import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BlabButtonStyle, Button } from '../../components/buttons';
import { BiaGlobalLogo, BiaGlobalLogoSize } from '../../components/icons';
import { AccentColor, AccentedLink, PlainLink } from '../../components/links';
import { Separator } from '../../components/separator';
import { Language } from '../../library/i18n';
import { combinePaths } from '../../library/url-parsing';
import {
  aboutBCorpsUrl,
  cookiePolicyUrl,
  globalNetworkUrl,
  movementOverviewUrl,
  privacyPolicyUrl,
  programsAndToolsOverviewUrl,
  securityNoticeUrl,
  certificationNoticeUrl,
  standardsOverviewUrl,
  termsOfUseUrl,
} from '../../library/urls';
import { Grid, NavDrawer, getMenu, instanceOfNavItem } from './layout';

type FooterLinkSection = {
  title: string;
  links: Array<{ title: string; href: string }>;
};

type FooterSocialMedia = {
  icon: IconDefinition;
  title: string;
  href: string;
  colorClass: string;
};

type FooterMenu = {
  movement: FooterLinkSection;
  standards: FooterLinkSection;
  programs: FooterLinkSection;
  corporations: FooterLinkSection;
  socialMedia: Array<FooterSocialMedia>;
};

function getFooterLinks(language: Language): FooterMenu {
  const menu = getMenu(language);

  const footerLinks: FooterMenu = {
    movement: {
      title: 'the-movement',
      links: [],
    },
    standards: {
      title: 'standards',
      links: [],
    },
    programs: {
      title: 'programs-and-tools',
      links: [],
    },
    corporations: {
      title: 'about-b-corporations',
      links: [],
    },
    socialMedia: [
      {
        title: 'Twitter',
        icon: faTwitter,
        href: 'https://twitter.com/BCorporation',
        colorClass: 'hover:twitter-color',
      },
      {
        title: 'Facebook',
        icon: faFacebook,
        href: 'https://m.facebook.com/bcorporation',
        colorClass: 'hover:facebook-color',
      },
      {
        title: 'Linkedin',
        icon: faLinkedin,
        href: 'https://www.linkedin.com/company/b-lab-global',
        colorClass: 'hover:linkedin-color',
      },
      {
        title: 'Youtube',
        icon: faYoutube,
        href: 'https://www.youtube.com/channel/UC-VDwwCN-6Z3DGEzcrmvQtQ',
        colorClass: 'hover:youtube-color',
      },
      {
        title: 'Instagram',
        icon: faInstagram,
        href: 'https://www.instagram.com/bcorporation',
        colorClass: 'hover:instagram-color',
      },
    ],
  };

  menu.items.forEach(item => {
    if (!instanceOfNavItem(item)) {
      const drawer = item as NavDrawer;
      switch (drawer.title) {
        case 'the-movement':
          drawer.items.forEach(i => {
            footerLinks.movement.links.push({
              title: i.title,
              href: i.href,
            });
          });
          break;
        case 'standards':
          drawer.items.forEach(i => {
            footerLinks.standards.links.push({
              title: i.title,
              href: i.href,
            });
          });
          break;
        case 'programs-and-tools':
          drawer.items.forEach(i => {
            footerLinks.programs.links.push({
              title: i.title,
              href: i.href,
            });
          });
          break;
        case 'about-b-corporations':
          drawer.items.forEach(i => {
            footerLinks.corporations.links.push({
              title: i.title,
              href: i.href,
            });
          });
          break;
      }
    }
  });
  return footerLinks;
}

const DesktopLinkSection = (props: {
  section: FooterLinkSection;
}): JSX.Element => {
  const { t } = useTranslation();

  const links = props.section.links.map((l, index) => {
    return (
      <div key={index}>
        <PlainLink
          href={l.href}
          className="font-serif hover:font-bold hover:yellow-animated-underline"
        >
          {t(l.title)}
        </PlainLink>
      </div>
    );
  });

  return (
    <div className="flex-col-stack-2">
      <div className="font-bold">{t(props.section.title)}</div>
      <div className="flex-col-stack-1.5">{links}</div>
    </div>
  );
};

const SocialMediaSection = (props: {
  social: Array<FooterSocialMedia>;
}): JSX.Element => {
  const { t } = useTranslation();

  const links = props.social.map((s, index) => {
    const classes = `flex-row-gutter-2 items-center font-serif hover:font-bold ${s.colorClass}`;
    return (
      <div key={index}>
        <PlainLink href={s.href} className={classes}>
          <FontAwesomeIcon icon={s.icon} />
          <span>{s.title}</span>
        </PlainLink>
      </div>
    );
  });

  return (
    <div className="flex-col-stack-2">
      <div className="font-bold">{t('follow-us')}</div>
      <div className="flex-col-stack-1.5">{links}</div>
    </div>
  );
};

const MobileSocialMediaSection = (props: {
  social: Array<FooterSocialMedia>;
}): JSX.Element => {
  const links = props.social.map((s, index) => {
    const classes = `flex flex-col items-center ${s.colorClass}`;
    return (
      <div key={index} className={classes}>
        <PlainLink href={s.href} aria-label={s.title}>
          <FontAwesomeIcon icon={s.icon} size="2x" />
        </PlainLink>
      </div>
    );
  });

  return (
    <div className="flex-row-gutter-2 self-center w-full grow-children-equally">
      {links}
    </div>
  );
};

// regex pulled from https://emailregex.com
const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

type ValidationErrors = {
  email: string;
};

type NewsletterSignupFormData = {
  email: string;
  organization: string;
  language: Language;
  errors: ValidationErrors;
  submitted: boolean;
};

const EmptyForm: NewsletterSignupFormData = {
  email: '',
  organization: '',
  language: '',
  errors: { email: '' },
  submitted: false,
};

const NewsletterSignupForm = (): JSX.Element => {
  const formRef = React.useRef<HTMLFormElement>(null);

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = React.useState<NewsletterSignupFormData>({
    ...EmptyForm,
    language: i18n.language,
  });

  const validateForm = (
    data: NewsletterSignupFormData
  ): ValidationErrors | null => {
    if (!data.email) {
      return {
        ...data.errors,
        email: t('footer-email-required'),
      };
    } else if (!emailRegex.test(data.email)) {
      return {
        ...data.errors,
        email: t('footer-email-invalid'),
      };
    }
    return null;
  };

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const errors = validateForm(formData);
    if (errors) {
      setFormData({ ...formData, errors });
      return;
    }

    formRef.current?.submit();
    setFormData({ ...EmptyForm, submitted: true });
  };

  const handleEmailUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(state => {
      return {
        ...state,
        email: event.target.value,
      };
    });
  };

  const handleOrganizationUpdate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData(state => {
      return {
        ...state,
        organization: event.target.value,
      };
    });
  };

  return (
    <>
      <iframe name="redirectframe" className="hidden"></iframe>

      <div className={`${!formData.submitted ? 'hidden' : 'block'} text-green`}>
        {t('footer-success-message')}
      </div>
      <form
        className={`${
          formData.submitted ? 'hidden' : 'flex-col-stack-2'
        } w-full`}
        action="https://pardot.bcorporation.net/l/39792/2021-10-07/9n5cqg"
        method="post"
        ref={formRef}
        target="redirectframe"
      >
        <input
          type="email"
          name="email"
          required
          autoComplete="on"
          placeholder={t('email-address')}
          onChange={handleEmailUpdate}
          value={formData.email}
        />
        {!!formData.errors.email && (
          <div className="text-sm text-red">{formData.errors.email}</div>
        )}
        <input
          type="text"
          name="organization"
          autoComplete="off"
          placeholder={t('organization')}
          onChange={handleOrganizationUpdate}
          value={formData.organization}
        />
        <input type="hidden" name="language" value={i18n.language} />
        <p>
          <Trans
            i18nKey="footer-security-message"
            components={{
              TLink: (
                <AccentedLink
                  href={combinePaths(i18n.language, globalNetworkUrl())}
                  accentColor={AccentColor.Blue}
                  className="font-sans"
                />
              ),
            }}
          />
        </p>
        <Button
          type="submit"
          blabType={BlabButtonStyle.SquareLargeFilledButton}
          onClick={e => handleSubmit(e)}
        >
          {t('submit')}
        </Button>
      </form>
    </>
  );
};

const Footer = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const footerLinkElement = (year: number) => (
    <>
      <div className="text-center px-8 py-1">
        {t('copyright', { year: year })}
      </div>
      <div className="text-center p-1 flex-grow flex-row">
        <Link to={`/${i18n.language}/${termsOfUseUrl}`}>
          {t('terms-of-use')}
        </Link>
        {' | '}
        <Link to={`/${i18n.language}/${privacyPolicyUrl}`}>
          {t('privacy-policy')}
        </Link>
        {' | '}
        <Link to={`/${i18n.language}/${cookiePolicyUrl}`}>
          {t('cookie-policy')}
        </Link>
        {' | '}
        <Link to={`/${i18n.language}/${securityNoticeUrl}`}>
          {t('security-notice')}
        </Link>
        {' | '}
        <Link to={`/${i18n.language}/${certificationNoticeUrl}`}>
          {t('certification-notice')}
        </Link>
      </div>
    </>
  );

  const year = new Date().getFullYear();

  const footerLinks = getFooterLinks(i18n.language);

  const logoUrl = `/${i18n.language}`;
  return (
    <Grid>
      <Separator />

      {/** Desktop */}
      <Grid className="hidden lg:grid col-span-full">
        <div className="col-start-1 col-span-5 border-r-2 px-8 flex-col-stack-4">
          <PlainLink href={logoUrl} className="self-center">
            <BiaGlobalLogo size={BiaGlobalLogoSize.Desktop} />
          </PlainLink>
          <p>{t('footer-tagline')}</p>
          <p>{t('newsletter-signup')}</p>
          <NewsletterSignupForm />
        </div>
        <div className="col-start-6 col-span-8 pl-16 flex-col-stack-4">
          <div className="flex-row-gutter-2 grow-children-equally">
            <DesktopLinkSection section={footerLinks.movement} />
            <DesktopLinkSection section={footerLinks.standards} />
            <DesktopLinkSection section={footerLinks.programs} />
          </div>
          <div className="flex-row-gutter-2 grow-children-equally">
            <DesktopLinkSection section={footerLinks.corporations} />
            <SocialMediaSection social={footerLinks.socialMedia} />
            <div />
          </div>
          <div className="flex-grow" />
        </div>
        <div className="col-span-full flex flex-row items-center">
          {footerLinkElement(year)}
        </div>
      </Grid>

      {/** Tablet & Mobile */}
      <div className="lg:hidden col-span-full flex-col-stack-2 items-center">
        <PlainLink href={logoUrl}>
          <BiaGlobalLogo size={BiaGlobalLogoSize.Mobile} />
        </PlainLink>
        <p>{t('newsletter-signup')}</p>
        <NewsletterSignupForm />
        <div className="flex-col-stack-2 items-center w-full">
          <hr className="border-t-2 text-gray w-full" />
          <div className="text-2xl font-bold">
            <Link to={`/${i18n.language}/${programsAndToolsOverviewUrl}`}>
              {t(footerLinks.movement.title)}
            </Link>
          </div>
          <hr className="border-t-2 text-gray w-full" />
          <div className="text-2xl font-bold">
            <Link to={`/${i18n.language}/${standardsOverviewUrl}`}>
              {t(footerLinks.standards.title)}
            </Link>
          </div>
          <hr className="border-t-2 text-gray w-full" />
          <div className="text-2xl font-bold">
            <Link to={`/${i18n.language}/${movementOverviewUrl}`}>
              {t(footerLinks.programs.title)}
            </Link>
          </div>
          <hr className="border-t-2 text-gray w-full" />

          <div className="text-2xl font-bold">
            <Link to={`/${i18n.language}/${aboutBCorpsUrl}`}>
              {t(footerLinks.corporations.title)}
            </Link>
          </div>
          <hr className="border-t-2 text-gray w-full" />

          <hr className="border-t-2 text-gray w-full" />
        </div>
        <MobileSocialMediaSection social={footerLinks.socialMedia} />
        <div className="col-span-full flex flex-col items-center">
          {footerLinkElement(year)}
        </div>
      </div>
    </Grid>
  );
};

export default Footer;
