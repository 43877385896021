import React, { SelectHTMLAttributes } from 'react';

export const Select = (
  props: SelectHTMLAttributes<HTMLSelectElement>
): JSX.Element => {
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    setSelected(props.value !== '');
  }, [props.value]);

  const fullProps: SelectHTMLAttributes<HTMLSelectElement> = {
    ...props,
    className: selected
      ? props.className
      : `text-gray-dark ${props.className ?? ''}`,
  };

  return <select {...fullProps}>{fullProps.children}</select>;
};
