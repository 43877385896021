import React, { HTMLAttributes } from 'react';
import { CallToAction as CallToActionModel } from '../library/models/call-to-action';
import { GatsbyImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from './attributes';
import { Button, BlabButtonStyle } from './buttons';
import { Grid, ZIndex, Layer } from '../templates/layout/layout';

type CallToActionProps = {
  content: CallToActionModel;
} & Omit<HTMLAttributes<HTMLDivElement>, 'content'>;

export const CallToAction = (props: CallToActionProps): JSX.Element => {
  const { content, ...divProps } = props;

  return (
    <Grid {...divProps}>
      <div className="col-span-full row-start-1 row-end-3 lg:row-end-1 lg:col-start-1 lg:col-end-11">
        <GatsbyImage
          image={content.image}
          alt={decorativeImageAlt}
          className="rounded-3xl"
        />
      </div>
      <div
        className={`col-span-full row-start-2 row-end-3 p-8 lg:p-0 lg:row-start-1 lg:row-end-1 lg:col-start-8 lg:col-end-13 flex flex-row items-center ${ZIndex(
          Layer.CallToActionContent
        )}`}
      >
        <div className="bg-white rounded-3xl shadow-lg p-6 flex-col-stack-4 text-center">
          <div className="font-bold text-2xl">{content.title}</div>
          <p>{content.body}</p>
          <Button
            blabType={BlabButtonStyle.LargeFilledButton}
            href={content.url}
          >
            {content.ctaLabel}
          </Button>
        </div>
      </div>
    </Grid>
  );
};
