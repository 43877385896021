import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';

export const useCalculateHeightOnResize = (
  ref: React.RefObject<HTMLElement>
) => {
  const { width } = useWindowSize();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handler = () => {
      if (!ref.current) {
        if (!height) {
          // we rerun handler if header ref is uninitialized yet
          requestAnimationFrame(handler);
        }
        return;
      }

      const { clientHeight } = ref.current;
      if (clientHeight !== height) {
        setHeight(clientHeight);
      }
    };

    handler();
  }, [width, height, setHeight, ref]);

  return height;
};
