import './src/styles/global.css';
import Modal from 'react-modal';
import ReactDOM from 'react-dom/client';
import { config } from '@fortawesome/fontawesome-svg-core';

Modal.setAppElement('#___gatsby');

/**
 * prevents fontawesome auto css insertion
 *
 * see: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
 */
export const onClientEntry = () => {
  config.autoAddCss = false;
};

/**
 * attempting to prevent hydration errors filling logs in production
 * idea found in gatsby discussion https://github.com/gatsbyjs/gatsby/discussions/36232
 *
 * see: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
 */
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export default { onClientEntry, replaceHydrateFunction };
