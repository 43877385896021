import React from 'react';
import { BlabButtonStyle, Button } from '../buttons';

export type CookieBannerProps = {
  text: string;
  acceptLabel: string;
  declineLabel: string;
  onAccept: () => void;
  onDecline: () => void;
};

export function CookieBanner({
  text,
  acceptLabel,
  declineLabel,
  onAccept,
  onDecline,
}: CookieBannerProps) {
  return (
    <div
      role="dialog"
      aria-labelledby="cookieBannerId"
      className="flex flex-col justify-between gap-x-40px gap-y-32px border-t border-grey-light bg-white p-32px @container @md:items-center @lg:flex-row"
    >
      <p
        id="cookieBannerId"
        className="font-medium text-text-light-primary app-body-md"
      >
        {text}
      </p>
      <div className="flex flex-col items-center justify-between gap-32px @md:flex-row">
        <Button
          blabType={BlabButtonStyle.MediumFilledButton}
          onClick={onAccept}
          className="inline h-fit w-fit select-none rounded-md outline-none focus:outline-none disabled:cursor-not-allowed min-h-[3rem] py-3 app-body-md px-5 @md:whitespace-nowrap"
        >
          {acceptLabel}
        </Button>
        <Button
          blabType={BlabButtonStyle.MediumFilledButton}
          onClick={onDecline}
          className="inline h-fit w-fit select-none rounded-md outline-none focus:outline-none disabled:cursor-not-allowed min-h-[3rem] py-3 app-body-md px-5 @md:whitespace-nowrap"
        >
          {declineLabel}
        </Button>
      </div>
    </div>
  );
}
