import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../components/accordion/accordion';
import { Separator } from '../components/separator';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import { Language, useChangeLanguage } from '../library/i18n';
import {
  CertifiedAssessment,
  CertifiedCompany,
  ImpactTopic,
} from '../library/models/certified-company';
import {
  Standard,
  getAreaExcerpt,
  getAreaName,
  getTopicName,
} from '../library/models/standards';
import { CompanyInfo, Header } from '../templates/company';
import Layout, { Grid } from '../templates/layout/layout';

export type CertifiedCompanyContext = {
  language: Language;
  pagePath: string;
  company: CertifiedCompany;
  standards: Standard;
};

interface CertifiedCompanyProps {
  readonly pageContext: CertifiedCompanyContext;
}

function AdditionalDocumentation(company: CertifiedCompany): JSX.Element {
  const { t } = useTranslation();

  if (company.files.length === 0) {
    return <></>;
  }

  const content = (
    <>
      <h3>{t('additional-documentation')}</h3>
      <div>
        {company.files.map((val, index) => {
          return (
            <div className="flex flex-col" key={index}>
              <div className="flex py-8">
                <span className="flex-grow"> {val.title}</span>
                <span>
                  <a href={val.downloadUrl} rel="noopener">
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </span>
              </div>
              <hr className="border-t-2 text-gray w-full" />
            </div>
          );
        })}
      </div>
    </>
  );

  return (
    <>
      <Separator />

      {/* Desktop */}
      <Grid className="hidden lg:grid col-span-full p-8">
        <div className="col-start-2 col-end-12">
          <div className="flex flex-col">{content}</div>
        </div>
      </Grid>

      {/* Tablet + Mobile */}
      <div className="lg:hidden flex col-span-full p-8">
        <div className="flex flex-col w-full">{content}</div>
      </div>
    </>
  );
}

function ScoreLegendDot(color: string, content: string): JSX.Element {
  return (
    <>
      <div className="flex items-center">
        <div className="flex w-4 h-4 mr-2 flex-shrink-0">
          <div className={color + ' min-w-full min-h-full rounded-full'}></div>
        </div>
        <span>{content}</span>
      </div>
    </>
  );
}

function ScoreCircle(
  height: number,
  center: number,
  strokeColor: string,
  percentageFill: number
): JSX.Element {
  const strokeWidth = 12;
  const radius = height / 2 - strokeWidth * 2;
  const circumference = radius * 2 * Math.PI;

  return (
    <>
      <circle
        className="stroke-blue opacity-20"
        fill="transparent"
        origin="center"
        cx={center}
        cy={center}
        r={radius}
        strokeDasharray={circumference}
        strokeWidth={strokeWidth}
      />
      <circle
        className={strokeColor}
        origin={'center'}
        fill="transparent"
        transform={'rotate(90 ' + center + ' ' + center + ')'}
        cx={center}
        cy={center}
        r={radius}
        strokeDasharray={circumference * percentageFill + ' ' + circumference}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </>
  );
}

function ScoreCircles(
  overallScore: number,
  totalScorePossible: number,
  scoreThreshold: number,
  medianScore: number
): JSX.Element {
  const height = 265;
  const center = 265 / 2;

  return (
    <>
      <svg height={height} width={height}>
        <g>
          {ScoreCircle(
            height,
            center,
            'stroke-blue',
            overallScore / totalScorePossible
          )}
          {ScoreCircle(
            height - 40,
            center,
            'stroke-green',
            scoreThreshold / totalScorePossible
          )}
          {ScoreCircle(
            height - 80,
            center,
            'stroke-yellow',
            medianScore / totalScorePossible
          )}
          <text
            className="text-4xl"
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            {overallScore}
          </text>
        </g>
      </svg>
    </>
  );
}

function PreviousBImpactScores(
  previousAssessments: CertifiedAssessment[]
): JSX.Element {
  const { t } = useTranslation();

  if (previousAssessments.length === 0) {
    return <></>;
  }

  return (
    <>
      <Separator />

      <div className="col-span-full flex flex-col w-full">
        <div className="flex justify-center">
          <h3>{t('previous-b-impact-scores')}</h3>
        </div>
      </div>
      <div className="col-start-2 col-end-12">
        <div className="flex justify-center">
          <div className="flex flex-col space-y-4">
            {previousAssessments.map((assessment, index) => {
              return (
                <div className="flex w-full space-x-12" key={index}>
                  <span>
                    {t('overall-b-impact-score-year', {
                      year: new Date(assessment.ratingDate)
                        .getFullYear()
                        .toString(),
                    })}
                  </span>
                  <span className="font-bold">{assessment.overallScore}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

type ImpactAreaScoresProps = {
  latestAssessment: CertifiedAssessment;
  standards: Standard;
};

function ImpactAreaScores({
  latestAssessment,
  standards,
}: ImpactAreaScoresProps): JSX.Element {
  const [activeIndex, setActiveIndex] = useState(0);

  if (latestAssessment.impactAreas.length === 0) {
    return <></>;
  }

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:flex flex-col col-span-full space-y-4">
        <div className="flex justify-center space-x-4">
          {latestAssessment.impactAreas.map((area, index) => {
            return (
              <button
                onClick={() => setActiveIndex(index)}
                className={`rounded-md p-2 ${
                  index == activeIndex ? 'border border-blue border-solid' : ''
                }`}
                key={area.name}
              >
                {getAreaName(area, standards)}
              </button>
            );
          })}
        </div>
      </div>
      <div className="hidden lg:flex col-start-2 col-end-12">
        {latestAssessment.impactAreas
          .filter((_, index) => index == activeIndex)
          .map(area => {
            return (
              <div className="flex animate-fade-in space-x-24" key={area.name}>
                <div className="w-1/2">
                  <h3 className="whitespace-nowrap">
                    {getAreaName(area, standards)} {formatScore(area.score)}
                  </h3>
                  <p className="py-4 pr-4">{getAreaExcerpt(area, standards)}</p>
                </div>
                <div className="w-1/2">
                  {ImpactTopicScores(area.impactTopics, standards)}
                </div>
              </div>
            );
          })}
      </div>

      {/* Tablet + Mobile */}
      <Grid
        data-testid="impact_area_accordion_list"
        className="lg:hidden w-full"
      >
        {latestAssessment.impactAreas.map(area => {
          return (
            <React.Fragment key={area.name}>
              <Accordion
                title={getAreaName(area, standards)}
                data-testid="impact_area_accordion_item"
                className="col-span-full"
              >
                <h3>
                  {getAreaName(area, standards)} {formatScore(area.score)}
                </h3>
                <p className="py-4 pr-4">{getAreaExcerpt(area, standards)}</p>
                {ImpactTopicScores(area.impactTopics, standards)}
              </Accordion>
              <Separator />
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
}

function ImpactTopicScores(
  topics: ImpactTopic[],
  standards: Standard
): JSX.Element {
  const { t } = useTranslation();

  const ibmTopics = topics.filter(topic => {
    return topic.isIbm;
  });

  const nonIbmTopics = topics.filter(topic => {
    return !topic.isIbm;
  });

  return (
    <>
      <div className="flex flex-col space-y-2">
        {nonIbmTopics.map(topic => {
          return (
            <div className="flex px-4" key={topic.id}>
              <span className="flex-grow">
                {getTopicName(topic, standards)}
              </span>
              <span className="font-bold">{formatScore(topic.score)}</span>
            </div>
          );
        })}

        {ibmTopics.length > 0 ? (
          <div className="bg-blue-light rounded-3xl py-4 px-4 space-y-2">
            {ibmTopics.map(topic => {
              return (
                <div className="flex" key={topic.id}>
                  <span className="flex-grow">
                    {`+`} {getTopicName(topic, standards)}
                  </span>
                  <span className="font-bold">{topic.score}</span>
                </div>
              );
            })}
            <p className="pt-2">
              <span className="font-bold">{t('ibm-description-1')}&nbsp;</span>
              <span>{t('ibm-description-2')}</span>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

function formatScore(n: number): string {
  return n.toFixed(1).toString();
}

type BImpactScoreProps = {
  company: CertifiedCompany;
  standards: Standard;
};

function BImpactScore({ company, standards }: BImpactScoreProps): JSX.Element {
  const { t } = useTranslation();

  const totalPointsAvailable = 250;
  const minimumThreshold = 80;
  const medianScore = 50.9;

  const sortedAssessments = company.assessments.sort((a, b) => {
    return b.ratingDate - a.ratingDate;
  });

  const [latestRatedAssessment, ...previousAssessments] = sortedAssessments;

  if (latestRatedAssessment === undefined) {
    return <></>;
  }

  const overallScore = latestRatedAssessment.overallScore;
  const scoreLegend = (
    <>
      {ScoreLegendDot(
        'bg-blue',
        t('overall-b-impact-score-earned', {
          overallScore: overallScore.toString(),
        })
      )}
      {ScoreLegendDot(
        'bg-green',
        t('overall-b-impact-score-qualifies', {
          scoreThreshold: minimumThreshold.toString(),
        })
      )}
      {ScoreLegendDot(
        'bg-yellow',
        t('overall-b-impact-score-median', { medianScore: medianScore })
      )}
    </>
  );

  return (
    <>
      {/* Desktop */}
      <Grid className="hidden lg:grid col-span-full bg-gray-light rounded-3xl p-8">
        <div className="flex justify-center col-span-full">
          <h2>{t('overall-b-impact-score')}</h2>
        </div>
        <div className="col-start-2 col-end-12">
          <div className="flex">
            <span className="flex-1">
              {t('overall-b-impact-score-description', {
                name: company.name,
                overallScore: formatScore(latestRatedAssessment.overallScore),
                medianScore: medianScore.toString(),
              })}
            </span>

            <div className="flex flex-1 justify-center">
              {ScoreCircles(
                overallScore,
                totalPointsAvailable,
                minimumThreshold,
                medianScore
              )}
            </div>

            <div className="flex flex-col space-y-2 flex-1">{scoreLegend}</div>
          </div>
        </div>

        <ImpactAreaScores
          latestAssessment={latestRatedAssessment}
          standards={standards}
        />
        {PreviousBImpactScores(previousAssessments)}
      </Grid>

      {/* Tablet + Mobile */}
      <div className="lg:hidden col-span-full flex flex-col justify-center items-center bg-gray-light rounded-3xl p-8 space-y-8">
        <h2>{t('overall-b-impact-score')}</h2>
        <div>
          {t('overall-b-impact-score-description', {
            name: company.name,
            overallScore: formatScore(latestRatedAssessment.overallScore),
            medianScore: medianScore.toString(),
          })}
        </div>

        {ScoreCircles(
          overallScore,
          totalPointsAvailable,
          minimumThreshold,
          medianScore
        )}

        <div className="flex flex-col space-y-2">{scoreLegend}</div>

        <ImpactAreaScores
          latestAssessment={latestRatedAssessment}
          standards={standards}
        />
        {PreviousBImpactScores(sortedAssessments)}
      </div>
    </>
  );
}

const Company: FC<CertifiedCompanyProps> = ({
  pageContext: { company, language, pagePath, standards },
}): JSX.Element => {
  const { t } = useTranslation();
  useChangeLanguage(language);

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    title: t('seo-certified-company-title', {
      val: company.name,
    }),
    description: t('seo-certified-company-description', {
      name: company.name,
      description: company.description,
    }).substring(0, 320),
    lang: language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
    ogImage: company.lifestyleLogo === null ? undefined : company.lifestyleLogo,
  };

  return (
    <Layout metaTagProps={metaTagProps}>
      {Header(company)}
      {CompanyInfo(company)}
      <BImpactScore company={company} standards={standards} />
      {AdditionalDocumentation(company)}
    </Layout>
  );
};

export default Company;
