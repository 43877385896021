// TODO: helpers like `requireStringEnv` doesn't work in all environments.

import {
  COMPANIES_COLLECTION_ALIAS,
  toTypesenseEnvironment,
} from '../../services/search/typesense/model';

// Consider migration to @t3-oss/env-core later
const TypesenseEnrironment = toTypesenseEnvironment(
  process.env.GATSBY_TYPESENSE_ENVIRONMENT
);
const TypesenseClientUrl = process.env.GATSBY_TYPESENSE_CLIENT_URL;
const TypesenseSearchApiKey = process.env.GATSBY_TYPESENSE_SEARCH_API_KEY ?? '';
if (!TypesenseSearchApiKey) {
  throw new Error(
    'missing required GATSBY_TYPESENSE_SEARCH_API_KEY string option'
  );
}
export { TypesenseEnrironment, TypesenseClientUrl, TypesenseSearchApiKey };
export const DefaultIndex = COMPANIES_COLLECTION_ALIAS[TypesenseEnrironment];

export enum SearchKey {
  query = 'query',
  page = 'page',
  refinement = 'refinement',
  sortBy = 'sortBy',
}

export enum RefinementItem {
  countries = 'countries',
  demographicsList = 'demographicsList',
  hqCountry = 'hqCountry',
  size = 'size',
  industry = 'industry',
}

export const REFINEMENT_ITEMS = [
  RefinementItem.countries,
  RefinementItem.demographicsList,
  RefinementItem.hqCountry,
  RefinementItem.size,
  RefinementItem.industry,
];
