import React from 'react';
import { Grid } from '../../../templates/layout/layout';
import { decorativeImageAlt } from '../../attributes';
import { Separator } from '../../separator';
import { CircleNav } from '../../../hooks/use-circle-nav';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PlainLink } from '../../links';
import { createRelatedContentUrl } from '../../../library/urls';
import { intersperse } from '../../../library/array-utils';
import { isString } from '../../../library/string-utils';

interface CircleNavProps {
  items: CircleNav[];
}

function generateUrl(i: CircleNav) {
  if (isString(i.linkOrDestination)) {
    if (
      i.linkOrDestination.startsWith('http://') ||
      i.linkOrDestination.startsWith('https://')
    ) {
      return i.linkOrDestination;
    }
    return `/${i.node_locale}${i.linkOrDestination}`;
  }

  return createRelatedContentUrl(i.linkOrDestination);
}

const CircleNavigation = ({ items }: CircleNavProps): JSX.Element => {
  if (items.length == 0) {
    return <></>;
  }

  return (
    <>
      {/** Desktop */}
      <Separator />
      <Grid className="col-span-full hidden lg:grid">
        <div className="col-span-full flex-row-gutter-2 grow-children-equally">
          {items.map((i, index) => {
            return (
              <div
                key={index}
                className="flex flex-col justify-top items-center"
              >
                <PlainLink
                  className="flex flex-col items-center text-bg-fx"
                  href={generateUrl(i)}
                >
                  <GatsbyImage
                    image={i.img}
                    alt={decorativeImageAlt}
                    className="h-40 w-40 rounded-full border-4 p-4 border-black isolate"
                  />
                  <div
                    className="flex text-3xl text-center mt-6 font-bold justify-center mx-auto"
                    data-testid="circle-nav-1"
                  >
                    <span className="text-bg-fx-yellow text-bg-fx-scale-y">
                      {i.title}
                    </span>
                  </div>
                </PlainLink>
              </div>
            );
          })}
        </div>
      </Grid>

      {/** Tablet + Mobile */}
      <Grid className="col-span-full lg:hidden">
        {intersperse(
          i => (
            <Separator key={`sep-${i}`} />
          ),
          items.map((i, index) => {
            return (
              <div
                className="col-span-full h-32 flex text-center text-3xl justify-center items-center font-bold"
                data-testid="circle-nav-1"
                key={index}
              >
                <PlainLink
                  href={generateUrl(i)}
                  className="text-bg-fx-yellow text-bg-fx text-bg-fx-scale-y"
                >
                  {i.title}
                </PlainLink>
              </div>
            );
          })
        )}
      </Grid>
      <Separator />
    </>
  );
};

export default CircleNavigation;
