import React, { FC } from 'react';
import { Language, useChangeLanguage } from '../../library/i18n';
import { useTranslation } from 'react-i18next';
import Layout from '../layout/layout';
import {
  CorporateStructure,
  corporateStructureTranslation,
  LegalRequirementMap,
  RequirementLink,
  isCorporateStructureRequirementMap,
} from '../../library/models/legal-requirements';
import { LegalRequirementsPageModel } from '../../library/models/legal-requirements-page';
import { Select } from '../../components/inputs/select';
import { WrappedRichText } from '../../components/rich-text';
import { Button, BlabButtonStyle } from '../../components/buttons';
import { isString } from '../../library/string-utils';
import { AccentedLink, AccentColor } from '../../components/links';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { GrayHeader } from '../../components/gray-header';

type LegalIndexState = {
  country: string;
  province: string;
  corporateStructure: string;
};

export interface LegalIndexPageContext {
  pagePath: string;
  pageModel: LegalRequirementsPageModel;
  language: Language;
  legalRequirementMap: LegalRequirementMap;
}

export interface LegalIndexProps {
  readonly pageContext: LegalIndexPageContext;
}

function getLink(
  pageState: LegalIndexState,
  selections: LegalRequirementMap
): RequirementLink | undefined {
  if (pageState.country === '') {
    return undefined;
  }

  const countrySelection = selections[pageState.country];
  if (countrySelection === undefined || isString(countrySelection)) {
    return countrySelection;
  }

  if (pageState.corporateStructure === '') {
    return undefined;
  }

  if (isCorporateStructureRequirementMap(countrySelection)) {
    const result =
      countrySelection.corporateStructures[pageState.corporateStructure];
    if (isString(result)) {
      return result;
    }

    return undefined;
  }

  if (pageState.province === '') {
    return undefined;
  }

  const corporateSelections = countrySelection.provinces[pageState.province];

  if (corporateSelections === undefined) {
    return undefined;
  }

  return corporateSelections[pageState.corporateStructure];
}

function getProvinceValues(
  country: string,
  reqMap: LegalRequirementMap
): string[] {
  if (country === '') {
    return [];
  }

  const countryOptions = reqMap[country];
  if (
    countryOptions === undefined ||
    isString(countryOptions) ||
    isCorporateStructureRequirementMap(countryOptions)
  ) {
    return [];
  }

  return Object.keys(countryOptions.provinces);
}

function getCorporateStructureValues(
  country: string,
  province: string,
  reqMap: LegalRequirementMap
): string[] {
  if (country === '') {
    return [];
  }

  const countryOptions = reqMap[country];
  if (countryOptions === undefined || isString(countryOptions)) {
    return [];
  }

  if (isCorporateStructureRequirementMap(countryOptions)) {
    return Object.keys(countryOptions.corporateStructures);
  }

  if (province === '') {
    return [];
  }

  const provinceOptions = countryOptions.provinces[province];
  return provinceOptions === undefined ? [] : Object.keys(provinceOptions);
}

const defaultState: LegalIndexState = {
  country: '',
  province: '',
  corporateStructure: '',
};

const LegalIndex: FC<LegalIndexProps> = ({ pageContext }): JSX.Element => {
  const { pagePath, pageModel, language, legalRequirementMap } = pageContext;
  const {
    page: { title, body, seoTitle, seoDescription },
    introduction,
    viewButtonTitle,
    resetFiltersTitle,
    epilogue,
  } = pageModel;

  const { t } = useTranslation();
  useChangeLanguage(language);

  const [pageState, setPageState] =
    React.useState<LegalIndexState>(defaultState);

  const updateCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const country = event.target.value;
    setPageState(state => ({
      ...state,
      country,
      province: '',
      corporateStructure: '',
    }));
  };

  const updateProvince = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const province = event.target.value;
    setPageState(state => ({
      ...state,
      province,
      corporateStructure: '',
    }));
  };

  const updateCorporateStructure = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const corporateStructure = event.target.value;
    setPageState(state => ({ ...state, corporateStructure }));
  };

  const resetSelections = () => {
    setPageState(defaultState);
  };

  function getCountryOptions(options: string[]): JSX.Element[] {
    const optionElements = options.sort().map((country, index) => {
      return (
        <option key={index} value={country}>
          {country}
        </option>
      );
    });

    return [
      <option key="no-selection" value="">
        {t('country-region-territory')}
      </option>,
      ...optionElements,
    ];
  }

  function getProvinceOptions(options: string[]): JSX.Element[] {
    const optionElements = options.sort().map((key, index) => {
      return (
        <option key={index} value={key}>
          {key}
        </option>
      );
    });

    return [
      <option key="no-selection" value="">
        {t('province')}
      </option>,
      ...optionElements,
    ];
  }

  function getCorporateStructureOptions(options: string[]): JSX.Element[] {
    const optionElements = options.sort().map((key, index) => {
      return (
        <option key={index} value={key}>
          {corporateStructureTranslation(t, key as CorporateStructure)}
        </option>
      );
    });

    return [
      <option key="no-selection" value="">
        {t('corporate-structure')}
      </option>,
      ...optionElements,
    ];
  }

  const CountrySelect = ({
    value,
    options,
  }: {
    value: string;
    options: string[];
  }) => {
    if (options.length === 0) {
      return null;
    }
    return (
      <Select onChange={updateCountry} value={value}>
        {getCountryOptions(options)}
      </Select>
    );
  };

  const ProvinceSelect = ({
    value,
    options,
  }: {
    value: string;
    options: string[];
  }) => {
    if (options.length === 0) {
      return null;
    }
    return (
      <Select onChange={updateProvince} value={value}>
        {getProvinceOptions(options)}
      </Select>
    );
  };

  const CorporateStructureSelect = ({
    value,
    options,
  }: {
    value: string;
    options: string[];
  }) => {
    if (options.length === 0) {
      return null;
    }
    return (
      <Select onChange={updateCorporateStructure} value={value}>
        {getCorporateStructureOptions(options)}
      </Select>
    );
  };

  const ViewRequirementsButton = ({ url }: { url?: string }) => {
    if (!url) {
      return null;
    }
    return (
      <Button
        blabType={BlabButtonStyle.LargeFilledButton}
        onClick={() => window.open(url, '_blank')}
      >
        {viewButtonTitle}
      </Button>
    );
  };

  const ResetLink = ({ isVisible }: { isVisible: boolean }) => {
    if (!isVisible) {
      return null;
    }
    return (
      <AccentedLink
        onClick={resetSelections}
        accentColor={AccentColor.Blue}
        className="font-bold"
      >
        {resetFiltersTitle}
      </AccentedLink>
    );
  };

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    title: seoTitle,
    description: seoDescription,
    lang: language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
  };

  const { country, province, corporateStructure } = pageState;
  const countryValues = Object.keys(legalRequirementMap);
  const availableProvinceValues = getProvinceValues(
    country,
    legalRequirementMap
  );
  const availableCorporateStructureValues = getCorporateStructureValues(
    country,
    province,
    legalRequirementMap
  );
  const viewRequirementURL = getLink(pageState, legalRequirementMap);

  return (
    <Layout metaTagProps={metaTagProps}>
      <GrayHeader>
        <h1 data-testid="hero-header" className="text-4xl">
          {title}
        </h1>
      </GrayHeader>

      <div className="col-span-full flex-col-stack-4">
        {body && (
          <WrappedRichText
            className="flex-col-stack-4"
            richText={body}
            additionalProps={{
              'heading-2': { as: 'h2' },
              hyperlink: {
                className: 'font-bold',
                accentColor: AccentColor.Blue,
              },
            }}
          />
        )}

        <div className="py-12 px-4 md:px-24 bg-gray-light rounded-2xl flex-col-stack-6">
          <WrappedRichText
            richText={introduction}
            className="flex-col-stack-3"
            additionalProps={{
              'heading-3': { as: 'h3', className: 'text-xl' },
              paragraph: { className: 'text-3xl' },
            }}
          />

          <div className="flex-col-stack-4 lg:px-24">
            <CountrySelect value={country} options={countryValues} />
            <ProvinceSelect
              value={province}
              options={availableProvinceValues}
            />
            <CorporateStructureSelect
              value={corporateStructure}
              options={availableCorporateStructureValues}
            />
            <div className="flex-col-stack-2 items-center">
              <ViewRequirementsButton url={viewRequirementURL} />
              <ResetLink isVisible={country !== ''} />
            </div>
          </div>

          <WrappedRichText
            className="flex-col-stack-6"
            richText={epilogue}
            additionalProps={{
              hyperlink: {
                className: 'font-sans',
                accentColor: AccentColor.Blue,
              },
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default LegalIndex;
