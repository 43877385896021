import { UiState } from 'instantsearch.js/es/types';
import { RouterProps } from 'instantsearch.js/es/middlewares/createRouterMiddleware';
import { history } from 'instantsearch.js/cjs/lib/routers';
import { INITIAL_SORT_VALUE, QUERY_SORT_VALUE } from './sort-by';
import { DefaultIndex, SearchKey, RefinementItem } from './constants';

interface RouteState {
  [SearchKey.query]?: string;
  [SearchKey.refinement]?: {
    [RefinementItem.countries]?: string[];
    [RefinementItem.demographicsList]?: string[];
    [RefinementItem.hqCountry]?: string[];
    [RefinementItem.size]?: string[];
    [RefinementItem.industry]?: string[];
  };
  [SearchKey.sortBy]?: string;
  [SearchKey.page]?: string;
}

export const routing: RouterProps<UiState, RouteState> = {
  router: history(),
  stateMapping: {
    stateToRoute(uiState: UiState) {
      const indexUiState = uiState[DefaultIndex];
      if (!indexUiState) return {};

      return {
        [SearchKey.query]: indexUiState.query,
        [SearchKey.refinement]: {
          [RefinementItem.countries]:
            indexUiState.refinementList?.[RefinementItem.countries],
          [RefinementItem.demographicsList]:
            indexUiState.refinementList?.[RefinementItem.demographicsList],
          [RefinementItem.hqCountry]:
            indexUiState.refinementList?.[RefinementItem.hqCountry],
          [RefinementItem.size]:
            indexUiState.refinementList?.[RefinementItem.size],
          [RefinementItem.industry]:
            indexUiState.refinementList?.[RefinementItem.industry],
        },
        [SearchKey.sortBy]:
          indexUiState.sortBy === INITIAL_SORT_VALUE
            ? undefined
            : indexUiState.sortBy ?? QUERY_SORT_VALUE,
        [SearchKey.page]:
          indexUiState.page !== undefined && indexUiState.page !== 1
            ? String(indexUiState.page)
            : undefined,
      };
    },
    routeToState(routeState: RouteState) {
      const parseInteger = (value?: string) => {
        if (!value) return undefined;

        const result = Number.parseInt(value);
        if (!result || isNaN(result)) return undefined;

        return result;
      };

      return {
        [DefaultIndex]: {
          query: routeState[SearchKey.query],
          refinementList: {
            [RefinementItem.countries]:
              routeState[SearchKey.refinement]?.[RefinementItem.countries] ||
              [],
            [RefinementItem.demographicsList]:
              routeState[SearchKey.refinement]?.[
                RefinementItem.demographicsList
              ] || [],
            [RefinementItem.hqCountry]:
              routeState[SearchKey.refinement]?.[RefinementItem.hqCountry] ||
              [],
            [RefinementItem.size]:
              routeState[SearchKey.refinement]?.[RefinementItem.size] || [],
            [RefinementItem.industry]:
              routeState[SearchKey.refinement]?.[RefinementItem.industry] || [],
          },
          sortBy: routeState[SearchKey.sortBy] || INITIAL_SORT_VALUE,
          page: parseInteger(routeState[SearchKey.page]) || 1,
        },
      };
    },
  },
};
