import { Client } from 'typesense';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

import { Company, CompanyIndexed } from '../models';
import { MakeTypesenseApiOptions } from './model';

// TODO: do we need to limit the fields to query by?
const QUERY_BY_FIELDS = [
  'name',
  'description',
  'websiteKeywords',
  'countries',
  'industry',
  'sector',
  'hqCountry',
  'hqProvince',
  'hqCity',
  'hqPostalCode',
  'provinces',
  'cities',
  'size',
  'demographicsList',
] satisfies (keyof CompanyIndexed)[];
const QUERY_BY = QUERY_BY_FIELDS.join(',');

export function makeTypesenseSearchApi(options: MakeTypesenseApiOptions) {
  const searchClient = new Client(options);

  function searchCompanies(
    collectionName: string,
    q: string,
    exhaustiveSearch?: boolean
  ) {
    return searchClient
      .collections<Company>(collectionName)
      .documents()
      .search({
        q,
        query_by: QUERY_BY,
        exhaustive_search: exhaustiveSearch,
      });
  }

  return {
    searchCompanies,
  };
}

export function makeTypesenseSearchAdaptor(options: MakeTypesenseApiOptions) {
  const server = {
    ...options,
    cacheSearchResultsForSeconds: 5 * 60,
  };
  const additionalSearchParameters = {
    query_by: QUERY_BY,
    exhaustive_search: true,
  };
  const typesenseSearchAdaptor = new TypesenseInstantSearchAdapter({
    server,
    additionalSearchParameters,
  });

  function setExhaustiveSearch(exhaustiveSearch: boolean) {
    typesenseSearchAdaptor.updateConfiguration({
      server,
      additionalSearchParameters: {
        ...additionalSearchParameters,
        exhaustive_search: exhaustiveSearch,
      },
    });
  }

  return {
    searchClient: typesenseSearchAdaptor.searchClient,
    setExhaustiveSearch,
  };
}
