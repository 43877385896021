import { FromDecoder, JsonDecoder } from 'ts.data.json';
import { RichTextDecoder, CommonModelDecoder } from '../common';
import { ListItemDecoder } from './list-item';

const UnconstrainedListDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      title: JsonDecoder.string,
      items: JsonDecoder.array(ListItemDecoder, 'List item[]'),
      epilogue: RichTextDecoder,
    },
    'List'
  )
);

export type ListModel = FromDecoder<typeof UnconstrainedListDecoder>;

export const ListDecoder = UnconstrainedListDecoder.chain<ListModel>(result => {
  const { items } = result;
  if (items.length > 99) {
    return JsonDecoder.fail(
      'Change timeline page template in order to support more than 99 items'
    );
  }

  return JsonDecoder.constant(result);
});
