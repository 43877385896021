import React from 'react';
import clsx from 'clsx';

const classes = {
  link: {
    default:
      'outline-none flex items-center justify-center w-10 h-10 border border-surface-variant-light-outline rounded-lg',
    hover: 'hover:bg-white hover:border-fiber-grey-900',
    focus: 'focus:bg-white focus:border-fiber-grey-900',
    disabled: 'pointer-events-none bg-gray',
    selected:
      'pointer-events-none bg-white text-fiber-grey-900 border-fiber-grey-900 shadow-[inset_0_-3px_0_black]',
    unselected: 'text-fiber-neutral-500 border-fiber-neutral-500',
  },
  label: 'font-medium',
};

interface PaginationButtonProps {
  label: string;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const PaginationButton = ({
  label,
  selected,
  disabled,
  onClick,
  children,
}: PaginationButtonProps): JSX.Element => (
  <button
    className={clsx(classes.link.default, {
      [classes.link.selected]: selected,
      [classes.link.unselected]: !selected,
      [classes.link.hover]: !(disabled || selected),
      [classes.link.focus]: !(disabled || selected),
      [classes.link.disabled]: disabled && !selected,
    })}
    disabled={disabled}
    onClick={onClick}
    aria-label={label}
    aria-current={selected ? 'true' : 'false'}
  >
    <span className={classes.label}>{children}</span>
  </button>
);

export default PaginationButton;
