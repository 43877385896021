import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSortBy, useSearchBox } from 'react-instantsearch';
import { Layer, ZIndex } from '../../templates/layout/layout';
import PositionedContentModal from '../modal/positioned-content-modal';
import BottomSheet from '../../components/dropdown/bottom-sheet';
import Dropdown from '../../components/dropdown/dropdown';
import DropdownMenuItem from '../../components/dropdown/dropdown-menu-item';
import FullScreenModal from '../../components/modal/full-screen-modal';
import SelectInput from '../../fiber/components/input/select-input';
import CheckIcon from '../../fiber/icons/outline/system-devices/check';
import { useLockScroll } from '../../hooks/use-lock-scroll';
import { useBreakpoint } from '../../hooks/use-breakpoint';
import { DefaultIndex } from './constants';
import { useScrollToFilters } from './scroll';
import { typesenseInstantsearchAdapter } from './instant-search';

interface SortByGroup {
  groupName: string;
  hint?: string;
  hintFirst?: boolean;
  options: Array<{
    value: string;
    label: string;
  }>;
}

export const INITIAL_SORT_VALUE = `${DefaultIndex}/sort/initialCertificationDateTimestamp:desc`;
export const QUERY_SORT_VALUE = DefaultIndex;

const SORT_BY_GROUPS: SortByGroup[] = [
  {
    groupName: 'relevant',
    options: [
      {
        value: QUERY_SORT_VALUE,
        label: 'most-relevant',
      },
    ],
  },
  {
    groupName: 'latest-certification',
    hint: 'certified',
    hintFirst: false,
    options: [
      {
        value: INITIAL_SORT_VALUE,
        label: 'newest',
      },
      {
        value: `${DefaultIndex}/sort/initialCertificationDateTimestamp:asc`,
        label: 'oldest',
      },
    ],
  },
  {
    groupName: 'alphabetical-name',
    hint: 'alphabetically',
    hintFirst: true,
    options: [
      {
        value: `${DefaultIndex}/sort/name:asc`,
        label: 'ascending',
      },
      {
        value: `${DefaultIndex}/sort/name:desc`,
        label: 'descending',
      },
    ],
  },
];

export const useSortByOptions = () => {
  const { t } = useTranslation();
  const items = SORT_BY_GROUPS.flatMap(({ options, hint, hintFirst }) => [
    ...options.map(({ value, label }) => ({
      value,
      label: t(label),
      hint: hint ? t(hint) : undefined,
      hintFirst,
    })),
  ]);
  const sortBy = useSortBy({ items });

  function refine(value: string) {
    typesenseInstantsearchAdapter.setExhaustiveSearch(
      value === QUERY_SORT_VALUE
    );
    sortBy.refine(value);
  }
  return {
    currentRefinement: sortBy.currentRefinement,
    refine,
    items,
  };
};

interface SortByProps {
  className?: string;
}

export const SortBy = ({ className }: SortByProps) => {
  const { t } = useTranslation();
  const isLg = useBreakpoint('lg');
  const scrollToFilters = useScrollToFilters();
  const { lockScroll, unlockScroll } = useLockScroll('#___gatsby');
  const { query } = useSearchBox({});
  const { currentRefinement, refine, items } = useSortByOptions();

  const [open, setOpen] = useState(false);
  const onOpen = () => {
    if (isLg) {
      scrollToFilters();
      lockScroll();
    }
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    unlockScroll();
  };

  const onClick = (value: string) => {
    refine(value);
    onClose();
  };

  const option =
    items.find(e => e.value === currentRefinement) ||
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    items.find(e => e.value === INITIAL_SORT_VALUE)!;
  const dropdownItems = items
    .filter(({ value }) => (query ? true : value !== DefaultIndex))
    .map(({ hint, hintFirst, value, label }) => (
      <DropdownMenuItem
        key={value}
        className={classes.menuItem}
        value={value}
        label={hintFirst ? hint || '' : label}
        hint={hintFirst ? label : hint}
        active={value === option.value}
        icon={value === option.value ? <CheckIcon /> : undefined}
        onClick={onClick}
      />
    ));

  return (
    <div className={className}>
      <SelectInput
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        label={t('sort-by')}
        value={option.label}
      >
        {!isLg && (
          <FullScreenModal isOpen={open} onRequestClose={onClose}>
            <BottomSheet label={t('sort-by')}>{dropdownItems}</BottomSheet>
          </FullScreenModal>
        )}
        {isLg && (
          <PositionedContentModal onRequestClose={onClose} minHeight={480}>
            <Dropdown className={classes.dropdown}>{dropdownItems}</Dropdown>
          </PositionedContentModal>
        )}
      </SelectInput>
    </div>
  );
};

const classes = {
  dropdown: clsx('mt-2 absolute w-[280px]', ZIndex(Layer.Modal)),
  menuItem: 'pl-6',
};
