export function slugify(s: string): string {
  return s
    .toLowerCase()
    .trim()
    .normalize('NFD') // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
}

export function camelCase(s: string): string {
  return s
    .toLowerCase()
    .replace(/[-_]+/g, '-')
    .replace(/([-_][a-z])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
}

export function capWord(s: string): string {
  if (s.length === 0) {
    return s;
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function isString(s: unknown): s is string {
  return typeof s === 'string';
}
