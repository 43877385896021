import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../../../templates/layout/layout';
import { StaticImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../attributes';
import { useSiteMetadata } from '../../../hooks/use-site-metadata';

const NotFound = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    description: t('seo-404-description'),
    title: t('seo-404-title'),
    lang: i18n.language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: '/',
    noIndex: true,
  };

  return (
    <Layout metaTagProps={metaTagProps}>
      <div className="col-span-full flex flex-col items-center">
        <h1>{t('page-not-found-header')}</h1>
        <Link to={`/${i18n.language}`} aria-label={t('go-to-home-page')}>
          <StaticImage
            src="../../../../static/images/impact-bot-not-found.svg"
            alt={decorativeImageAlt}
            placeholder="none"
          />
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
