import React from 'react';
import Modal, { Props } from 'react-modal';
import { useHideFreshworks } from '../../hooks/use-hide-freshworks';

type FullScreenModalProps = Omit<Props, 'style'> & {
  contentClassName?: string;
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // If we import zIndex class from `templates/layout` it's rewrited by header
    zIndex: 40,
    backgroundColor: 'rgba(32, 32, 32, 0.25)',
  },
  content: {
    padding: 0,
    inset: 0,
    background: 'transparent',
    border: 'none',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
} as const;

function FullScreenModal({
  isOpen,
  onRequestClose,
  className,
  contentClassName,
  ...rest
}: FullScreenModalProps) {
  useHideFreshworks(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={styles}
      className={className}
      contentElement={(props, children) => (
        <div {...props} onClick={onRequestClose}>
          <div className={contentClassName} onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </div>
      )}
      {...rest}
    />
  );
}

export default FullScreenModal;
