import React from 'react';
import clsx from 'clsx';

const classes = {
  root: {
    default: 'flex flex-row justify-between items-center pl-3.5 pr-3 py-2.5',
    hover: 'hover:bg-fiber-grey-100',
    active: 'bg-fiber-grey-100',
    clickable: 'cursor-pointer',
  },
  icon: 'w-5',
  hint: 'ml-2 text-fiber-neutral-500',
};

interface DropdownMenuItemProps<T extends string | number> {
  value: T;
  label: string;
  hint?: string;
  icon?: JSX.Element;
  active?: boolean;
  onClick?: (value: T) => void;
  className?: string;
}

function DropdownMenuItem<T extends string | number>({
  value,
  label,
  hint,
  icon,
  active,
  onClick,
  className,
}: DropdownMenuItemProps<T>) {
  return (
    <div
      className={clsx(
        classes.root.default,
        classes.root.hover,
        { [classes.root.active]: active, [classes.root.clickable]: onClick },
        className
      )}
      onClick={() => onClick?.(value)}
    >
      <span>
        {label}
        {hint && <span className={classes.hint}>{hint}</span>}
      </span>
      <span className={classes.icon}>{icon}</span>
    </div>
  );
}

export default DropdownMenuItem;
