import { useEffect } from 'react';
import { useHitsPerPage } from 'react-instantsearch';

export const HITS_PER_PAGE = 25;
export const ONE_PAGE_HITS = HITS_PER_PAGE * 2;

const HITS_PER_PAGE_ITEMS = [
  {
    label: `${HITS_PER_PAGE} hits per page`,
    value: HITS_PER_PAGE,
    default: true,
  },
  { label: `${ONE_PAGE_HITS} hits per page`, value: ONE_PAGE_HITS },
];

const findCurrentPageHits = (nbHits: number) =>
  nbHits <= ONE_PAGE_HITS ? ONE_PAGE_HITS : HITS_PER_PAGE;

export const useCurrentPageHits = (nbHits: number) => {
  const { canRefine, refine } = useHitsPerPage({
    items: HITS_PER_PAGE_ITEMS,
  });

  useEffect(() => {
    if (!canRefine) return;
    refine(findCurrentPageHits(nbHits));

    /**
     * Instantsearch is returning a new function on every render which triggers an infinite loop
     *
     * This is particular to the hits-per-page feature
     */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbHits, canRefine]);
};
