import { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';

export const typesenceClusterConfiguration: Omit<
  ConfigurationOptions,
  'apiKey'
> = {
  nearestNode: {
    host: '94eo8lmsqa0nd3j5p.a1.typesense.net',
    port: 443,
    protocol: 'https',
  },
  nodes: [
    {
      host: '94eo8lmsqa0nd3j5p-1.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
    {
      host: '94eo8lmsqa0nd3j5p-2.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
    {
      host: '94eo8lmsqa0nd3j5p-3.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
    {
      host: '94eo8lmsqa0nd3j5p-4.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
    {
      host: '94eo8lmsqa0nd3j5p-5.a1.typesense.net',
      port: 443,
      protocol: 'https',
    },
  ],
};
