import { FromDecoder, JsonDecoder } from 'ts.data.json';
import {
  RichTextDecoder,
  CommonModelDecoder,
  GatsbyImageDecoder,
} from '../common';
import { UrlDecoder } from './url';

export const ListItemDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      title: JsonDecoder.string,
      description: RichTextDecoder,
      image: GatsbyImageDecoder,
      link: JsonDecoder.optional(UrlDecoder),
    },
    'List Item'
  )
);

export type ListItemModel = FromDecoder<typeof ListItemDecoder>;
