export function parseIntegerWithDefault(
  params: URLSearchParams,
  paramKey: string,
  defaultValue: number
): number {
  const value = params.get(paramKey);
  const result = value ? Number.parseInt(value) : null;

  if (!result || isNaN(result)) {
    return defaultValue;
  }

  return result;
}

export function parseStringWithDefault(
  params: URLSearchParams,
  paramKey: string,
  defaultValue: string
): string {
  const result = params.get(paramKey);

  if (!result) {
    return defaultValue;
  }

  return decodeURIComponent(result);
}

export function parseOptionalString(
  params: URLSearchParams,
  paramKey: string
): string | undefined {
  const result = params.get(paramKey);

  if (!result) {
    return undefined;
  }

  return decodeURIComponent(result);
}

/**
 * Matches possible mutliple search params that are key value pairs split by = sign.
 *
 * ex: &refinement=key1%3Dvalue1?refinement=key2%3Dvalue2
 */
export function parseLists(
  params: URLSearchParams,
  paramKey: string
): { [key: string]: string[] } | undefined {
  const rawValues = params.getAll(paramKey);
  if (rawValues.length === 0) {
    return undefined;
  }

  const result: { [key: string]: string[] } = {};

  rawValues.reduce((accum, current) => {
    const pieces = decodeURIComponent(current).split('=');
    const key = pieces[0];
    const value = pieces.length > 1 ? pieces[1] : null;
    if (!key || !value) {
      throw new Error(`unknown ${key} ${current}`);
    }

    const currentValue = accum[key] ?? [];
    accum[key] = [...currentValue, value];

    return accum;
  }, result);

  return result;
}

export function combinePaths(...args: string[]): string {
  return args.reduce((accum, path, index) => {
    if (
      index === 0 &&
      (path.startsWith('https://') || path.startsWith('http://'))
    ) {
      return path;
    }

    const trimmedPath = path.trim().replace(/^\/+/, '').replace(/\/+$/, '');
    if (trimmedPath === '') {
      return accum;
    }

    if (accum === 'https://' || accum === 'http://') {
      return `${accum}${trimmedPath}`;
    }

    return `${accum}/${trimmedPath}`;
  }, '');
}
