import React from 'react';
import clsx from 'clsx';
import FunnelSimpleIcon from '../../fiber/icons/outline/office-editing/funnel-simple';
import Pill from '../../fiber/components/pill/pill';
import { REFINEMENT_ITEMS } from './constants';
import { useControlRefinementList } from './refinement-list';
import { useScrollToFilters, getFiltersPosition } from './scroll';
import { useWindowScroll } from 'react-use';

interface FiltersButtonProps {
  className?: string;
}

const classes = {
  root: 'cursor-pointer relative w-10 text-fiber-neutral-500',
  pill: 'absolute top-0 right-0 max-w-[20px] h-5 text-white bg-fiber-grey-900 text-xs',
};

export const FiltersButton = ({ className }: FiltersButtonProps) => {
  // trigger rerender on scroll
  useWindowScroll();
  const scrollToFilters = useScrollToFilters();

  const appliedFiltersCount = REFINEMENT_ITEMS.map(
    useControlRefinementList
  ).reduce((sum, list) => sum + list.items.filter(e => e.isRefined).length, 0);

  const hideFilters = getFiltersPosition() > 0;
  if (hideFilters) return null;

  return (
    <div className={clsx(classes.root, className)} onClick={scrollToFilters}>
      <FunnelSimpleIcon />
      <Pill label={`${appliedFiltersCount}`} className={classes.pill} />
    </div>
  );
};
