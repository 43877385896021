import { scroller } from 'react-scroll';
import { useBreakpoint } from '../../hooks/use-breakpoint';

export { Element as ScrollElement } from 'react-scroll';

export const SCROLL_ELEMENT_NAME = 'stats';

const getOffset = (isLg?: boolean) => (isLg ? -312 : -412);

export const getFiltersPosition = (isLg?: boolean) => {
  const elem = scroller.get(SCROLL_ELEMENT_NAME);
  if (!elem) return undefined;

  const top = elem.getBoundingClientRect()?.top;
  if (top === undefined) return undefined;

  return top + getOffset(isLg);
};

export const useScrollToFilters = () => {
  const isLg = useBreakpoint('lg');

  return () =>
    scroller.scrollTo(SCROLL_ELEMENT_NAME, {
      name: 'scroll',
      smooth: true,
      offset: getOffset(isLg),
    });
};
