/**
 * Central location for URL's to be used in routing and page generation.
 */

import { Err, Ok, Result } from 'ts.data.json';
import {
  ContentfulBlogPostEntryFragmentFragment,
  ContentfulGenericEntryFragmentFragment,
  ContentfulLandingEntryFragmentFragment,
  ContentfulPressPostEntryFragmentFragment,
  ContentfulStandardEntryFragmentFragment,
} from '../__generated__/graphql-types';
import { NewsItem, isBlogPost, isPressPost } from '../hooks/use-news-items';
import {
  CommonModel,
  ContentfulNewsItemTypes,
  ContentfulPageTypes,
  contentfulEntryUrl,
} from '../library/models/common';
import { enableStrictDecoderErrors } from './env';
import { combinePaths } from './url-parsing';

export function companyProfileUrl(slug: string): string {
  return `/find-a-b-corp/company/${slug}`;
}

export function searchIndexUrl(): string {
  return '/find-a-b-corp';
}

export function legalIndexUrl(): string {
  return '/about-b-corps/legal-requirements';
}

export function newsIndexUrl(page?: number): string {
  const q = page !== undefined ? `?page=${page}` : '';
  return `/news/blog${q}`;
}

export function blogIndexUrl(page?: number): string {
  const q = page !== undefined ? `?page=${page}` : '';
  return `/news/blog${q}`;
}

export function pressIndexUrl(page?: number): string {
  const q = page !== undefined ? `?page=${page}` : '';
  return `/news/press${q}`;
}

export function mediaIndexUrl(page?: number): string {
  const q = page !== undefined ? `?page=${page}` : '';
  return `/news/media${q}`;
}

export function newsItemUrl(item: NewsItem): string {
  if (isBlogPost(item)) {
    return combinePaths(item.node_locale, blogPostUrl(item.slug));
  }

  if (isPressPost(item)) {
    return combinePaths(item.node_locale, pressPostUrl(item.slug));
  }

  return item.link;
}

export function blogPostUrl(slug: string): string {
  return `/news/blog/${slug}`;
}

export function pressPostUrl(slug: string): string {
  return `/news/press/${slug}`;
}

export function ourTeamUrl(): string {
  return `/movement/team`;
}

export function globalNetworkUrl(): string {
  return `/movement/global-network`;
}

export function certificationUrl(): string {
  return `/certification`;
}

function relatedContentErrorMessage(name: string, slug: string): string {
  return `A related ${name} entry does not have a registered slug in our URL maps: ${slug}
Recommend unpublishing pages or adding the contentful ID to our URL maps
See links (displayed near entity name) to identify where this unpublished page is being used: ${contentfulEntryUrl(
    slug
  )}`;
}

function missingSlugErrorMessage(name: string, slug: string): string {
  return `A related ${name} entry has an empty slug field: ${slug}
Simply updating the record with a slug should fix this
See entity definition at ${contentfulEntryUrl(slug)}`;
}

export function createRelatedContentUrl<T extends CommonModel>(
  model: T
): string {
  const result = createRelatedContentUrlHelper(model);
  if (!result.isOk()) {
    if (enableStrictDecoderErrors()) {
      throw new Error(result.error);
    }

    // eslint-disable-next-line no-console
    console.warn(result.error);
    return '/linked-entry-unpublished';
  }

  return result.value;
}

function createRelatedContentUrlHelper<T extends CommonModel>(
  model: T
): Result<string> {
  switch (model.contentful_type) {
    case ContentfulPageTypes.ContentfulStandardPage: {
      const standardEntity = model as ContentfulStandardEntryFragmentFragment;
      const slug = StandardPagesUrlMap.get(standardEntity.contentful_id);
      if (!slug) {
        return new Err(
          relatedContentErrorMessage(
            'Standard Page',
            standardEntity.contentful_id
          )
        );
      }
      return new Ok(`/${standardEntity.node_locale.toLowerCase()}/${slug}`);
    }
    case ContentfulPageTypes.ContentfulGenericPage: {
      const genericEntity = model as ContentfulGenericEntryFragmentFragment;
      const slug = GenericPagesUrlMap.get(genericEntity.contentful_id);
      if (!slug) {
        return new Err(
          relatedContentErrorMessage(
            'Generic Page',
            genericEntity.contentful_id
          )
        );
      }

      return new Ok(`/${genericEntity.node_locale.toLowerCase()}/${slug}`);
    }
    case ContentfulPageTypes.ContentfulLandingPage: {
      const landingEntity = model as ContentfulLandingEntryFragmentFragment;
      if (!landingEntity.slug) {
        return new Err(
          missingSlugErrorMessage('Landing Page', landingEntity.contentful_id)
        );
      }

      return new Ok(
        combinePaths(
          landingEntity.node_locale.toLowerCase(),
          landingEntity.slug
        )
      );
    }
    case ContentfulNewsItemTypes.ContentfulBlogPost: {
      const blogPostEntity = model as ContentfulBlogPostEntryFragmentFragment;
      if (!blogPostEntity.slug) {
        return new Err(
          missingSlugErrorMessage('Blog Post', blogPostEntity.contentful_id)
        );
      }

      return new Ok(
        combinePaths(
          blogPostEntity.node_locale.toLowerCase(),
          blogPostUrl(blogPostEntity.slug)
        )
      );
    }
    case ContentfulNewsItemTypes.ContentfulPressPost: {
      const pressPostEntity = model as ContentfulPressPostEntryFragmentFragment;
      if (!pressPostEntity.slug) {
        return new Err(
          missingSlugErrorMessage('Press Post', pressPostEntity.contentful_id)
        );
      }

      return new Ok(
        combinePaths(
          pressPostEntity.node_locale.toLowerCase(),
          pressPostUrl(pressPostEntity.slug)
        )
      );
    }
    default: {
      return new Err(`unsupported entry type: ${model.contentful_type}`);
    }
  }
}

/**
 * unique identifiers to specific pages in contentul.
 *
 * content models:
 *
 * https://app.contentful.com/spaces/l575jm7617lt/content_types/genericPage/fields
 * https://app.contentful.com/spaces/l575jm7617lt/content_types/standardPage/fields
 *
 * list of pages:
 *
 * https://app.contentful.com/spaces/l575jm7617lt/entries?id=PXQPyKIQ5aBKxbqW&contentTypeId=genericPage&order.fieldId=updatedAt&order.direction=descending
 * https://app.contentful.com/spaces/l575jm7617lt/entries?id=PXQPyKIQ5aBKxbqW&contentTypeId=standardPage&order.fieldId=updatedAt&order.direction=descending
 */

export const termsOfUseUrl = 'terms-of-use';
export const cookiePolicyUrl = 'cookie-policy';
export const securityNoticeUrl = 'security-notice';
export const certificationNoticeUrl = 'certification-notice';
export const privacyPolicyUrl = 'privacy-policy';

export const GenericPagesUrlMap: Map<string, string> = new Map([
  ['73Dk654rOx60ny0aewQGu4', termsOfUseUrl],
  ['2zKKmEkYHVZtFG5H8pDHWI', cookiePolicyUrl],
  ['3Z9wbKGOMGWbtndgGUvwNG', securityNoticeUrl],
  ['1NYQjkhtChUzeelyZWLD2K', certificationNoticeUrl],
  ['1ORDxmGfH4TrSh4TRFNInV', privacyPolicyUrl],
  ['2B9uMA58FJj9kSNvOEqJjw', 'faqs/how-long-does-it-take-get-certified'],
  ['K9vE5DFOuljowWXqDoUHY', 'faqs/are-any-b-corps-publicly-traded'],
  [
    '6PI4dAys2nMhUOAwB3mSkc',
    'faqs/whats-difference-between-certified-b-corp-and-benefit-corporation',
  ],
  [
    '3llCjMHpqmKuFZFw9FRuuU',
    'faqs/how-much-does-it-cost-be-certified-b-corporation',
  ],
  ['MWTIho07cCD8pphmHQ4dT', 'faqs/how-did-b-corp-movement-start'],
  [
    '7v9DQQDezPVpwabV9HCJTU',
    'faqs/what-companies-have-highest-scores-b-impact-assessment',
  ],
  [
    'Ws6aXoMVPlZbZKl3WYMoG',
    'faqs/how-many-certified-b-corps-are-there-around-world',
  ],
  ['2MeeWzCoDVWG6szMa94bEg', 'faqs/are-b-corps-hiring'],
  [
    '3LbAhEtL0e3KYzJGUbbMNY',
    'faqs/what-topics-are-covered-b-impact-assessment',
  ],
  ['j0oOlvHxoVggXBEjgZBox', 'faqs/can-start-ups-certify-b-corps'],
  ['7eZMIWTWvpXEs0hqWkpBfe', 'faqs/how-long-does-it-take-get-certified'],
  [
    '3qthfjXaIeZhiosYcfEtCy',
    'faqs/how-can-organizations-get-involved-with-b-corp-movement',
  ],
  ['2zocRu4PPqn9uuDetJV069', 'faqs/what-average-size-certified-b-corp'],
  ['2EJEg5HfX3WBU84n33ceRM', 'faqs/are-there-b-corps-my-area'],
  ['3btKnw1DFh5tNMyD2reu6I', 'faqs/can-sole-proprietors-get-b-corp-certified'],
  ['1YOyXFVOy3iCnf8afUXGFS', 'faqs/who-eligible-b-corp-certification'],
  [
    '3eBHEBNvLkr9FHn6fd3AJp',
    'faqs/will-i-need-change-my-companys-legal-structure-certify',
  ],
  [
    'Zudj65EBb0JCqHBKySLOH',
    'faqs/what-information-do-certified-b-corps-have-make-transparent',
  ],
  [
    '1Pre8OzVpjHitiUVzpGb54',
    'faqs/what-if-i-have-complaint-about-certified-b-corp',
  ],
  [
    '1PmMBriGDCZuYDLiCBzVQq',
    'faqs/where-do-standards-b-corp-certification-come',
  ],
  [
    '5kcIMH5DEOvJoq5Wiwr9qy',
    'faqs/can-subsidiaries-or-franchises-pursue-b-corp-certification',
  ],
  ['elaZg7hjO30D82uWulg6W', 'faqs/who-certifies-b-corps'],
  [
    '6UpdCMcczaLKhiiiCF7dqj',
    'faqs/temporary-changes-to-certification-support-2023',
  ],
]);

export const publicComplaintsProcessUrl = 'standards/complaints';
export const controversialIssuesUrl = 'standards/controversial-issues';
export const developmentGovernanceUrl = 'standards/development-and-governance';
export const advisoryCouncilUrl = 'standards/advisory-council';
export const advisoryWorkingGroupsUrl = 'standards/advisory-working-groups';
export const performanceRequirementsUrl = 'standards/performance-requirements';

export const aboutBLabPageUrl = 'movement/about-b-lab';
export const theoryOfChangeUrl = 'movement/theory-of-change';
export const stakeholderGovernanceUrl = 'movement/stakeholder-governance';
export const jediUrl = 'movement/justice-equity-diversity-inclusion';
export const collaborationsAndPartnershipsUrl =
  'movement/collaborations-partnerships';
export const careersUrl = 'movement/careers';
export const contactUsUrl = 'movement/contact-us';

export const supportOurWorkUrl = 'support-our-work';
export const shareFeedbackUrl = 'https://bit.ly/3vjPuvT';

export const bMovementBuildersUrl = 'programs-and-tools/b-movement-builders';
export const pendingBCorpProgramUrl = 'programs-and-tools/pending-b-corps';
export const collectiveActionUrl = 'programs-and-tools/collective-action';
export const sdgActionManagerUrl = 'programs-and-tools/sdg-action-manager';
export const sustainableDevelopmentGoalsUrl =
  'programs-and-tools/sustainable-development-goals';
export const bImpactAssessmentUrl = 'programs-and-tools/b-impact-assessment';
export const policyAndAdvocacyUrl = 'programs-and-tools/policy-and-advocacy';

export const aboutBCorporationCertificationUrl = 'certification';
export const aboutLargeEnterpriseUrl =
  'certification/large-enterprise-multinational';
export const aboutBCorpsUrl = 'resources';
export const faqsUrl = 'faqs';

export const careersContentfulID = '4lmMraraE5ObXPUVErR9Ek';
export const foundersFundUrl = 'the-founders-fund';
export const StandardPagesUrlMap: Map<string, string> = new Map([
  ['2dZAbyo1563SrIMsSRbg12', aboutBLabPageUrl],
  ['8kv0UbZYS8fqJY6A4WFwM', publicComplaintsProcessUrl],
  ['4vIpXNSY8UV27oW7vLYMGM', controversialIssuesUrl],
  ['Kqs2PfMLZSEFEr6AYIZ9F', developmentGovernanceUrl],
  ['eSOjoDIKESfaTh9mSJqeZ', advisoryCouncilUrl],
  ['yy3K7V1MYcEHMEnCckyH6', advisoryWorkingGroupsUrl],
  ['Z75NcRyxsvDfHitLWMq8w', theoryOfChangeUrl],
  ['g7uzKP90KjeTilzAVuxFQ', stakeholderGovernanceUrl],
  ['36IxGT1AijC9AW7cWpD4T8', jediUrl],
  ['2tDuJb8vobtBDeFwILNPkD', collaborationsAndPartnershipsUrl],
  ['7hVUraeAtloSL8i4wjGZiT', bMovementBuildersUrl],
  ['1P73qXqn8hu8lzZKC7E6O2', pendingBCorpProgramUrl],
  ['cMe7XmmCTD05zNB8azHOD', collectiveActionUrl],
  [careersContentfulID, careersUrl],
  ['7vkVWYMkSZSzJSpqSvCpvS', sdgActionManagerUrl],
  ['RNGThq3iARlf7VZ5NBM8H', foundersFundUrl],
  ['5WA2YqGV7zqOd2o3qIFdOM', performanceRequirementsUrl],
  ['3psQ8lIu9QdQWc0MgUbMmi', sustainableDevelopmentGoalsUrl],
  ['27l8qLXWF4VoKMWJRFRzcR', contactUsUrl],
  ['5sg4OyNyJcPuF2LXOGAzqt', aboutBCorporationCertificationUrl],
  ['JUn8SDNmd1DXauozRfvOP', policyAndAdvocacyUrl],
  ['2vp40eeOcNg0UJgLmktk4F', aboutBCorpsUrl],
  ['3tYkJf2F8y0KY54SqCFQgT', aboutLargeEnterpriseUrl],
  ['1A76nBzaNtF6bHnBiPIPpF', faqsUrl],
  ['1WgAG55njn0KvlVpZ9f0zW', supportOurWorkUrl],
]);

export const bCorpMonthUrl = 'b-corp-month';
export const programsAndToolsOverviewUrl = 'programs-and-tools';
export const movementOverviewUrl = 'movement';
export const standardsOverviewUrl = 'standards';

export const OverviewCollectionUrlMap: Map<string, string> = new Map([
  ['6c2u5KjqXgpTOSChNQQoBy', programsAndToolsOverviewUrl],
  ['4mMzt4CTbOd4QgtRhhx02f', movementOverviewUrl],
  ['15zEJEwdDbTsvwvrkUQx0T', standardsOverviewUrl],
]);

export const EvenPagesUrlMap: Map<string, string> = new Map([
  ['5tDxDFMOIUQ6gomZVxi49k', bCorpMonthUrl],
]);
