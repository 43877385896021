import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import InputWrapper, { InputWrapperProps } from './input-wrapper';
import MagnifyingGlassIcon from '../../icons/outline/system-devices/magnifying-glass';
import XCircleIcon from '../../icons/outline/system-devices/x-circle';

// TODO: remove defaults from global.css to use class names without !important
const classes = {
  input: {
    default: 'ml-2 !p-0 !border-none w-full',
    disabled: 'cursor-not-allowed bg-surface-light',
    focusVisible: 'focus-visible:outline-none',
    withValue: '!text-fiber-neutral-900',
    withoutValue: '!text-fiber-neutral-400',
  },
  icon: {
    default: 'w-5',
    disabled: 'text-fiber-neutral-400',
  },
};

interface SearchInputProps
  extends Pick<InputWrapperProps, 'disabled' | 'error' | 'className'> {
  value: string;
  placeholder?: string;
  focused?: boolean;
  onChange: (value: string) => void;
}

function SearchInput({
  value,
  placeholder,
  onChange: onChangeProp,
  disabled,
  focused: focusedProp = false,
  ...rest
}: SearchInputProps) {
  const [focused, setFocused] = useState(() => focusedProp);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChangeProp(e.target.value);
  const onFocus = () => setFocused(true);
  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (e.relatedTarget && e.relatedTarget === buttonRef.current) {
      // Cancel blur event when clicked on clean button
      return;
    }
    setFocused(false);
  };
  const onClean = () => {
    onChangeProp('');
    setFocused(false);
  };

  const showClean = value !== '' && focused;

  return (
    <InputWrapper {...rest} {...{ focused, disabled }}>
      <span
        className={clsx(classes.icon.default, {
          [classes.icon.disabled]: disabled,
        })}
      >
        <MagnifyingGlassIcon />
      </span>
      <input
        type="text"
        {...{ value, placeholder, disabled, onChange, onFocus, onBlur }}
        className={clsx(classes.input.default, classes.input.focusVisible, {
          [classes.input.withValue]: !disabled && value,
          [classes.input.withoutValue]: disabled || !value,
          [classes.input.disabled]: disabled,
        })}
      />
      {showClean && (
        <button
          ref={buttonRef}
          onClick={onClean}
          className={clsx(classes.icon.default)}
        >
          <XCircleIcon />
        </button>
      )}
    </InputWrapper>
  );
}

export default SearchInput;
