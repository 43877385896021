import React, { FC } from 'react';
import { useChangeLanguage } from '../../library/i18n';
import { StandardPage as StandardPageModel } from '../../library/models/standard-page';
import { LandingPage as LandingPageModel } from '../../library/models/landing-page';
import Layout from '../layout/layout';
import { WrappedRichText } from '../../components/rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../components/attributes';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import CircleNavigation from '../../components/pages/index/circle-navigation';
import { CallToAction } from '../../components/call-to-action';

export type StandardPageContext = {
  pageModel: StandardPageModel | LandingPageModel;
  pagePath: string;
};

export interface StandardPageProps {
  readonly pageContext: StandardPageContext;
}

const StandardPage: FC<StandardPageProps> = ({
  pageContext: { pageModel, pagePath },
}): JSX.Element => {
  useChangeLanguage(pageModel.node_locale);

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    lang: pageModel.node_locale,
    title: pageModel.seoTitle,
    description: pageModel.seoDescription,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
    ogImage: pageModel.headerHero?.image,
  };

  let hero = <></>;
  if (pageModel.headerHero) {
    hero = (
      <div className="col-span-full flex flex-col lg:breakout">
        <GatsbyImage
          className="w-full rounded-3xl"
          image={pageModel.headerHero.image}
          alt={decorativeImageAlt}
        />
        {!pageModel.headerHero.callout ? (
          <></>
        ) : (
          <WrappedRichText
            className="lg:w-80 lg:ml-28 lg:-mt-20 mt-4 z-0 bg-gray-light rounded-3xl flex-col-stack-2 py-6 px-6"
            richText={pageModel.headerHero.callout}
          />
        )}
      </div>
    );
  }

  let bodySpan = 'col-span-full';
  let bodyCallout = <></>;
  if (pageModel.bodyCallout) {
    bodySpan = 'col-span-full lg:col-span-9';
    bodyCallout = (
      <div className="col-span-full lg:col-span-3 px-2 font-bold text-lg">
        {pageModel.bodyCallout}
      </div>
    );
  }

  const callToAction = pageModel.callToAction ? (
    <CallToAction className="col-span-full" content={pageModel.callToAction} />
  ) : (
    <></>
  );

  return (
    <Layout metaTagProps={metaTagProps}>
      <div className="col-span-full lg:col-span-8 flex flex-col-stack-1">
        <h1 className="font-bold text-xl uppercase">{pageModel.header}</h1>
        <div className="font-bold text-4xl">{pageModel.subHeader}</div>
      </div>
      {hero}
      {bodyCallout}
      <CircleNavigation items={pageModel.circleNavigationItems || []} />
      <WrappedRichText
        className={`${bodySpan} flex-col-stack-4`}
        richText={pageModel.body}
      />
      {callToAction}
      <CircleNavigation items={pageModel.circleNavigationItems || []} />
    </Layout>
  );
};

export default StandardPage;
