import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Demographic } from '../../library/models/pending-company';
import FullScreenModal from '../../components/modal/full-screen-modal';
import BottomSheet from '../../components/dropdown/bottom-sheet';
import { useBreakpoint } from '../../hooks/use-breakpoint';
import { useLockScroll } from '../../hooks/use-lock-scroll';
import SelectInput from '../../fiber/components/input/select-input';
import PositionedContentModal from '../modal/positioned-content-modal';
import { Search } from './search';
import { RefinementItem } from './constants';
import { useScrollToFilters } from './scroll';
import { useControlRefinementList } from './refinement-list';
import { sortByLabel } from './helpers';

const DEMOGRAPHIC_LABELS: Record<Demographic, string> = {
  womenOwned: 'Women',
  minorityOwned: 'Minority',
  lgbtqOwned: 'LGBTQ',
  blackOwned: 'Black',
  employeeOwned: 'Employee',
  familyOwned: 'Family',
  veteranOwned: 'Veteran',
  indigenousOwned: 'Indigenous',
  personWithDisabilityOwned: 'Person with disability',
  immigrantOwned: 'Immigrant',
};
const DEMOGRAPHIC_OPTIONS = Object.keys(DEMOGRAPHIC_LABELS) as Demographic[];

interface OwnershipSearchProps {
  className?: string;
}

const HEADERS_AREA_HEIGHT = 320;
const SEARCH_HEIGHT = 480 + 2 * 8;
const classes = {
  bottomSheet: 'w-screen max-h-3/4 flex flex-col !pb-0',
  search: 'my-2 absolute w-[408px] h-[480px]',
};

export const OwnershipSearch = ({ className }: OwnershipSearchProps) => {
  const { t } = useTranslation();
  const isLg = useBreakpoint('lg');
  const { lockScroll, unlockScroll } = useLockScroll('#___gatsby');
  const [open, setOpen] = useState(false);
  const { items, refine, clearAll } = useControlRefinementList(
    RefinementItem.demographicsList
  );
  const [searchTerm, onSearch] = useState('');
  const scrollToFilters = useScrollToFilters();

  const selected = new Set(items.filter(e => e.isRefined).map(e => e.value));

  const options = DEMOGRAPHIC_OPTIONS.map(value => ({
    value,
    label: DEMOGRAPHIC_LABELS[value],
    selected: selected.has(value),
  })).sort(sortByLabel);

  const onItemSelect = (itemID: string) => {
    refine(itemID);
    onSearch('');
  };

  const onOpen = () => {
    if (isLg) {
      scrollToFilters();
      lockScroll();
    }
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    unlockScroll();
  };

  const selectedCount = selected.size;

  return (
    <div className={className}>
      <SelectInput
        label={t('ownership')}
        {...{ open, onOpen, onClose, selectedCount }}
      >
        {!isLg && (
          <FullScreenModal isOpen={open} onRequestClose={onClose}>
            <BottomSheet label={t('ownership')} className={classes.bottomSheet}>
              <Search
                {...{
                  listLabel: t('demographics'),
                  items: options,
                  onItemSelect,
                  searchTerm,
                  onSearch,
                  onClearAll: clearAll,
                  onClose,
                }}
              />
            </BottomSheet>
          </FullScreenModal>
        )}
        {isLg && (
          <PositionedContentModal
            onRequestClose={onClose}
            minHeight={SEARCH_HEIGHT + HEADERS_AREA_HEIGHT}
          >
            <Search
              {...{
                listLabel: t('demographics'),
                items: options,
                onItemSelect,
                searchTerm,
                onSearch,
                onClearAll: clearAll,
                onClose,
              }}
              className={classes.search}
            />
          </PositionedContentModal>
        )}
      </SelectInput>
    </div>
  );
};
