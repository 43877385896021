import { Language, allSupportedLanguages } from '../i18n';
import { JsonDecoder, Result, Ok, Err } from 'ts.data.json';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import type { IGatsbyImageData } from 'gatsby-plugin-image';

const ContentfulSpaceID = 'l575jm7617lt';
export function contentfulEntryUrl(slug: string): string {
  return `https://app.contentful.com/spaces/${ContentfulSpaceID}/entries/${slug}`;
}

export const LanguageDecoder = JsonDecoder.string.chain(
  (language: string): JsonDecoder.Decoder<Language> => {
    // Legacy mapping of Portuguese language code
    const target =
      language.toLowerCase() === 'pt-pt' ? 'pt-br' : language.toLowerCase();

    const match = allSupportedLanguages().find(l => l === target);
    if (match) {
      return JsonDecoder.constant(match);
    }

    return JsonDecoder.fail(`unknown language ${language}`);
  }
);

export function arrayOrUndefined<T>(
  name: string,
  decoder: JsonDecoder.Decoder<T>
): JsonDecoder.Decoder<T[]> {
  return JsonDecoder.oneOf(
    [
      JsonDecoder.array(decoder, `${name}[]`),
      JsonDecoder.isUndefined([]),
      JsonDecoder.isNull([]),
    ],
    `${name}[] | undefined`
  );
}

export const TrimmedString = JsonDecoder.string.map(s => s.trim());

export const IntString = JsonDecoder.string.chain(s => {
  const result = parseInt(s);
  if (isNaN(result)) {
    JsonDecoder.fail<number>(`failed to decode int ${s}`);
  }

  return JsonDecoder.constant(result);
});

export const FloatString = JsonDecoder.string.chain(s => {
  const result = parseFloat(s);
  if (isNaN(result)) {
    JsonDecoder.fail<number>(`failed to decode float ${s}`);
  }

  return JsonDecoder.constant(result);
});

export const BooleanString = JsonDecoder.string.chain(s => {
  const result = s === 'true' ? true : false;

  return JsonDecoder.constant(result);
});

// non-strict decoders for these complex contentful data type
export const RichTextDecoder: JsonDecoder.Decoder<
  RenderRichTextData<ContentfulRichTextGatsbyReference>
> = JsonDecoder.succeed.chain(d => {
  if (d === undefined) {
    return JsonDecoder.fail('RichText is undefined');
  }

  if (d === null) {
    return JsonDecoder.fail('RichText is null');
  }

  return JsonDecoder.constant(d);
});

const GatsbyImageDataDecoder: JsonDecoder.Decoder<IGatsbyImageData> =
  JsonDecoder.succeed.chain(d => {
    if (d === undefined) {
      return JsonDecoder.fail('GatsbyImageData is undefined');
    }

    if (d === null) {
      return JsonDecoder.fail('GatsbyImageData is null');
    }

    return JsonDecoder.constant(d);
  });

export const GatsbyImageDecoder = JsonDecoder.objectStrict(
  { gatsbyImageData: GatsbyImageDataDecoder },
  'GatsbyImageData'
).map(s => s.gatsbyImageData);

export function combineResultErrors(...results: Result<unknown>[]): string[] {
  const result: string[] = [];
  results.forEach((r: Result<unknown>) => {
    if (!r.isOk()) {
      result.push(r.error);
    }
  });

  return result;
}

export function combineResults<T>(results: Result<T>[]): Result<T[]> {
  return results.reduce<Result<T[]>>((accum, r) => {
    if (r.isOk()) {
      return accum.map(b => [...b, r.value]);
    }

    if (accum.isOk()) {
      return new Err(r.error);
    }

    return new Err(`${accum.error} : ${r.error}`);
  }, new Ok([]));
}

export type CommonModel = {
  contentful_id: string;
  contentful_type: string;
  node_locale: Language;
};

export const CommonModelDecoder: JsonDecoder.Decoder<CommonModel> =
  JsonDecoder.object(
    {
      contentful_id: JsonDecoder.string,
      contentful_type: JsonDecoder.string,
      node_locale: LanguageDecoder,
    },
    'CommonModel'
  );

function commonErrorMessage(err: string, cm: CommonModel): string {
  return [
    err,
    JSON.stringify(cm, null, 2),
    `Contentful URL: ${contentfulEntryUrl(cm.contentful_id)}`,
  ].join('\n');
}

/**
 * Decoder for Contentful models, requires extending `CommonModel`.
 *
 * Decoder is strict in production.  In other environments, individual models that fail decoding are logged and ignored.
 */
export function decodeContentfulModel<R, T extends CommonModel>(
  strictErrors: boolean,
  name: string,
  data: R[],
  decoder: JsonDecoder.Decoder<T>
) {
  if (strictErrors) {
    return JsonDecoder.array(decoder, `${name}[]`).decode(data);
  } else {
    const result = data.map(d => {
      const r = decoder.decode(d);

      // Log any errors
      if (!r.isOk()) {
        const common = CommonModelDecoder.decode(d);
        if (common.isOk()) {
          console.error(commonErrorMessage(r.error, common.value));
        } else {
          console.error(r.error);
        }
      }

      return r;
    });
    // Return only successful results
    return combineResults(result.filter(r => r.isOk()));
  }
}

// Cannot use Date objects due to serialization of page context
// Validating the expected string is a Date but leaving plain string for now.
export type ValidDate = string;
export const ValidDateDecoder: JsonDecoder.Decoder<ValidDate> =
  JsonDecoder.string.chain<ValidDate>(s => {
    const date = new Date(s);

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#return_value
    if (date.toString() === 'Invalid Date') {
      return JsonDecoder.fail(`invalid date found ${s}`);
    }

    return JsonDecoder.constant(s);
  });

export type ValidTimestamp = number;
export const ValidTimestampDecoder: JsonDecoder.Decoder<ValidTimestamp> =
  JsonDecoder.number.chain(d => {
    const date = new Date(d);

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#return_value
    if (date.toString() === 'Invalid Date') {
      return JsonDecoder.fail(`invalid date found ${d}`);
    }

    return JsonDecoder.constant(d);
  });

// Attempting to reduce reliance on bare strings in mutilple places
export enum ContentfulNewsItemTypes {
  ContentfulBlogPost = 'ContentfulBlogPost',
  ContentfulMediaPost = 'ContentfulMediaPost',
  ContentfulPressPost = 'ContentfulPressPost',
}

export enum ContentfulPageTypes {
  ContentfulStandardPage = 'ContentfulStandardPage',
  ContentfulGenericPage = 'ContentfulGenericPage',
  ContentfulLandingPage = 'ContentfulLandingPage',
}

export enum EventTypes {
  Virtual = 'Virtual',
  InPerson = 'In-person',
  ForBCorps = 'For B Corps',
}

// Errors are truncated in the log.  Not sure the exact limit.
export function formatDecoderError(err: string): string {
  if (err.length <= 1200) {
    return err;
  }

  return `${err.substring(0, 600)} ... ${err.substring(err.length - 600)}`;
}
