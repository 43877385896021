import { JsonDecoder, FromDecoder } from 'ts.data.json';
import { GatsbyImageDecoder, CommonModelDecoder } from './common';

export const CallToActionDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      title: JsonDecoder.string,
      image: GatsbyImageDecoder,
      body: JsonDecoder.objectStrict(
        {
          body: JsonDecoder.string,
        },
        'body'
      ).map(s => s.body),
      ctaLabel: JsonDecoder.string,
      url: JsonDecoder.string,
    },
    'CallToAction'
  )
);

export type CallToAction = FromDecoder<typeof CallToActionDecoder>;
