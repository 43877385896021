export function debounce(f: () => void, wait: number): () => void {
  let timeout: NodeJS.Timeout | null = null;

  return () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => f(), wait);
  };
}
