import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '../../../templates/layout/layout';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../attributes';
import { Carousel } from '../../carousel';
import { Quote } from '../../../hooks/use-quotes';

const CarouselBlurb = (props: {
  content: Quote;
  className?: string;
  style: CSSProperties;
}): JSX.Element => {
  const className = `flex-col-stack-3 ${props.className || ''}`.trim();

  return (
    <div data-testid="quote" className={className} style={props.style}>
      <div>
        <q className="font-bold text-lg">{props.content.content}</q>
      </div>
      <div className="flex-row-gutter-2 items-center">
        {
          <GatsbyImage
            image={props.content.authorImage}
            alt={decorativeImageAlt}
            className="rounded-full"
          />
        }
        <div className="text-sm flex-col-stack-0.5">
          <div data-testid="quote-author" className="font-bold">
            {props.content.author}
          </div>
          <div>{props.content.title}</div>
        </div>
      </div>
    </div>
  );
};

const Quotes = (props: { quotes: Quote[] }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {/** Desktop */}
      <Grid
        className="col-span-full hidden lg:grid"
        data-testid="quotes-section"
      >
        <div
          className="col-span-full text-3xl font-bold text-left"
          data-testid="carousel-header"
        >
          {t('carousel-header')}
        </div>
        <div className="row-start-2 col-start-1 col-end-5 self-center">
          <StaticImage
            src="../../../../static/images/letter_b.png"
            alt={decorativeImageAlt}
          />
        </div>
        <div className="row-start-2 col-start-4 col-end-13 bg-gray-light" />
        <div className="row-start-2 col-start-5 col-end-13 p-8 font-serif">
          <Carousel content={props.quotes} element={CarouselBlurb} />
        </div>
      </Grid>

      {/** Tablet + Mobile */}
      <Grid className="col-span-full lg:hidden" data-testid="quotes-section">
        <div
          className="col-span-full text-3xl font-bold text-center"
          data-testid="carousel-header"
        >
          {t('carousel-header')}
        </div>
        <div className="row-start-2 col-span-full self-center px-8">
          <StaticImage
            src="../../../../static/images/letter_b.png"
            alt={decorativeImageAlt}
          />
        </div>
        <div className="row-start-2 col-span-full row-end-4 bg-gray-light mt-20" />
        <div className="row-start-3 col-span-full px-8 pb-8 font-serif">
          <Carousel content={props.quotes} element={CarouselBlurb} />
        </div>
      </Grid>
    </>
  );
};

export default Quotes;
