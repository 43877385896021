import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import SearchInput from '../../fiber/components/input/search-input';
import Checkbox from '../../fiber/components/controls/checkbox';
import SectionDivider from '../../fiber/components/section-divider/section-divider';
import CheckIcon from '../../fiber/icons/outline/system-devices/check';
import XIcon from '../../fiber/icons/outline/math-finance/x';
import Button from '../../fiber/components/button/button';
import Link from '../../fiber/components/link/link';
import Pill from '../../fiber/components/pill/pill';
import Dropdown from '../dropdown/dropdown';
import DropdownMenuItem from '../dropdown/dropdown-menu-item';

interface SearchProps {
  listLabel: string;
  items: Array<{ value: string; label: string; selected?: boolean }>;
  onItemSelect: (itemID: string) => void;
  searchTerm?: string;
  onSearch: (searchTerm: string) => void;
  checkboxLabel?: string;
  checboxChecked?: boolean;
  onCheckboxClick?: (selected: boolean) => void;
  onClearAll?: () => void;
  onClose?: () => void;
  className?: string;
}

const classes = {
  scrollableArea: 'overflow-y-scroll pr-4',
  search: 'm-4',
  divider: 'mx-4',
  dividerText: 'mx-2 font-sans text-fiber-neutral-500',
  pills: 'flex flex-row flex-wrap gap-2 mx-4 my-0.5',
  pill: 'bg-surface-variant-light border border-surface-variant-light-outline',
  dropdownItems: 'flex flex-col gap-0.5 my-0.5',
  footer: 'm-4 flex flex-row justify-between',
  footerButtons: 'flex flex-row items-center gap-6',
};

const Divider = ({ text }: { text?: string }) => (
  <SectionDivider className={classes.divider}>
    {text && <p className={classes.dividerText}>{text}</p>}
  </SectionDivider>
);

export const Search = ({
  listLabel,
  items,
  onItemSelect,
  searchTerm = '',
  onSearch,
  checkboxLabel,
  checboxChecked,
  onCheckboxClick,
  onClearAll,
  onClose,
  className,
}: SearchProps) => {
  const { t } = useTranslation();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [selectedRef, { height }] = useMeasure<HTMLDivElement>();
  const [prevScrollableHeight, setPrevScrollableHeight] = useState(0);

  useEffect(() => {
    if (prevScrollableHeight === height) return;

    const { current } = scrollableRef;
    if (!current) return;

    const top = height - prevScrollableHeight;
    current.scrollBy({ top });
    setPrevScrollableHeight(height);
  }, [prevScrollableHeight, height]);

  const selectedItems = items.filter(e => e.selected);
  const isShowSelected = selectedItems.length > 0;
  const isSearchMode = !!searchTerm;
  const options = isSearchMode
    ? items.filter(e =>
        e.label.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    : items;

  return (
    <Dropdown className={className}>
      <div className={classes.scrollableArea} ref={scrollableRef}>
        <SearchInput
          value={searchTerm}
          placeholder={t('search')}
          onChange={onSearch}
          className={classes.search}
        />

        <div ref={selectedRef}>
          {isShowSelected && !isSearchMode && (
            <>
              <Divider text={`${selectedItems.length} Selected`} />
              <div className={classes.pills}>
                {selectedItems.map(({ value, label }) => (
                  <Pill
                    key={value}
                    label={label}
                    icon={<XIcon />}
                    className={classes.pill}
                    onClick={() => onItemSelect(value)}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        <Divider
          text={`${options.length} ${isSearchMode ? t('results') : listLabel}`}
        />
        <div className={classes.dropdownItems}>
          {options.map(({ value, label, selected }) => (
            <DropdownMenuItem
              key={value}
              value={value}
              label={label}
              icon={selected ? <CheckIcon /> : undefined}
              active={selected}
              onClick={onItemSelect}
            />
          ))}
        </div>
      </div>

      {!isSearchMode && (
        <>
          <SectionDivider />
          <div className={classes.footer}>
            <div className={classes.footerButtons}>
              {onCheckboxClick && (
                <Checkbox
                  size="md"
                  label={checkboxLabel}
                  checked={checboxChecked}
                  onChange={e => onCheckboxClick(e.target.checked)}
                />
              )}
            </div>
            <div className={classes.footerButtons}>
              {onClearAll && (
                <Link label={t('clear-all')} onClick={onClearAll} />
              )}
              {onClose && (
                <Button type="grey" label={t('done')} onClick={onClose} />
              )}
            </div>
          </div>
        </>
      )}
    </Dropdown>
  );
};
