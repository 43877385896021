// Simple cookie consent solution based on
// https://github.com/Mastermindzh/react-cookie-consent
import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';

const isBrowser = typeof window !== 'undefined';

const STATUS_ACCEPT = 'true';
const STATUS_DECLINE = 'false';

type CookieConcentValue = typeof STATUS_ACCEPT | typeof STATUS_DECLINE;

export enum SAME_SITE_OPTIONS {
  STRICT = 'strict',
  LAX = 'lax',
  NONE = 'none',
}

const DEFAULT_COOKIE_CONSENT_NAME = 'CookieConsent';

/**
 * Returns the value of the consent cookie
 * Retrieves the regular value first and if not found the legacy one according
 * to: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
 * @param {*} name optional name of the cookie
 */
function getCookieConsentValue(name: string = DEFAULT_COOKIE_CONSENT_NAME) {
  if (!isBrowser) return undefined;

  let cookieValue = Cookies.get(name);

  // if the cookieValue is undefined check for the legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(getLegacyCookieName(name));
  }

  if (
    cookieValue !== STATUS_ACCEPT &&
    cookieValue !== STATUS_DECLINE &&
    cookieValue !== undefined
  ) {
    throw new Error(`Invalid cookie value: ${cookieValue}`);
  }
  return cookieValue;
}

/**
 * Get the legacy cookie name by the regular cookie name
 * @param {string} name of cookie to get
 */
function getLegacyCookieName(name: string) {
  return `${name}-legacy`;
}

type SetCookieOptions = {
  cookieSecurity?: boolean;
  sameSite?: SAME_SITE_OPTIONS;
  expires?: number;
};

function setCookie(
  cookieName: string,
  cookieValue: CookieConcentValue,
  { cookieSecurity, sameSite, expires }: SetCookieOptions
) {
  const secure =
    cookieSecurity ??
    (window.location ? window.location.protocol === 'https:' : true);

  const cookieOptions = {
    expires,
    sameSite,
    secure,
  };

  // Fallback for older browsers where can not set SameSite=None,
  // SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
  if (sameSite === SAME_SITE_OPTIONS.NONE) {
    Cookies.set(getLegacyCookieName(cookieName), cookieValue, cookieOptions);
  }

  // set the regular cookie
  Cookies.set(cookieName, cookieValue, cookieOptions);
}

type UseCookieConsentOptions = SetCookieOptions & { cookieName: string };

export function useCookieConsent({
  cookieName,
  cookieSecurity,
  sameSite = SAME_SITE_OPTIONS.LAX,
  expires = 365,
}: UseCookieConsentOptions) {
  const [acceptStatus, setAcceptStatus] = useState<
    CookieConcentValue | undefined
  >(() => getCookieConsentValue(cookieName));
  const isVisible = isBrowser && acceptStatus === undefined;
  const isAccepted = acceptStatus === STATUS_ACCEPT;

  const accept = useCallback(() => {
    setCookie(cookieName, STATUS_ACCEPT, { cookieSecurity, sameSite, expires });
    setAcceptStatus(STATUS_ACCEPT);
  }, [cookieName, cookieSecurity, sameSite, expires]);

  const decline = useCallback(() => {
    setCookie(cookieName, STATUS_DECLINE, {
      cookieSecurity,
      sameSite,
      expires,
    });
    setAcceptStatus(STATUS_DECLINE);
  }, [cookieName, cookieSecurity, sameSite, expires]);

  return {
    isVisible,
    isAccepted,
    accept,
    decline,
  };
}
