import { graphql, useStaticQuery } from 'gatsby';
import { JsonDecoder } from 'ts.data.json';
import {
  GatsbyImageDecoder,
  decodeContentfulModel,
  CommonModelDecoder,
  CommonModel,
} from '../library/models/common';
import { enableStrictDecoderErrors } from '../library/env';
import { ContentfulCircleNavQuery } from '../__generated__/graphql-types';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export type CircleNav = CommonModel & {
  title: string;
  img: IGatsbyImageData;
  linkOrDestination: CommonModel | string;
};

export const CircleNavDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      title: JsonDecoder.string,
      link: JsonDecoder.optional(JsonDecoder.string),
      img: GatsbyImageDecoder,
      destination: JsonDecoder.optional(CommonModelDecoder),
    },
    'CircleNav'
  )
).chain<CircleNav>(circle => {
  const { link, destination, ...rest } = circle;
  if (link !== undefined && destination !== undefined) {
    return JsonDecoder.fail(
      `both destination and link defined for circle navigation item: ${circle.contentful_id}`
    );
  }

  const linkOrDestination = link ?? destination;
  if (linkOrDestination === undefined) {
    return JsonDecoder.fail(
      `no destination or link found for circle navigation item: ${circle.contentful_id}`
    );
  }

  return JsonDecoder.constant({
    ...rest,
    linkOrDestination: linkOrDestination,
  });
});

export function useCircleNavData(): CircleNav[] {
  const data: ContentfulCircleNavQuery = useStaticQuery(graphql`
    query ContentfulCircleNav {
      allContentfulCircleNav {
        nodes {
          contentful_id
          contentful_type: __typename
          node_locale
          title
          link
          img {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          destination {
            contentful_id
            contentful_type: __typename
            node_locale
          }
        }
      }
    }
  `);

  const result = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'CircleNav',
    data.allContentfulCircleNav.nodes,
    CircleNavDecoder
  );

  if (!result.isOk()) {
    throw new Error(result.error);
  }

  return result.value;
}
