// Array functions without extending JS Array.

export function intersperse<T>(
  seperator: ((i: number) => T) | T,
  elements: T[]
): T[] {
  const finalElement = elements.length - 1;

  return elements.reduce<T[]>((accum, e, i) => {
    accum.push(e);

    if (finalElement !== i) {
      if (seperator instanceof Function) {
        // Function allows setting key on JSX.Elements.
        accum.push(seperator(i));
      } else {
        accum.push(seperator);
      }
    }

    return accum;
  }, []);
}

export function dateSortDescending<T>(
  elements: T[],
  getDate: (e: T) => Date
): T[] {
  return elements.sort((a, b) => {
    return getDate(b).getTime() - getDate(a).getTime();
  });
}

export function dateSortAscending<T>(
  elements: T[],
  getDate: (e: T) => Date
): T[] {
  return elements.sort((a, b) => {
    return getDate(a).getTime() - getDate(b).getTime();
  });
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export function isExist<T>(input: T | null | undefined): input is T {
  return !!input;
}
