import React from 'react';
import clsx from 'clsx';

interface LinkProps {
  label: string;
  size?: 'xs' | 'sm' | 'md';
  icon?: JSX.Element;
  iconLeft?: boolean;
  bold?: boolean;
  error?: boolean;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
}

const classes = {
  default:
    'outline-none inline-flex items-center gap-1 text-fiber-neutral-900 shadow-[inset_0px_-0.5px_0px_currentcolor]',
  hover: 'hover:shadow-[inset_0px_-2px_0px_currentcolor]',
  active:
    'active:shadow-[inset_0px_-2px_0px_currentcolor] focus:shadow-[inset_0px_-2px_0px_currentcolor]',
  disabled: 'cursor-not-allowed text-fiber-neutral-400',
  error: 'text-fiber-red-700',
  errorDisabled: 'text-fiber-red-100',
  iconLeft: 'flex-row-reverse',
  bold: 'font-medium',
  icon: 'w-4',
  size: {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
  },
};

const Link = ({
  label,
  size = 'md',
  icon,
  iconLeft,
  bold,
  error,
  disabled,
  onClick,
  className,
}: LinkProps) => (
  <a
    role="button"
    tabIndex={0}
    className={clsx(
      classes.default,
      classes.size[size],
      {
        [classes.hover]: !disabled,
        [classes.active]: !disabled,
        [classes.disabled]: disabled,
        [classes.error]: error,
        [classes.errorDisabled]: error && disabled,
        [classes.iconLeft]: iconLeft,
        [classes.bold]: bold,
      },
      className
    )}
    onClick={onClick}
  >
    {label}
    {icon && <span className={classes.icon}>{icon}</span>}
  </a>
);

export default Link;
