import React from 'react';

// Simple grey box used in a few templates - often with title + subtitle header elements of different sizes.
export const GrayHeader = (props: {
  children: string | JSX.Element | JSX.Element[];
}): JSX.Element => {
  return (
    <div className="py-12 col-span-full bg-gray-light rounded-2xl lg:breakout">
      <div className="container px-8 mx-auto flex-col-stack-2">
        {props.children}
      </div>
    </div>
  );
};
