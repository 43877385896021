import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, CSSProperties } from 'react';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout, { Grid } from '../layout/layout';
import { useChangeLanguage } from '../../library/i18n';
import { AccentColor } from '../../components/links';
import { Carousel } from '../../components/carousel';
import { combinePaths } from '../../library/url-parsing';
import { WrappedRichText } from '../../components/rich-text';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { decorativeImageAlt } from '../../components/attributes';
import { TeamPageModel, TeamMember } from '../../library/models/team-page';
import { globalNetworkUrl, developmentGovernanceUrl } from '../../library/urls';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

export type TeamPageContext = {
  pageModel: TeamPageModel;
  pagePath: string;
};

export interface TeamPageProps {
  readonly pageContext: TeamPageContext;
}

const TeamMemberBlock = (props: {
  content: TeamMember;
  className?: string;
  style: CSSProperties;
}): JSX.Element => {
  let subtitle = <></>;
  if (props.content.subtitle !== undefined) {
    subtitle = <div>{props.content.subtitle}</div>;
  }

  let linkedIn = <></>;
  if (props.content.linkedInUrl !== undefined) {
    linkedIn = (
      <div className="absolute top-3/4 w-full">
        <a
          className="bg-linkedin-color text-white rounded-2xl py-3 px-16 mx-auto w-3/4 flex flex-col items-center font-bold "
          target="_blank"
          rel="noopener noreferrer"
          href={props.content.linkedInUrl}
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    );
  }

  return (
    <div
      className={`${
        props.className ?? ''
      }  col-span-3 lg:col-span-3 flex-col-stack-4`.trim()}
      style={props.style}
      data-testid="team-member-block"
    >
      <div className="relative">
        <GatsbyImage image={props.content.photo} alt={decorativeImageAlt} />
        {linkedIn}
      </div>
      <div className="flex-col-stack-1 text-center">
        <div className="font-bold">
          {props.content.firstName} {props.content.lastName}
        </div>
        <div>
          <div data-testid="title">{props.content.title}</div>
          {subtitle}
        </div>
      </div>
    </div>
  );
};

const DesktopMembersGrid = (props: {
  content: TeamMember[];
  title: string;
  info: RenderRichTextData<ContentfulRichTextGatsbyReference> | undefined;
  infoUrl?: string;
  bgColor: string;
}): JSX.Element => {
  return (
    <Grid className={`py-14 px-20`} style={{ backgroundColor: props.bgColor }}>
      <div className="lg:col-span-3 md:col-span-6 font-semibold text-xl">
        {props.title}
      </div>
      {props.info && (
        <div className="lg:col-span-9 md:col-span-6">
          <WrappedRichText
            richText={props.info}
            additionalProps={{
              hyperlink: { accentColor: AccentColor.Blue },
            }}
          />
        </div>
      )}
      {props.content.map((m, i) => (
        <TeamMemberBlock key={i} content={m} style={{}} />
      ))}
    </Grid>
  );
};

const MobileMembersCarousel = (props: {
  content: TeamMember[];
  title: string;
  info: RenderRichTextData<ContentfulRichTextGatsbyReference> | undefined;
  infoUrl: string;
  bgColor: string;
}): JSX.Element => {
  return (
    <div className={`${props.bgColor} px-6 pt-10 pb-4 flex-col-stack-4`}>
      <div className="col-span-3 font-semibold text-xl text-center">
        {props.title}
      </div>
      {props.info && (
        <WrappedRichText
          richText={props.info}
          className="col-span-9"
          additionalProps={{
            hyperlink: { accentColor: AccentColor.Blue },
          }}
        />
      )}
      <Carousel content={props.content} element={TeamMemberBlock} />
    </div>
  );
};

const TeamPage: FC<TeamPageProps> = ({
  pageContext: { pageModel, pagePath },
}): JSX.Element => {
  const { t } = useTranslation();
  useChangeLanguage(pageModel.node_locale);

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    lang: pageModel.node_locale,
    title: t('seo-global-partners-title'),
    description: t('seo-global-partners-description'),
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
  };

  return (
    <Layout metaTagProps={metaTagProps}>
      <div className="col-span-full flex-row-gutter-6 grow-children-equally">
        <GatsbyImage
          alt={decorativeImageAlt}
          image={pageModel.heroSection.photo}
          className="hidden lg:block rounded-2xl"
        />
        <div className="flex-col-stack-4">
          <div className="flex-col-stack-2">
            <h1
              className="font-bold text-2xl uppercase"
              data-testid="page-header"
            >
              {pageModel.heroSection.title}
            </h1>
            <div className="font-bold text-4xl">
              {pageModel.heroSection.description}
            </div>
          </div>
          <WrappedRichText
            richText={pageModel.heroSection.content}
            additionalProps={{
              hyperlink: { accentColor: AccentColor.Blue },
            }}
          />
        </div>
      </div>
      <div className="hidden md:flex col-span-full flex-col-stack-4">
        <DesktopMembersGrid
          content={pageModel.peopleSections[0]?.people ?? []}
          title={pageModel.peopleSections[0]?.title ?? ''}
          info={pageModel.peopleSections[0]?.description}
          infoUrl={combinePaths(i18n.language, globalNetworkUrl())}
          bgColor={pageModel.peopleSections[0]?.sectionColor ?? ''}
          data-testid="leadership-members-list"
        />
        <DesktopMembersGrid
          content={pageModel.peopleSections[1]?.people ?? []}
          title={pageModel.peopleSections[1]?.title ?? ''}
          info={pageModel.peopleSections[1]?.description}
          infoUrl={`/${i18n.language}/${developmentGovernanceUrl}`}
          bgColor={pageModel.peopleSections[1]?.sectionColor ?? ''}
          data-testid="board-members-list"
        />
      </div>
      <div className="flex md:hidden col-span-full flex-col-stack-4">
        <MobileMembersCarousel
          content={pageModel.peopleSections[0]?.people ?? []}
          title={pageModel.peopleSections[0]?.title ?? ''}
          info={pageModel.peopleSections[0]?.description}
          infoUrl={combinePaths(i18n.language, globalNetworkUrl())}
          bgColor={pageModel.peopleSections[0]?.sectionColor ?? ''}
        />
        <MobileMembersCarousel
          content={pageModel.peopleSections[1]?.people ?? []}
          title={pageModel.peopleSections[1]?.title ?? ''}
          info={pageModel.peopleSections[1]?.description}
          infoUrl={`/${i18n.language}/${developmentGovernanceUrl}`}
          bgColor={pageModel.peopleSections[1]?.sectionColor ?? ''}
        />
      </div>
    </Layout>
  );
};

export default TeamPage;
