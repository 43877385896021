import React from 'react';

const VimeoVideo = (props: {
  src: string;
  title: string;
  iframeTitle: string;
}): JSX.Element => (
  <>
    <div className="col-span-full flex flex-col justify-center items-center space-y-10">
      <span className="text-3xl font-bold text-center">{props.title}</span>
      <div className="w-4/5 max-w-screen-sm h-full m-auto ">
        <div className="embed-video">
          <iframe
            height="2"
            width="2"
            src={props.src}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
            title={props.iframeTitle}
          ></iframe>
        </div>
      </div>
    </div>
  </>
);

export default VimeoVideo;
