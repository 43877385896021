import React from 'react';
import clsx from 'clsx';

const classes = {
  root: {
    default:
      'flex flex-row items-center px-3 py-3.5 rounded-md border border-fiber-neutral-300 bg-white',
    hover: 'hover:border-fiber-grey-900',
    active:
      'active:border-fiber-grey-900 active:shadow-[inset_0px_-4px_var(--color-black)]',
    focused: 'border-fiber-grey-900 shadow-[inset_0px_-4px_var(--color-black)]',
    disabled:
      'cursor-not-allowed text-fiber-neutral-400 bg-surface-variant-light border-surface-variant-light-outline',
    error: 'border-fiber-red-700',
  },
};

export interface InputWrapperProps {
  onClick?: () => void;
  focused?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  children: React.ReactNode;
}

const InputWrapper = ({
  onClick,
  focused,
  disabled,
  error,
  className,
  children,
}: InputWrapperProps) => (
  <div
    className={clsx(
      classes.root.default,
      {
        [classes.root.hover]: !disabled,
        [classes.root.active]: !disabled,
        [classes.root.focused]: focused && !disabled,
        [classes.root.disabled]: disabled,
        [classes.root.error]: error,
      },
      className
    )}
    role={onClick ? 'button' : undefined}
    onClick={disabled ? undefined : onClick}
  >
    {children}
  </div>
);

export default InputWrapper;
