import React from 'react';

const addToPlaceholder = (
  toAdd: string,
  el: HTMLInputElement
): Promise<void> => {
  el.setAttribute('placeholder', el.getAttribute('placeholder') + toAdd);
  return new Promise(resolve => setTimeout(resolve, 100));
};

const clearPlaceholder = (el: HTMLInputElement) => {
  el.setAttribute('placeholder', '');
};

const printPhrase = (phrase: string, el: HTMLInputElement): Promise<void> => {
  return new Promise(resolve => {
    clearPlaceholder(el);
    const letters = phrase.split('');
    letters.reduce(
      (promise, letter, index): Promise<void> =>
        promise.then(() => {
          if (index === letters.length - 1) {
            setTimeout(resolve, 2000);
          }
          return addToPlaceholder(letter, el);
        }),
      Promise.resolve()
    );
  });
};

const animatePlaceholder = async (
  searchActivePhrases: string[],
  el: React.RefObject<HTMLInputElement>
) => {
  const target = el?.current;
  if (!target) return;

  await searchActivePhrases.reduce(async (promise, _, i): Promise<void> => {
    await promise;

    const active = searchActivePhrases[i];
    if (!active) return;
    await printPhrase(active, target);
  }, Promise.resolve());
};

export default animatePlaceholder;
