import { ContentfulPressPostQuery } from '../../__generated__/graphql-types';
import { JsonDecoder, Result, FromDecoder } from 'ts.data.json';
import {
  decodeContentfulModel,
  CommonModelDecoder,
  GatsbyImageDecoder,
  RichTextDecoder,
  ValidDateDecoder,
  arrayOrUndefined,
} from './common';
import { AuthorDecoder } from './author';
import { enableStrictDecoderErrors } from '../env';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { BlogPost, BlogDecoder } from './blog-post';
import { CallToAction, CallToActionDecoder } from './call-to-action';

// Decoder for information needed only to display news items in list and carousel views
export const NewsItemPressDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      slug: JsonDecoder.string,
      title: JsonDecoder.string,
      author: AuthorDecoder,
      publishedDate: ValidDateDecoder,
      excerpt: JsonDecoder.string,
      headerImage: JsonDecoder.optional(GatsbyImageDecoder),
    },
    'NewsItem PressPost'
  )
);

export type NewsItemPressPost = FromDecoder<typeof NewsItemPressDecoder>;

export type PressPost = NewsItemPressPost & {
  seoTitle: string;
  seoDescription: string;
  subtitle: string;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  relatedPosts: (BlogPost | PressPost)[];
  tags: string[];
  callToAction: CallToAction | undefined;
};

// Full blog decoder for page generation.
export const PressDecoder: JsonDecoder.Decoder<PressPost> = JsonDecoder.combine(
  NewsItemPressDecoder,
  JsonDecoder.object(
    {
      seoTitle: JsonDecoder.string,
      seoDescription: JsonDecoder.objectStrict(
        { seoDescription: JsonDecoder.string },
        'seoDescription'
      ).map(s => s.seoDescription),
      subtitle: JsonDecoder.string,
      body: RichTextDecoder,

      // Recursive decoder for related news items.
      relatedPosts: JsonDecoder.lazy(() =>
        arrayOrUndefined(
          'RelatedPosts',
          JsonDecoder.oneOf([BlogDecoder, PressDecoder], 'Blog/Press')
        )
      ),
      tags: arrayOrUndefined(
        'Tag',
        JsonDecoder.objectStrict({ tag: JsonDecoder.string }, 'Tag').map(
          t => t.tag
        )
      ),
      callToAction: JsonDecoder.optional(CallToActionDecoder),
    },
    `PressPost`
  )
);

export function decodePressPostQuery(
  data: ContentfulPressPostQuery
): Result<PressPost[]> {
  return decodeContentfulModel(
    enableStrictDecoderErrors(),
    'PressPost',
    data.allContentfulPressPost.nodes,
    PressDecoder
  );
}
