import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, UsePaginationProps } from 'react-instantsearch';
import { Pagination as PaginationView } from '../pagination';
import { HITS_PER_PAGE, ONE_PAGE_HITS } from './hits-per-page';
import { useScrollToFilters } from './scroll';

const classes = {
  header: 'text-center text-fiber-neutral-500 mt-10',
  pagination: 'mt-10',
};

export const Pagination = (props: UsePaginationProps) => {
  const { t } = useTranslation();
  const { currentRefinement, refine, nbHits, nbPages } = usePagination(props);
  const scrollToFilters = useScrollToFilters();

  useEffect(() => {
    if (nbPages === 0) return;
    refine(currentRefinement);
  }, [refine, currentRefinement, nbPages]);

  const onClick = useCallback(
    (page: number) => {
      refine(page - 1);
      scrollToFilters();
    },
    [refine, scrollToFilters]
  );

  if (nbHits <= ONE_PAGE_HITS) {
    return null;
  }

  const currentPage = currentRefinement + 1;
  const totalPages = nbPages;
  const start = HITS_PER_PAGE * (currentPage - 1) + 1;
  const end = Math.min(HITS_PER_PAGE * currentPage, nbHits);

  return (
    <>
      <div className={classes.header}>
        {t('search-pagination-results', { start, end, all: nbHits })}
      </div>
      <PaginationView
        {...{ currentPage, totalPages, onClick }}
        className={classes.pagination}
      />
    </>
  );
};
