import React, { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  open?: boolean;
  title: string;
  children: ReactNode;
}
const Collapsible: React.FC<IProps> = ({ open, children, title }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <div className="card">
        <div>
          <div className="p-3 border-bottom flex justify-center items-center space-x-1">
            <h6 className="font-weight-bold">{title}</h6>
            <button
              type="button"
              data-testid="collapse_toggle"
              className="btn"
              onClick={handleFilterOpening}
            >
              {!isOpen ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </button>
          </div>
        </div>

        <div className="border-bottom">
          <div>
            {isOpen && (
              <div data-testid="collapse_content" className="p-3">
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
