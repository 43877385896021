import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import { navigate } from 'gatsby';

export enum BlabButtonStyle {
  LargeFilledButton = 1,
  LargeOutlinedButton = 2,
  SquareLargeFilledButton = 3,
  MediumFilledButton = 4,
}

type BlabButtonProps = {
  href?: string;
  blabType: BlabButtonStyle;
};

function fullStyles(
  b: BlabButtonStyle,
  ...others: Array<string | undefined | null>
): string {
  let custom = '';
  switch (b) {
    case BlabButtonStyle.LargeFilledButton:
      custom = 'lg:max-w-max rounded-3xl bg-yellow';
      break;
    case BlabButtonStyle.LargeOutlinedButton:
      custom = 'lg:max-w-max rounded-3xl';
      break;
    case BlabButtonStyle.SquareLargeFilledButton:
      custom = 'bg-yellow';
      break;
    case BlabButtonStyle.MediumFilledButton:
      custom = 'md:rounded-3xl bg-yellow';
      break;
  }

  return [custom, ...others]
    .filter((x): x is string => x !== null && x != undefined)
    .join(' ');
}

export const Button = (
  props: BlabButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
): JSX.Element => {
  // Filter all non-button properties.
  const { href, blabType, ...fitleredProps } = props;

  const basicStyles =
    'border-4 border-yellow py-3 px-16 flex flex-col items-center font-bold';
  const fullProps: ButtonHTMLAttributes<HTMLButtonElement> = {
    ...fitleredProps,
    className: fullStyles(blabType, fitleredProps.className, basicStyles),
    type: fitleredProps.type ?? 'button',
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (fitleredProps.onClick) {
        fitleredProps.onClick(e);
        return;
      }

      if (href) {
        if (href.startsWith('mailto:')) {
          window.location.href = href;
        } else {
          navigate(href);
        }

        return;
      }
    },
  };

  return <button {...fullProps}>{fullProps.children}</button>;
};

type SlideButtonProps = {
  sections: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    title: string;
    selected: boolean;
  }[];
};

export const SlideButton = (
  props: SlideButtonProps & ButtonHTMLAttributes<HTMLElement>
): JSX.Element => {
  const { sections, ...fitleredProps } = props;
  const fullProps: HTMLAttributes<HTMLDivElement> = {
    ...fitleredProps,
    className:
      `flex flex-row grow-children-equally border-4 border-black rounded-3xl ${
        fitleredProps.className || ''
      }`.trim(),
  };

  return (
    <div {...fullProps}>
      {sections.map((s, i) => {
        return (
          <button
            key={i}
            onClick={s.onClick}
            className={`rounded-3xl py-3 px-4 md:px-16 -m-1 font-bold ${
              s.selected ? 'bg-black text-white' : ''
            }`.trim()}
          >
            {s.title}
          </button>
        );
      })}
    </div>
  );
};
