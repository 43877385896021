import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useStats } from 'react-instantsearch';
import { useCurrentPageHits } from './hits-per-page';
import { ScrollElement, SCROLL_ELEMENT_NAME } from './scroll';

const classes = {
  root: 'font-sans font-medium text-xl text-black',
};

interface StatsProps {
  className?: string;
}

export const Stats = ({ className }: StatsProps) => {
  const { t } = useTranslation();
  const { nbHits } = useStats();
  useCurrentPageHits(nbHits);

  return (
    <ScrollElement
      name={SCROLL_ELEMENT_NAME}
      className={clsx(classes.root, className)}
    >
      {t(nbHits > 1 ? 'search-result-count_other' : 'search-result-count_one', {
        count: nbHits,
      })}
    </ScrollElement>
  );
};
