import React, { FC } from 'react';
import { useChangeLanguage } from '../../library/i18n';
import { useTranslation } from 'react-i18next';
import { BlogPost } from '../../library/models/blog-post';
import Layout from '../layout/layout';
import { WrappedRichText } from '../../components/rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../components/attributes';
import { DateWithLongFormat } from '../../components/date-formats';
import { Link } from 'gatsby';
import { newsItemUrl } from '../../library/urls';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { CallToAction } from '../../components/call-to-action';

export type BlogPostContext = {
  pageModel: BlogPost;
  pagePath: string;
};

interface BlogPostPageProps {
  readonly pageContext: BlogPostContext;
}

const BlogPostPage: FC<BlogPostPageProps> = ({
  pageContext: { pageModel, pagePath },
}): JSX.Element => {
  const { t } = useTranslation();
  useChangeLanguage(pageModel.node_locale);

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    lang: pageModel.node_locale,
    title: pageModel.seoTitle,
    description: pageModel.seoDescription,
    siteUrl: siteMetadata.siteUrl,
    pageContentfulId: pageModel.contentful_id,
    pathWithoutLanguage: pagePath,
    ogImage: pageModel.headerImage,
  };

  let hero = <></>;
  if (pageModel.headerImage) {
    hero = (
      <GatsbyImage
        className="col-span-full rounded-3xl"
        image={pageModel.headerImage}
        alt={decorativeImageAlt}
      />
    );
  }

  let relatedPosts = <></>;
  if (pageModel.relatedPosts.length !== 0) {
    relatedPosts = (
      <div className="col-span-full flex-col-stack-2">
        <div className="text-bold">{t('related-articles')}</div>
        <hr className="border-2" />
        <div className="flex flex-row grow-children-equally">
          {pageModel.relatedPosts.map((p, index) => {
            return (
              <Link
                key={index}
                to={newsItemUrl(p)}
                className="flex-row-gutter-4"
              >
                <div className="self-center">
                  <div className="w-14 h-14 bg-black rounded-xl" />
                </div>
                <div className="flex-col-stack-2">
                  <div className="flex-grow">{p.excerpt}</div>
                  <div className="text-sm">
                    <DateWithLongFormat d={new Date(p.publishedDate)} />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }

  let tags = <></>;
  if (pageModel.tags.length !== 0) {
    tags = <div className="col-span-full">{pageModel.tags.join(', ')}</div>;
  }

  const callToAction = pageModel.callToAction ? (
    <CallToAction className="col-span-full" content={pageModel.callToAction} />
  ) : (
    <></>
  );

  return (
    <Layout metaTagProps={metaTagProps}>
      <div className="flex-col-stack-10 col-span-full lg:col-start-4 lg:col-end-10 m-auto text-center">
        <div className="flex-col-stack-6 font-bold">
          <h1 className="text-4xl">{pageModel.title}</h1>
          <div className="text-xl">{pageModel.subtitle}</div>
        </div>
        <div className="flex flex-row grow-children-equally">
          <div className="text-left">
            {t('by-author', { author: pageModel.author.name })}
          </div>
          <div className="text-right">
            <DateWithLongFormat d={new Date(pageModel.publishedDate)} />
          </div>
        </div>
      </div>
      {hero}
      <div className="flex-col-stack-5 col-span-full">
        <WrappedRichText
          className="flex-col-stack-2"
          richText={pageModel.body}
        />
        {tags}
        {callToAction}
        {relatedPosts}
      </div>
    </Layout>
  );
};

export default BlogPostPage;
