module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"B Lab Global Site","short_name":"B Lab Global Site","lang":"en-us","start_url":"/","display":"standalone","theme_color":"#4fc6e0","background_color":"#ffffff","icon":"static/images/favicons/32.png","icons":[{"src":"static/images/favicons/192.png","sizes":"192x192","type":"image/png"},{"src":"static/images/favicons/512.png","sizes":"512x512","type":"image/png"},{"src":"static/images/favicons/maskable/maskable.png","sizes":"197x197","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e0f97515b8e5defb6f1fd6c4c7aaa683"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-KQJCRZ4","cookieName":"gatsby-gdpr-google-tagmanager","defaultDataLayer":{"platform":"gatsby"},"dataLayerName":"dataLayer"},"googleTag":{"trackingIds":["GTM-KQJCRZ4"],"cookieName":"gatsby-gpdr-google-tag"},"facebookPixel":{"cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"cookieName":"gatsby-gdpr-hotjar"},"chatwoot":{"cookieName":"gatsby-gdpr-chatwoot"},"linkedin":{"cookieName":"gatsby-gdpr-linkedin"},"hubspot":{"cookieName":"gatsby-gpdr-hubspot"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
