import { graphql, useStaticQuery } from 'gatsby';
import { FromDecoder, JsonDecoder } from 'ts.data.json';
import { CertifiedCompaniesDataQuery } from '../__generated__/graphql-types';

export const CertifiedCompaniesTotalsDecoder = JsonDecoder.object(
  {
    workers: JsonDecoder.number,
    industries: JsonDecoder.number,
    companies: JsonDecoder.number,
    countries: JsonDecoder.number,
  },
  'CertifiedCompaniesTotals'
);

export type CertifiedCompaniesTotals = FromDecoder<
  typeof CertifiedCompaniesTotalsDecoder
>;

export function useCertifiedCompaniesTotals(): CertifiedCompaniesTotals {
  const data: CertifiedCompaniesDataQuery = useStaticQuery(graphql`
    query CertifiedCompaniesData {
      allCertifiedCompaniesData {
        nodes {
          companies
          countries
          industries
          workers
        }
      }
    }
  `);

  const metrics = data.allCertifiedCompaniesData.nodes[0];
  const totals: CertifiedCompaniesTotals = {
    workers: metrics?.workers ?? 0,
    companies: metrics?.companies ?? 0,
    industries: metrics?.industries ?? 0,
    countries: metrics?.countries ?? 0,
  };
  return totals;
}
