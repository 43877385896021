import { useStaticQuery, graphql } from 'gatsby';
import {
  decodeContentfulModel,
  combineResultErrors,
  ContentfulNewsItemTypes,
} from '../library/models/common';
import { enableStrictDecoderErrors } from '../library/env';
import { ContentfulLatestNewsQuery } from '../__generated__/graphql-types';
import {
  NewsItemBlogDecoder,
  NewsItemBlogPost,
} from '../library/models/blog-post';
import {
  NewsItemPressDecoder,
  NewsItemPressPost,
} from '../library/models/press-post';
import { MediaPostDecoder, MediaPost } from '../library/models/media-post';

export type NewsItem = NewsItemBlogPost | NewsItemPressPost | MediaPost;

export function isBlogPost(p: unknown): p is NewsItemBlogPost {
  return (
    (p as NewsItemBlogPost).contentful_type ===
    ContentfulNewsItemTypes.ContentfulBlogPost
  );
}

export function isPressPost(p: unknown): p is NewsItemPressPost {
  return (
    (p as NewsItemPressPost).contentful_type ===
    ContentfulNewsItemTypes.ContentfulPressPost
  );
}

export function isMediaPost(p: unknown): p is MediaPost {
  return (
    (p as MediaPost).contentful_type ===
    ContentfulNewsItemTypes.ContentfulMediaPost
  );
}

export function useNewsItems(): NewsItem[] {
  const data: ContentfulLatestNewsQuery = useStaticQuery(graphql`
    query ContentfulLatestNews {
      allContentfulBlogPost {
        nodes {
          contentful_id
          contentful_type: __typename
          node_locale
          slug
          title
          publishedDate
          author {
            name
          }
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          excerpt
        }
      }
      allContentfulPressPost {
        nodes {
          contentful_id
          contentful_type: __typename
          node_locale
          slug
          title
          publishedDate
          author {
            name
          }
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          excerpt
        }
      }
      allContentfulMediaPost {
        nodes {
          contentful_id
          contentful_type: __typename
          node_locale
          title
          publishedDate
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          excerpt
          link
        }
      }
    }
  `);

  const blogResults = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'BlogPost',
    data.allContentfulBlogPost.nodes,
    NewsItemBlogDecoder
  );

  const pressResults = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'PressPost',
    data.allContentfulPressPost.nodes,
    NewsItemPressDecoder
  );

  const mediaResults = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'MediaPost',
    data.allContentfulMediaPost.nodes,
    MediaPostDecoder
  );

  if (!blogResults.isOk() || !pressResults.isOk() || !mediaResults.isOk()) {
    const errors = combineResultErrors(blogResults, pressResults, mediaResults);
    throw new Error(errors.join(', '));
  }

  return [...blogResults.value, ...pressResults.value, ...mediaResults.value];
}
