import { useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from '../translations/en-us.json';

export type Language = string;
export const EnUs: Language = 'en-us';
export const EsEs: Language = 'es-es';
export const FrFr: Language = 'fr-fr';
export const ItIt: Language = 'it-it';
export const PtBr: Language = 'pt-br';

export const resources = {
  /** note i18next is expecting actual locale codes here, and not the impact app lowercase variants */
  'en-us': { translation: englishTranslations },
};

export type MaybeLanguage = string | null;
export type TranslationsFile = typeof import('../translations/en-us.json');

const changeLanguage = async (lang: Language): Promise<void> => {
  document.cookie = `i18next=en-us; Path=/; SameSite=Strict; Secure;`;
  await i18n.changeLanguage(lang);
};

export const useChangeLanguage = (language: string) => {
  useEffect(() => {
    if (i18n.language !== language) {
      changeLanguage(language);
    }
  }, [language]);
};

/**
 * all supported languages of the global website
 * @returns array of language codes
 */
export function allSupportedLanguages(): Array<Language> {
  return [EnUs];
}

export default i18n.use(initReactI18next).init({
  supportedLngs: allSupportedLanguages(),
  resources: resources,
  fallbackLng: EnUs,
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,

  // Hack to force missing translation keys to cause errors
  saveMissing: true,
  missingKeyHandler: function (_lngs, _ns, key) {
    throw new Error(`missing translations key '${key}'`);
  },
});

/**
 * attempt to infer the language from a given site path
 * @param path the path of a specific page (e.g. /some/path/on/the/site)
 * @returns the language inferred from the path. falls back to EnUs
 */
export function inferLanguageFromPagePath(path: string): MaybeLanguage {
  const all = allSupportedLanguages();
  const parts = path.split('/');
  parts.forEach(p => {
    if (all.includes(p)) {
      return p;
    }
  });

  return null;
}

/**
 * replace language codes in a website path
 * @param path the path to search
 * @param find the language code to search for
 * @param replace the language code to replace with
 * @returns new website path
 */
export function replaceLanguageInPath(
  path: string,
  find: Language,
  replace: Language
): string {
  // opting to find and replace using a trailing slash, so that we don't
  // accidentally replace something like "taken-us-places" with "takes-es-places"
  return path.replace(`/${find}`, `/${replace}`);
}
