import React, { FC } from 'react';
import { useChangeLanguage } from '../../library/i18n';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import Layout from '../layout/layout';
import { WrappedRichText } from '../../components/rich-text';
import {
  LegalRequirement,
  LegalRequirementWithCorporate,
  LegalRequirementWithProvinceAndCorporate,
  isLegalRequirementWithCorporate,
  isLegalRequirementWithProvinceAndCorporate,
  corporateStructureTranslation,
} from '../../library/models/legal-requirements';
import { GrayHeader } from '../../components/gray-header';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

export type LegalRequirementPageContext = {
  pageModel:
    | LegalRequirement
    | LegalRequirementWithCorporate
    | LegalRequirementWithProvinceAndCorporate;
  pagePath: string;
};

interface LegalRequirementProps {
  readonly pageContext: LegalRequirementPageContext;
}

function requirementDescription(
  t: TFunction,
  m:
    | LegalRequirement
    | LegalRequirementWithCorporate
    | LegalRequirementWithProvinceAndCorporate
): string | null {
  if (isLegalRequirementWithCorporate(m)) {
    return `${corporateStructureTranslation(t, m.corporateStructure)}`;
  }

  if (isLegalRequirementWithProvinceAndCorporate(m)) {
    return `${m.province} - ${corporateStructureTranslation(
      t,
      m.corporateStructure
    )}`;
  }

  return null;
}

const LegalRequirementPage: FC<LegalRequirementProps> = ({
  pageContext: { pageModel, pagePath },
}): JSX.Element => {
  const { t } = useTranslation();
  useChangeLanguage(pageModel.node_locale);

  const siteMetadata = useSiteMetadata();
  const description = requirementDescription(t, pageModel);
  const metaTagProps = {
    title: t('seo-legal-requirement-title'),
    description: t('seo-legal-requirement-description', {
      seoDescription: `${pageModel.country} ${description || ''}`.trim(),
    }),
    lang: pageModel.node_locale,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
  };
  return (
    <Layout metaTagProps={metaTagProps}>
      <GrayHeader>
        <h1>{pageModel.country}</h1>
        {description === null ? (
          <></>
        ) : (
          <div className="font-bold text-2xl">
            {description} {t('legal-requirement')}
          </div>
        )}
      </GrayHeader>
      <WrappedRichText
        className="flex-col-stack-2 col-span-full"
        richText={pageModel.requirement}
      />
    </Layout>
  );
};

export default LegalRequirementPage;
