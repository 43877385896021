import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, useChangeLanguage } from '../library/i18n';
import Layout from './layout/layout';
import { Header, CompanyInfo } from '../templates/company';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import { PendingCompany } from '../library/models/pending-company';
import { Standard } from '../library/models/standards';

export type PendingCompanyContext = {
  language: Language;
  pagePath: string;
  company: PendingCompany;
  standards: Standard;
};

interface PendingCompanyProps {
  readonly pageContext: PendingCompanyContext;
}

const PendingCompanyPage: FC<PendingCompanyProps> = ({
  pageContext: { company, language, pagePath },
}): JSX.Element => {
  const { t } = useTranslation();
  useChangeLanguage(language);

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    title: t('seo-pending-company-title', {
      val: company.name,
    }),
    description: t('seo-pending-company-description', {
      name: company.name,
      description: company.description,
    }).substring(0, 320),
    lang: language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
    ogImage: company.lifestyleLogo,
  };

  return (
    <Layout metaTagProps={metaTagProps}>
      {Header(company)}
      {CompanyInfo(company)}
    </Layout>
  );
};

export default PendingCompanyPage;
