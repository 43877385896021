import React from 'react';
import { useTranslation } from 'react-i18next';
import { companyProfileUrl } from '../../library/urls';
import { combinePaths } from '../../library/url-parsing';
import { DateWithMonthFormat } from '../date-formats';
import CompanyCard from './company-card';
import { Company } from '../../services/search/models';

interface CompanyRecordProps {
  hit: Company;
}

const CompanyRecord = ({ hit }: CompanyRecordProps): JSX.Element => {
  const { i18n } = useTranslation();

  const { slug, name, description, companyLogo } = hit;
  if (!slug) {
    throw new Error(`missing slug ${name}`);
  }
  if (!name) {
    throw new Error(`missing name ${slug}`);
  }

  const logo = companyLogo || undefined;

  if (!hit.isCertified) {
    return (
      <CompanyCard
        {...{
          name,
          description,
          initialCertificationDate: <></>,
          logo,
          link: combinePaths(i18n.language, companyProfileUrl(slug)),
        }}
      />
    );
  }

  const { initialCertificationDateTimestamp } = hit;
  const rawDate = new Date(initialCertificationDateTimestamp);
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#return_value
  if (rawDate.toString() === 'Invalid Date') {
    throw new Error(`invalid certification date found with ${name}`);
  }
  const initialCertificationDate = <DateWithMonthFormat d={rawDate} />;

  return (
    <CompanyCard
      {...{
        name,
        description,
        initialCertificationDate,
        logo,
        link: combinePaths(i18n.language, companyProfileUrl(slug)),
      }}
    />
  );
};

export default CompanyRecord;
