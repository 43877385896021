import React from 'react';
import clsx from 'clsx';

interface DropdownProps {
  className?: string;
  children?: React.ReactNode;
}

const classes = {
  root: {
    default:
      'flex flex-col rounded-lg py-1 bg-white overflow-hidden shadow-[0_4px_4px_rgba(16,24,40,0.05),0_12px_16px_rgba(16,24,40,0.1)]',
  },
};

function Dropdown({ className, children }: DropdownProps) {
  return (
    <div className={clsx(classes.root.default, className)}>{children}</div>
  );
}

export default Dropdown;
