import { graphql, useStaticQuery } from 'gatsby';
import { FromDecoder, JsonDecoder } from 'ts.data.json';
import { enableStrictDecoderErrors } from '../library/env';
import {
  CommonModelDecoder,
  decodeContentfulModel,
  formatDecoderError,
  GatsbyImageDecoder,
  ValidDateDecoder,
} from '../library/models/common';
import { ContentfulEventGalleryQuery } from '../__generated__/graphql-types';

const EventGalleryDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      companyName: JsonDecoder.string,
      galleryImage: GatsbyImageDecoder,
      destinationLink: JsonDecoder.string,
      updatedAt: ValidDateDecoder,
    },
    'EventGallery'
  )
);

export type GalleriesItem = FromDecoder<typeof EventGalleryDecoder>;

export function useGalleryItems(): GalleriesItem[] {
  const data: ContentfulEventGalleryQuery = useStaticQuery(graphql`
    query ContentfulLatestEventGallery {
      allContentfulEventGallery(sort: { updatedAt: DESC }) {
        nodes {
          contentful_id
          contentful_type: __typename
          node_locale
          companyName
          destinationLink
          galleryImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          updatedAt
        }
      }
    }
  `);

  const result = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'EventGallery',
    data.allContentfulEventGallery.nodes,
    EventGalleryDecoder
  );

  if (!result.isOk()) {
    throw new Error(formatDecoderError(result.error));
  }

  return [...result.value];
}
