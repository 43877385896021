import React, { FC } from 'react';
import { useChangeLanguage } from '../../library/i18n';
import i18n, { t } from 'i18next';
import { EventPage as EventPageModel } from '../../library/models/event-page';
import Layout from '../layout/layout';
import { WrappedRichText } from '../../components/rich-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../components/attributes';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import EventsPage from '../../components/pages/event/event';
import { useGalleryItems } from '../../hooks/use-event-gallery';
import { dateSortAscending } from '../../library/array-utils';
import GalleryPage from '../../components/pages/event/event-gallery';
import { Link } from 'react-scroll';

export type EventPageContext = {
  pageModel: EventPageModel;
  pagePath: string;
};

export interface EventPageProps {
  readonly pageContext: EventPageContext;
}

const EventPage: FC<EventPageProps> = ({
  pageContext: { pageModel, pagePath },
}): JSX.Element => {
  useChangeLanguage(pageModel.node_locale);

  const siteMetadata = useSiteMetadata();

  let hero = <></>;
  if (pageModel.headerImage) {
    hero = (
      <div className="col-span-full flex flex-col lg:breakout">
        <GatsbyImage
          className="w-full rounded-3xl"
          image={pageModel.headerImage}
          alt={decorativeImageAlt}
        />
      </div>
    );
  }

  const allEventItems = dateSortAscending(
    pageModel.posts.filter(
      (p: { node_locale: string }) => p.node_locale == i18n.language
    ),
    post => new Date(post.eventDate)
  );

  const allGalleryItems = useGalleryItems().filter(
    g => g.node_locale == i18n.language
  );

  return (
    <Layout
      metaTagProps={{
        lang: pageModel.node_locale,
        title: pageModel.seoTitle,
        description: pageModel.seoDescription,
        siteUrl: siteMetadata.siteUrl,
        pathWithoutLanguage: pagePath,
        ogImage: pageModel.headerImage,
      }}
    >
      <div className="col-span-full lg:col-span-8 flex flex-col-stack-1">
        <h1 className="font-bold text-xl uppercase">{pageModel.header}</h1>
        <div className="font-bold text-4xl">{pageModel.subHeader}</div>
      </div>
      {hero}
      <WrappedRichText
        className="col-span-full flex-col-stack-4"
        richText={pageModel.body}
      />
      {/* {callToAction} */}
      <div className="col-span-full lg:col-start-4 lg:col-end-10">
        <div className="flex flex-row grow-children-equally divide-x text-center">
          <div>
            <Link
              activeClass="blue-highlight-custom"
              to="event_page"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="blue-highlight-custom font-bold"
            >
              {t('view-schedule')}
            </Link>
          </div>
          <div>
            <Link
              activeClass="blue-highlight-custom"
              to="gallery_page"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="blue-highlight-custom font-bold"
            >
              {t('view-gallery')}
            </Link>
          </div>
        </div>
      </div>
      <EventsPage id="event_page" items={allEventItems} />
      <div
        id="gallery_page"
        className="col-span-full flex flex-col pt-16 pb-12"
      >
        <div className="flex font-bold text-3xl">{t('gallery')}</div>
        <div className="text-md text-left pt-4">
          {pageModel.galleryDescription}
        </div>
      </div>
      <GalleryPage items={allGalleryItems.slice(0, 23)} />
    </Layout>
  );
};

export default EventPage;
