import React, { FC, useEffect, useLayoutEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  create,
  Circle,
  color,
  getInteraction,
} from '@amcharts/amcharts4/core';
import {
  MapChart as AmChartsMapChart,
  projections,
  MapPolygonSeries,
  MapImageSeries,
  MapImage,
} from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import { useTranslation } from 'react-i18next';
import { GlobalPartnerModel } from '../../library/models/primitives';
import { AccentedLink, AccentColor } from '../links';

export interface MapChartProps {
  globalPartners: GlobalPartnerModel[];
}
/**
 * AmCharts map documentation:
 * https://www.amcharts.com/docs/v4/chart-types/map/#Regional_and_county_maps
 *
 * there's no corresponding test for this component, because Jest does not support SVG:
 * https://www.amcharts.com/docs/v4/getting-started/using-typescript-or-es6/#Using_amCharts_with_Jest
 *
 * because of the lack of SVG support / Jest support, a unit test would end up mocking all of
 * amcharts4/core. instead, we'll rely on cypress acceptance testing / visual testing
 */
export const GlobalPartnerMap: FC<MapChartProps> = ({
  globalPartners,
}: MapChartProps): JSX.Element => {
  const chart = useRef<AmChartsMapChart | null>(null);

  // Setup with dispose can only be called once (no dependencies allowed)
  useLayoutEffect(() => {
    const ID = 'gp-map';
    const m = create(ID, AmChartsMapChart);
    if (m.svgContainer) {
      m.svgContainer.hideOverflow = false;
    }

    chart.current = m;

    // presentation / interaction
    chart.current.geodata = am4geodata_worldLow;
    chart.current.seriesContainer.draggable = false;
    chart.current.seriesContainer.resizable = false;
    chart.current.maxZoomLevel = 1;
    chart.current.chartContainer.wheelable = false;
    chart.current.projection = new projections.NaturalEarth1();

    // adds geodata to map and exclude Antarctica
    const polygonSeries = chart.current.series.push(new MapPolygonSeries());
    polygonSeries.exclude = ['AQ'];
    polygonSeries.useGeodata = true;

    return () => {
      m.dispose();
    };
  }, []);

  const { t } = useTranslation();
  const learnMore = t('learn-more');

  useEffect(() => {
    if (chart?.current === null) {
      return;
    }

    // gp dots
    // design preference is that dots/tooltips are clickable to be visible, the default is hover
    let currentTooltip: MapImage | null;
    const imageSeries = chart.current.series.push(new MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = 'longitude';
    imageSeries.mapImages.template.propertyFields.latitude = 'latitude';
    imageSeries.mapImages.template.userClassName = 'gp-point';
    imageSeries.mapImages.template.showTooltipOn = 'always';
    imageSeries.mapImages.template.events.on('hit', e => {
      if (currentTooltip?.tooltip) {
        currentTooltip.tooltip.hide();
      }

      currentTooltip = e.target;
      currentTooltip.tooltipHTML = `{toolTipHTML}`;
      currentTooltip.invalidate();
      currentTooltip.showTooltip();
    });

    getInteraction().body.events.on('hit', () => {
      if (currentTooltip?.tooltip) {
        currentTooltip.tooltip.hide();
      }
    });

    const circle = imageSeries.mapImages.template.createChild(Circle);
    circle.radius = 5;
    circle.propertyFields.fill = 'color';
    circle.nonScaling = true;

    // tooltip ui
    if (imageSeries.tooltip) {
      imageSeries.tooltip.getFillFromObject = false;
      imageSeries.tooltip.getStrokeFromObject = false;
      imageSeries.tooltip.label.fill = color('#231f20'); // --blab-black
      imageSeries.tooltip.label.fontSize = 20;
      imageSeries.tooltip.label.fontFamily = 'Helvetica';
      imageSeries.tooltip.label.maxWidth = 250;
      imageSeries.tooltip.label.wrap = true;
      imageSeries.tooltip.label.interactionsEnabled = true;
      imageSeries.tooltip.keepTargetHover = true;
    }

    // plot global partner dots
    const imageSeriesData = globalPartners.map(gp => {
      let link = <></>;
      if (gp.website !== undefined) {
        link = (
          <AccentedLink
            href={gp.website}
            accentColor={AccentColor.Yellow}
            className="font-bold"
          >
            {gp.website}
          </AccentedLink>
        );
      }

      return {
        latitude: gp.addressCoordinates.lat,
        longitude: gp.addressCoordinates.lon,
        color: '#ef4044', // --blab-red
        toolTipHTML: ReactDOMServer.renderToString(
          <div className="flex-col-stack-2">
            <div className="font-bold">{gp.name}</div>
            <div className="font-bold text-sm text-gray">
              {gp.addressDescription}
            </div>
            <div>{link}</div>
            <div className="font-bold text-sm text-gray">
              {t('founded-in', {
                year: new Date(gp.dateFounded).getFullYear(),
              })}
            </div>
          </div>
        ),
      };
    });

    imageSeries.data = imageSeriesData;
  }, [globalPartners, learnMore, t]);

  return (
    <div
      id="gp-map"
      className="hidden lg:block col-span-full"
      style={{ height: '500px' }}
    ></div>
  );
};
