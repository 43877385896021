import { ContentfulBlogPostQuery } from '../../__generated__/graphql-types';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { FromDecoder, JsonDecoder, Result } from 'ts.data.json';
import {
  ValidDateDecoder,
  RichTextDecoder,
  GatsbyImageDecoder,
  CommonModelDecoder,
  arrayOrUndefined,
  decodeContentfulModel,
} from './common';
import { enableStrictDecoderErrors } from '../env';
import { AuthorDecoder } from './author';
import { PressPost, PressDecoder } from './press-post';
import { CallToAction, CallToActionDecoder } from './call-to-action';

// Decoder for information needed only to display news items in list and carousel views
export const NewsItemBlogDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      slug: JsonDecoder.string,
      title: JsonDecoder.string,
      author: AuthorDecoder,
      publishedDate: ValidDateDecoder,
      excerpt: JsonDecoder.string,
      headerImage: JsonDecoder.optional(GatsbyImageDecoder),
    },
    'NewsItem BlogPost'
  )
);

export type NewsItemBlogPost = FromDecoder<typeof NewsItemBlogDecoder>;

export type BlogPost = NewsItemBlogPost & {
  slug: string;
  seoTitle: string;
  canonicalUrl: string | undefined;
  seoDescription: string;
  subtitle: string;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  relatedPosts: (BlogPost | PressPost)[];
  tags: string[];
  callToAction: CallToAction | undefined;
};

// Full blog decoder for page generation.
export const BlogDecoder: JsonDecoder.Decoder<BlogPost> = JsonDecoder.combine(
  NewsItemBlogDecoder,
  JsonDecoder.object(
    {
      seoTitle: JsonDecoder.string,
      canonicalUrl: JsonDecoder.optional(JsonDecoder.string),
      seoDescription: JsonDecoder.objectStrict(
        { seoDescription: JsonDecoder.string },
        'seoDescription'
      ).map(s => s.seoDescription),
      subtitle: JsonDecoder.string,
      body: RichTextDecoder,

      // Recursive decoder for related news items.
      relatedPosts: JsonDecoder.lazy(() =>
        arrayOrUndefined(
          'RelatedPosts',
          JsonDecoder.oneOf([BlogDecoder, PressDecoder], 'Blog/Press')
        )
      ),
      tags: arrayOrUndefined(
        'Tag',
        JsonDecoder.objectStrict({ tag: JsonDecoder.string }, 'Tag').map(
          t => t.tag
        )
      ),
      callToAction: JsonDecoder.optional(CallToActionDecoder),
    },
    'BlogPost'
  )
);

export function decodeBlogPostQuery(
  data: ContentfulBlogPostQuery
): Result<BlogPost[]> {
  return decodeContentfulModel(
    enableStrictDecoderErrors(),
    'BlogPost',
    data.allContentfulBlogPost.nodes,
    BlogDecoder
  );
}
