export function enableStrictDecoderErrors() {
  /**
   * Warning: GATSBY_*** environment variables are not accessible on the frontend
   *  when acceessed indirectly, ie. `process.env[name]` where name is a variable.
   */
  return (
    process.env.GATSBY_ENABLE_STRICT_DECODER_ERRORS?.toLowerCase() === 'true'
  );
}

export function requireStringEnv(name: string): string {
  const result = process.env[name];
  if (typeof result !== 'string' || result === '') {
    throw new Error(`missing required ${name} string option: ${result}`);
  }

  return result;
}

export function requireOneOfStringEnv(...names: string[]): string {
  const match = names.find(n => typeof process.env[n] === 'string');

  if (!match) {
    throw new Error(
      `missing one of required ${names.join(', ')} string options`
    );
  }

  // Already validated type in find operation
  return process.env[match] as string;
}

export function requireNumberEnv(name: string): number {
  const result = process.env[name];
  if (result === undefined) {
    throw new Error(`missing required ${name} number option`);
  }

  return +result;
}

export function optionalBooleanEnv(name: string): boolean {
  const result = process.env[name];
  return result !== undefined && result.toLowerCase() === 'true';
}

export function getSiteUrl(): string {
  return requireStringEnv('PUBLIC_URL');
}
