import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const FreshDesk: FC<{}> = (): JSX.Element => {
  return (
    <Helmet>
      <script>
        {`
          window.fwSettings = {
            widget_id: 43000001672,
          }
          !function(){
            if("function"!=typeof window.FreshworksWidget){
              var n=function(){
                n.q.push(arguments)
              };
              n.q=[],window.FreshworksWidget=n
            }
          }()`}
      </script>
      <script
        type="text/javascript"
        src="https://widget.freshworks.com/widgets/43000001672.js"
        async
        defer
      />
    </Helmet>
  );
};

export default FreshDesk;
