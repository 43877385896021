import { FromDecoder, JsonDecoder } from 'ts.data.json';
import { CommonModelDecoder } from '../common';

export const UrlDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      title: JsonDecoder.string,
      url: JsonDecoder.string,
    },
    'URL'
  )
);

export type UrlModel = FromDecoder<typeof UrlDecoder>;
