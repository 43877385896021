import React from 'react';
import clsx from 'clsx';
import Pill from '../../fiber/components/pill/pill';

const classes = {
  root: 'flex flex-row flex-wrap gap-x-4 gap-y-6',
  pill: {
    default: 'min-w-[80px] border',
    selected: 'text-white bg-fiber-grey-900 border-fiber-grey-900',
    unselected: 'border-surface-variant-light-outline',
  },
};

interface PillsListProps<T extends string> {
  options: Array<{ value: T; label: string; selected?: boolean }>;
  onSelect: (value: T) => void;
  defaultLabel: string;
  onDefault: () => void;
  className?: string;
}

function PillsList<T extends string>({
  options,
  onSelect,
  defaultLabel,
  onDefault,
  className,
}: PillsListProps<T>) {
  const isSomeSelected = options.some(e => e.selected);

  return (
    <div className={clsx(classes.root, className)}>
      <Pill
        label={defaultLabel}
        onClick={onDefault}
        className={clsx(classes.pill.default, {
          [classes.pill.selected]: !isSomeSelected,
          [classes.pill.unselected]: isSomeSelected,
        })}
      />
      {options.map(({ value, label, selected }) => (
        <Pill
          key={value}
          label={label}
          onClick={() => onSelect(value)}
          className={clsx(classes.pill.default, {
            [classes.pill.selected]: selected,
            [classes.pill.unselected]: !selected,
          })}
        />
      ))}
    </div>
  );
}

export default PillsList;
