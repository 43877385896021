import React, { FC } from 'react';
import TranslatedPage, {
  TranslatedPageProps,
} from '../../templates/translated-page';
import ComponentPage from '../../components/pages/404';

const Page: FC<TranslatedPageProps> = (props): JSX.Element => {
  return (
    <TranslatedPage {...props}>
      <ComponentPage />
    </TranslatedPage>
  );
};

export default Page;
