import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { decorativeImageAlt } from '../components/attributes';
import { PlainLink } from '../components/links';
import { CompanyType, IsCertified } from '../library/models/company';
import { DateWithMonthFormat } from '../components/date-formats';
import { Grid } from '../templates/layout/layout';

function lifestyleLogo(company: CompanyType): JSX.Element {
  const logo = company.lifestyleLogo ? (
    <img
      alt={decorativeImageAlt}
      src={company.lifestyleLogo}
      className="rounded-3xl h-full max-h-[inherit]"
    />
  ) : (
    <StaticImage
      alt={decorativeImageAlt}
      src="../../static/images/lifestyle-default.png"
      className="rounded-3xl"
    />
  );

  return (
    <div className="flex mx-auto rounded-3xl justify-center max-w-[992px] max-h-[384px]">
      {logo}
    </div>
  );
}

function CompanyLogo(company: CompanyType): JSX.Element {
  if (company.companyLogo) {
    return (
      <img
        alt={decorativeImageAlt}
        className="max-h-3/4 max-w-3/4"
        src={company.companyLogo}
      />
    );
  } else if (IsCertified(company)) {
    return (
      <StaticImage
        src="../../static/images/en-us/bcorp-logo-no-border-gray.png"
        alt={decorativeImageAlt}
        className="max-h-3/4 max-w-3/4"
        objectFit="contain"
      />
    );
  } else {
    return (
      <StaticImage
        src="../../static/images/certified-pending-bcorp-logo.png"
        alt={decorativeImageAlt}
        className="max-h-3/4 max-w-3/4"
        objectFit="contain"
      />
    );
  }
}

export function Header(company: CompanyType): JSX.Element {
  return (
    <>
      {/* Desktop */}
      <div
        data-testid="company-profile-header"
        className="hidden lg:flex col-span-full relative"
      >
        <div className="w-full rounded-3xl">{lifestyleLogo(company)}</div>
        <div
          className="flex flex-col p-2 h-60 w-60 bg-white rounded-3xl absolute right-24 shadow-lg"
          style={{ top: '60%' }}
        >
          <div className="flex h-full w-full justify-center items-center">
            {CompanyLogo(company)}
          </div>
        </div>
      </div>

      {/* Tablet + Mobile */}
      <div className="flex col-span-full relative lg:hidden justify-center mb-60">
        <div className="w-full rounded-3xl">{lifestyleLogo(company)}</div>
        <div className="flex flex-col w-[184px] h-[184px] px-8 bg-white rounded-3xl absolute top-3/4 shadow-lg">
          <div className="w-full h-full flex justify-center items-center">
            {CompanyLogo(company)}
          </div>
        </div>
      </div>
    </>
  );
}

function LabelValue(label: string, content: JSX.Element): JSX.Element {
  return (
    <div>
      <span>{label}</span>
      <div className="opacity-60">{content}</div>
    </div>
  );
}

export function CompanyInfo(company: CompanyType): JSX.Element {
  const { t } = useTranslation();

  const pendingCertifiedLogo = IsCertified(company) ? (
    <StaticImage
      src="../../static/images/en-us/bcorp-logo-no-border-gray.png"
      alt={t('certified-b-corporation')}
      width={75}
      height={125}
    />
  ) : (
    <StaticImage
      src="../../static/images/certified-pending-bcorp-logo.png"
      alt={t('pending-b-corporation')}
      width={75}
      height={125}
    />
  );

  const details = (
    <>
      {company.hqProvince ? (
        LabelValue(
          t('headquarters'),
          <p>
            {company.hqProvince}
            {','} {company.hqCountry}
          </p>
        )
      ) : (
        <></>
      )}

      {IsCertified(company) ? (
        LabelValue(
          t('profile-certified-since'),
          <p>
            <DateWithMonthFormat
              d={new Date(company.initialCertificationDateTimestamp)}
              className="font-serif"
            />
          </p>
        )
      ) : (
        <></>
      )}

      {company.industry && LabelValue(t('industry'), <p>{company.industry}</p>)}

      {company.sector ? (
        LabelValue(t('sector'), <p>{company.sector}</p>)
      ) : (
        <></>
      )}

      {company.countries && company.countries.length > 0 ? (
        LabelValue(
          t('operates-in'),
          <div>
            {company.countries.map((country, i, countriesArr) => (
              <p key={i} className="inline">
                {countriesArr.length - 1 === i ? country : country + ', '}
              </p>
            ))}
          </div>
        )
      ) : (
        <></>
      )}

      {company.website ? (
        LabelValue(
          t('website'),
          <PlainLink
            href={
              company.website.startsWith('http')
                ? company.website
                : `https://${company.website}`
            }
            target="_blank"
            className="font-serif"
            forceExternalLink={true}
            showReferral={true}
          >
            {company.website}
          </PlainLink>
        )
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      {/* Desktop */}
      <Grid className="col-span-full hidden lg:grid -mt-8 gap-y-0">
        <div className="w-full bg-gray-light col-start-2 col-end-5">
          <div className="flex justify-center">
            <div className="flex justify-center mt-8 bg-white px-6 py-4 rounded-3xl min-w-max max-w-min">
              {pendingCertifiedLogo}
            </div>
          </div>
        </div>

        <div className="hidden lg:flex flex-col bg-gray-light col-start-2 col-end-5 p-4 space-y-4 break-words">
          {details}
        </div>

        <div className="col-start-5 col-end-12 py-4">
          <h1>{company.name}</h1>
          <p className="my-8">{company.description}</p>
        </div>
      </Grid>

      {/* Tablet + Mobile */}
      <div className="lg:hidden flex flex-col col-span-full -mt-8 gap-y-0">
        <div className="flex w-full justify-center my-8">
          <h1>{company.name}</h1>
        </div>
        <div className="w-full bg-gray-light">
          <div className="flex justify-center">
            <div className="flex justify-center mt-8 bg-white px-6 py-4 rounded-3xl min-w-max max-w-min">
              {pendingCertifiedLogo}
            </div>
          </div>
        </div>

        <div className="lg:hidden flex flex-col bg-gray-light p-4 space-y-4">
          {details}
        </div>

        <p className="mt-8">{company.description}</p>
      </div>
    </>
  );
}
