export function elementHeight(e: Element): number {
  if (e instanceof HTMLElement) {
    return e.offsetHeight;
  }

  const rect = e.getBoundingClientRect();
  return rect.height;
}

export function elementWidth(e: Element): number {
  if (e instanceof HTMLElement) {
    return e.offsetWidth;
  }

  const rect = e.getBoundingClientRect();
  return rect.width;
}

export function scrollToTop(top = 0): void {
  window.scrollTo({
    behavior: 'smooth',
    left: 0,
    top,
  });
}
