import {
  faBars,
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { HTMLAttributes, forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CookieConsent } from '../../components/cookie-consent';
import FreshDesk from '../../components/fresh-desk/fresh-desk';
import { BiaLogo } from '../../components/icons';
import { PlainLink } from '../../components/links';
import MetaTags, { MetaTagProps } from '../../components/meta-tags/meta-tags';
import { Language } from '../../library/i18n';
import { combinePaths } from '../../library/url-parsing';
import {
  aboutBCorporationCertificationUrl,
  aboutBCorpsUrl,
  aboutBLabPageUrl,
  aboutLargeEnterpriseUrl,
  advisoryCouncilUrl,
  advisoryWorkingGroupsUrl,
  bImpactAssessmentUrl,
  bMovementBuildersUrl,
  careersUrl,
  collaborationsAndPartnershipsUrl,
  collectiveActionUrl,
  contactUsUrl,
  controversialIssuesUrl,
  developmentGovernanceUrl,
  faqsUrl,
  globalNetworkUrl,
  jediUrl,
  legalIndexUrl,
  movementOverviewUrl,
  newsIndexUrl,
  ourTeamUrl,
  pendingBCorpProgramUrl,
  performanceRequirementsUrl,
  policyAndAdvocacyUrl,
  programsAndToolsOverviewUrl,
  publicComplaintsProcessUrl,
  sdgActionManagerUrl,
  shareFeedbackUrl,
  stakeholderGovernanceUrl,
  standardsOverviewUrl,
  supportOurWorkUrl,
  theoryOfChangeUrl,
} from '../../library/urls';
import Footer from './footer';
import RollbarComponent from './rollbar';

// Organizes layers to simplify adding new or refactoring.
export enum Layer {
  Header = 1,
  NavDrawers = 2,
  MobileMenu = 3,
  SearchBar = 4,
  CallToActionContent = 5,
  Modal = 6,
}

// MapChart has a default layer that is not accounted for here but should be less than 10.
export const ZIndex = (l: Layer): string => {
  switch (l) {
    case Layer.CallToActionContent: {
      return 'z-10';
    }
    case Layer.SearchBar: {
      return 'z-10';
    }
    case Layer.Header: {
      return 'z-20';
    }
    case Layer.NavDrawers: {
      return 'z-20';
    }
    case Layer.MobileMenu: {
      return 'z-30';
    }
    case Layer.Modal: {
      return 'z-40';
    }
  }
};

// function getLanguageAgnosticPath(path: string): string {
//   const pieces = path.split('/').filter(p => p.length !== 0);

//   // Drop old language code.
//   pieces.shift();

//   return pieces.join('/');
// }

const Overlay = (props: {
  className?: string;
  onClick: () => void;
}): JSX.Element => {
  const propsClasses = `${props.className || ''}`;
  const classes = `fixed top-0 bottom-0 left-0 right-0 margin-0-important bg-black opacity-25 ${propsClasses}`;

  return <div className={classes} onClick={props.onClick}></div>;
};

const NavMenuLink = (props: { title: string; href: string }): JSX.Element => {
  const linkStyles =
    'text-sm font-bold p-2 hover:bg-blue transition-all duration-500 ease-in-out';
  return (
    <PlainLink href={props.href} className={linkStyles}>
      {props.title}
    </PlainLink>
  );
};

const MobileNavMenu = (props: {
  children: Array<JSX.Element> | JSX.Element;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const display = open ? 'display' : 'hidden';
  const translateX =
    'transform transition-all ' +
    (open ? 'translate-x-0' : '-translate-x-full');
  const fixedPosition = `fixed top-0 bottom-0 left-0 margin-0-important ${ZIndex(
    Layer.MobileMenu
  )} overflow-y-scroll`;
  const sidebarMenuStyles = `${fixedPosition} ${translateX} bg-black w-64 flex-col-stack-2 p-4 text-white border-r-4 border-yellow`;

  const openNav: () => void = () => {
    setOpen(true);
  };

  const closenNav: () => void = () => {
    setOpen(false);
  };

  const { t } = useTranslation();
  return (
    <>
      <div onClick={openNav}>
        <FontAwesomeIcon data-testid="hamburger-icon" icon={faBars} />
      </div>
      <Overlay className={display} onClick={closenNav} />
      <nav
        aria-label={t('main-mobile-navigation')}
        className={sidebarMenuStyles}
      >
        <div className="flex flex-row items-center py-2">
          <div className="flex-grow">
            <BiaLogo />
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-blue"
            onClick={closenNav}
          />
        </div>

        <div className="flex flex-col border-between-x-1">{props.children}</div>
      </nav>
    </>
  );
};

const SimpleNavMenuItem = (props: {
  href: string;
  children: string;
}): JSX.Element => {
  return (
    <PlainLink
      href={props.href}
      className="text-sm font-bold p-4 hover:yellow-animated-underline"
    >
      {props.children}
    </PlainLink>
  );
};

const SimpleMobileNavMenuItem = (props: {
  href: string;
  children: string;
}): JSX.Element => {
  return (
    <PlainLink
      href={props.href}
      className="block text-white py-2 text-sm font-bold"
    >
      {props.children}
    </PlainLink>
  );
};

const NavMenuDrawer = (props: {
  title: string;
  className?: string;
  children: Array<JSX.Element>;
  leftAlignedDrawer: boolean;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const propsClasses = `${props.className || ''}`;
  const linkStyles = `relative text-sm font-bold p-4 hover:yellow-animated-underline ${propsClasses}`;
  const display = open ? 'display' : 'hidden';
  const icon = open ? faChevronUp : faChevronDown;
  const align = props.leftAlignedDrawer ? '' : 'right-0';
  const fullDropDownStyles = `${display} ${ZIndex(
    Layer.NavDrawers
  )} absolute flex flex-col bg-white shadow w-48 mt-1 ${align}`;

  const openNav: () => void = () => {
    setOpen(true);
  };

  const closenNav: () => void = () => {
    setOpen(false);
  };

  return (
    <div className={linkStyles} onMouseOver={openNav} onMouseOut={closenNav}>
      <div className="flex-row-gutter-1 items-center">
        <span>{props.title}</span>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={fullDropDownStyles}>{props.children}</div>
    </div>
  );
};

const MobileMenuDrawer = (props: {
  title: string;
  className?: string;
  children: Array<JSX.Element> | JSX.Element;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const propsClasses = `${props.className || ''}`;
  const linkStyles =
    `text-sm font-bold border-between-x-1 ${propsClasses}`.trim();
  const display = open ? 'display' : 'hidden';
  const drawerStyles = `${display} border-between-x-1`;
  const icon = open ? faChevronUp : faChevronDown;

  const toggleNav: () => void = () => {
    setOpen(state => {
      return !state;
    });
  };

  return (
    <div className={linkStyles}>
      <div className="flex-row-gutter-1 items-center py-2" onClick={toggleNav}>
        <span>{props.title}</span>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={drawerStyles}>{props.children}</div>
    </div>
  );
};

const MobileMenuLink = (props: {
  title: string;
  href: string;
}): JSX.Element => {
  return (
    <PlainLink href={props.href} className="py-2 pl-4 block text-white">
      {props.title}
    </PlainLink>
  );
};

interface NavItem {
  title: string;
  href: string;
}

export function instanceOfNavItem(i: NavItem | NavDrawer): i is NavItem {
  return 'href' in i;
}

export interface NavDrawer {
  title: string;
  items: Array<NavItem>;
}

interface Menu {
  items: Array<NavDrawer | NavItem>;
}
export function getMenu(language: Language): Menu {
  return {
    items: [
      {
        title: 'the-movement',
        items: [
          {
            title: 'movement-title',
            href: `/${language}/${movementOverviewUrl}`,
          },
          {
            title: 'about-b-lab',
            href: combinePaths(language, aboutBLabPageUrl),
          },
          {
            title: 'support-our-work',
            href: `/${language}/${supportOurWorkUrl}`,
          },
          {
            title: 'global-network',
            href: combinePaths(language, globalNetworkUrl()),
          },
          {
            title: 'our-team',
            href: combinePaths(language, ourTeamUrl()),
          },
          {
            title: 'our-theory-of-change',
            href: `/${language}/${theoryOfChangeUrl}`,
          },
          {
            title: 'stakeholder-governance',
            href: `/${language}/${stakeholderGovernanceUrl}`,
          },
          {
            title: 'jedi',
            href: `/${language}/${jediUrl}`,
          },
          {
            title: 'collaborations-and-partnerships',
            href: `/${language}/${collaborationsAndPartnershipsUrl}`,
          },
          {
            title: 'careers',
            href: `/${language}/${careersUrl}`,
          },
          {
            title: 'contact-us',
            href: `/${language}/${contactUsUrl}`,
          },
        ],
      },
      {
        title: 'standards',
        items: [
          {
            title: 'about-our-standards',
            href: `/${language}/${standardsOverviewUrl}`,
          },
          {
            title: 'explore-the-latest-draft-standards',
            href: shareFeedbackUrl,
          },
          {
            title: 'standard-developement-and-governance',
            href: `/${language}/${developmentGovernanceUrl}`,
          },
          {
            title: 'standards-advisory-council',
            href: `/${language}/${advisoryCouncilUrl}`,
          },
          {
            title: 'standard-advisory-and-working-groups',
            href: `/${language}/${advisoryWorkingGroupsUrl}`,
          },
          {
            title: 'controversial-issues',
            href: `/${language}/${controversialIssuesUrl}`,
          },
          {
            title: 'public-complaints-process',
            href: `/${language}/${publicComplaintsProcessUrl}`,
          },
          {
            title: 'performance-requirements',
            href: `/${language}/${performanceRequirementsUrl}`,
          },
        ],
      },
      {
        title: 'programs-and-tools',
        items: [
          {
            title: 'programs-and-tools',
            href: `/${language}/${programsAndToolsOverviewUrl}`,
          },
          {
            title: 'b-impact-assessment',
            href: `/${language}/${bImpactAssessmentUrl}`,
          },
          {
            title: 'sdg-action-manager',
            href: `/${language}/${sdgActionManagerUrl}`,
          },
          {
            title: 'b-movement-builders',
            href: `/${language}/${bMovementBuildersUrl}`,
          },
          {
            title: 'pending-b-corps',
            href: `/${language}/${pendingBCorpProgramUrl}`,
          },
          {
            title: 'collective-action',
            href: `/${language}/${collectiveActionUrl}`,
          },
          {
            title: 'global-policy',
            href: `/${language}/${policyAndAdvocacyUrl}`,
          },
        ],
      },
      {
        title: 'about-b-corporations',
        items: [
          {
            title: 'about-b-corporation-certification',
            href: `/${language}/${aboutBCorporationCertificationUrl}`,
          },
          {
            title: 'b-corporations-legal-requirements',
            href: combinePaths(language, legalIndexUrl()),
          },
          {
            title: 'multinationals-and-large-enterprise-businesses',
            href: `/${language}/${aboutLargeEnterpriseUrl}`,
          },
          {
            title: 'b-impact-assessment-more-info-2',
            href: 'https://kb.bimpactassessment.net/en/support/home',
          },
          {
            title: 'resources',
            href: `/${language}/${aboutBCorpsUrl}`,
          },
          {
            title: 'faqs',
            href: `/${language}/${faqsUrl}`,
          },
          {
            title: 'b-corp-month-title',
            href: 'https://www.bcorpmonth.com',
          },
        ],
      },
      { title: 'find-a-b-corp', href: `/${language}/find-a-b-corp` },
      { title: 'news', href: combinePaths(language, newsIndexUrl()) },
    ],
  };
}

const HeaderButtons = (props: { buttonsClassName?: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="whitespace-nowrap">
      <a
        href="https://donate.bcorporation.net/give/333999/#!/donation/checkout"
        target="_blank"
        rel="noreferrer"
        className={clsx(
          'rounded-md border-2 border-black border-solid mr-2',
          props.buttonsClassName
        )}
      >
        {t('donate')}
      </a>
      <a
        href="https://app.bimpactassessment.net/login"
        target="_blank"
        rel="noreferrer"
        className={clsx(
          'rounded-md border-2 border-black border-solid bg-black text-white',
          props.buttonsClassName
        )}
      >
        {t('sign-in')}
      </a>
    </div>
  );
};

type HeaderProps = {
  additionalHeaders?: React.ReactNode;
};
let Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ additionalHeaders }: HeaderProps, ref): JSX.Element => {
    const { t, i18n } = useTranslation();

    const blabMenu = getMenu(i18n.language);

    const menuLinks = blabMenu.items.map(i => {
      if (instanceOfNavItem(i)) {
        return (
          <SimpleNavMenuItem href={i.href} key={i.title}>
            {t(i.title)}
          </SimpleNavMenuItem>
        );
      }

      const childItems = i.items.map(child => (
        <NavMenuLink
          title={t(child.title)}
          href={child.href}
          key={child.title}
        />
      ));

      return (
        <NavMenuDrawer
          title={t(i.title)}
          key={i.title}
          leftAlignedDrawer={true}
        >
          {childItems}
        </NavMenuDrawer>
      );
    });

    const mobileMenuLinks = blabMenu.items.map(i => {
      if (instanceOfNavItem(i)) {
        return (
          <SimpleMobileNavMenuItem href={i.href} key={i.title}>
            {t(i.title)}
          </SimpleMobileNavMenuItem>
        );
      }

      const childItems = i.items.map((child, index) => (
        <MobileMenuLink title={t(child.title)} href={child.href} key={index} />
      ));

      return (
        <MobileMenuDrawer title={t(i.title)} key={i.title}>
          {childItems}
        </MobileMenuDrawer>
      );
    });

    const headerClasses = `sticky top-0 w-screen bg-white shadow-x-small ${ZIndex(
      Layer.Header
    )}`;

    const logoUrl = `/${i18n.language}`;
    return (
      <header className={`${headerClasses}`}>
        {/* to be removed on Feb 28, 2022 */}
        <div ref={ref}>
          {/* Desktop + Tablet */}
          <div className={`hidden lg:block container mx-auto px-4 py-4`}>
            <div className="flex flex-row-gutter-2 flex-grow items-center">
              <PlainLink href={logoUrl}>
                <BiaLogo />
              </PlainLink>
              <nav
                aria-label={t('main-navigation')}
                className="flex flex-row flex-grow items-center"
              >
                {menuLinks}
              </nav>
              <HeaderButtons buttonsClassName="text-base px-4 py-3" />
            </div>
          </div>

          {/* Mobile */}
          <div className="lg:hidden flex flex-row justify-between flex-grow items-center px-4 md:px-8">
            <PlainLink href={logoUrl}>
              <BiaLogo />
            </PlainLink>
            <div className="flex flex-row space-x-2">
              <HeaderButtons buttonsClassName="text-sm px-4 py-2.5 my-1" />
              <MobileNavMenu>{mobileMenuLinks}</MobileNavMenu>
            </div>
          </div>
        </div>
        {additionalHeaders}
      </header>
    );
  }
);
Header.displayName = 'Header';
Header = memo(Header);

export const Grid = ({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={clsx('grid grid-cols-6 lg:grid-cols-12 gap-8', className)}
    {...rest}
  />
);

interface LayoutProps {
  className?: string;
  metaTagProps: MetaTagProps;
  headerRef?: React.Ref<HTMLDivElement>;
  additionalHeaders?: React.ReactNode;
  noContainerConstraints?: boolean;
  children: React.ReactNode;
}
const Layout = (props: LayoutProps): JSX.Element => {
  const {
    className,
    metaTagProps,
    headerRef,
    additionalHeaders,
    noContainerConstraints,
    children,
  } = props;

  return (
    <div className="h-full overflow-clip background-accent flex flex-col">
      <MetaTags {...metaTagProps} />
      <RollbarComponent />
      <FreshDesk />
      <CookieConsent />
      <Header ref={headerRef} additionalHeaders={additionalHeaders} />
      {noContainerConstraints && (
        <div className="max-h-full">
          <main className={className}>{children}</main>
          <footer className="container mx-auto p-4">
            <Footer />
          </footer>
        </div>
      )}
      {!noContainerConstraints && (
        <main className="max-h-full container flex-col-stack-6 mx-auto px-4 py-4">
          <Grid className={className}>{children}</Grid>
          <Footer />
        </main>
      )}
    </div>
  );
};

export default Layout;
