import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import CSS from 'csstype';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { elementHeight, elementWidth } from '../library/dom';
import { debounce } from '../library/debouncer';

const PageCounter = (props: {
  current: number;
  total: number;
  className?: string;
}): JSX.Element => {
  return (
    <div className={`${props.className || ''} flex flex-col`}>
      <div className="flex flex-row text-sm font-bold">
        <div data-testid="current-carousel-item" className="w-5 text-center">
          {String(props.current).padStart(2, '0')}
        </div>
        <div> / </div>
        <div data-testid="total-carousel-items" className="w-5 text-center">
          {String(props.total).padStart(2, '0')}
        </div>
      </div>
    </div>
  );
};

type CarouselContentElement<T> = (props: {
  key: React.Key;
  content: T;
  className?: string;
  style: CSSProperties;
}) => JSX.Element;

export function Carousel<T>(props: {
  className?: string;
  content: Array<T>;
  element: CarouselContentElement<T>;
}): JSX.Element {
  const [minHeight, setMinHeight] = React.useState(0);
  const [maxWidth, setMaxWidth] = React.useState<number | null>(null);
  const [page, setPage] = React.useState(0);

  const ref = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const previousPage: () => void = () => {
    const p = page - 1 < 0 ? props.content.length - 1 : page - 1;
    setPage(p);
  };

  const nextPage: () => void = () => {
    const p = page + 1 > props.content.length - 1 ? 0 : page + 1;
    setPage(p);
  };

  function handleWindowResize() {
    if (ref.current === null) {
      return;
    }

    const max = elementWidth(ref.current);
    if (max !== maxWidth) {
      setMaxWidth(max);
    }
  }

  // Contain carousel elements with max width to ensure height calculated even for hidden elements.
  React.useEffect(() => {
    handleWindowResize();

    const debouncedHandler = debounce(handleWindowResize, 250);
    window.addEventListener('resize', debouncedHandler);

    // Effect callback destructor
    return () => window.removeEventListener('resize', debouncedHandler);
  });

  // Calculate minimum height of all elements to prevents layout shift during paging.
  React.useEffect(() => {
    if (ref.current === null) {
      return;
    }

    let min = 0;
    ref.current.querySelectorAll('.carousel-item').forEach(item => {
      min = Math.max(min, elementHeight(item));
    });

    setMinHeight(min);
  }, [maxWidth]);

  const maxWidthStyle: CSS.Properties =
    maxWidth === null ? {} : { maxWidth: `${maxWidth}px` };
  const content = props.content.map((b, i) => {
    // `absolute invisible` style allows calculation of an elements height in DOM.
    const classes = `${
      i === page ? '' : 'absolute invisible'
    } flex-grow carousel-item`;
    return (
      <props.element
        key={i}
        content={b}
        className={classes}
        style={maxWidthStyle}
      />
    );
  });

  const minHeightStyle: CSS.Properties = { minHeight: `${minHeight}px` };
  return (
    <div className={`flex-col-stack-2 ${props.className || ''}`}>
      <PageCounter
        className="hidden lg:flex"
        current={page + 1}
        total={props.content.length}
      />
      <div ref={ref} className="flex" style={minHeightStyle}>
        {content}
      </div>
      <div className="pad-children-2 flex-row-gutter-2">
        {/** Spacer for desktop */}
        <div className="hidden lg:block flex-grow" />

        <div
          className="cursor-pointer hover:bg-black hover:text-white"
          role="button"
          title={t('carousel-previous-quote')}
          data-testid="previous-page"
          onClick={previousPage}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>

        {/** Expansion element for mobile */}
        <PageCounter
          className="lg:hidden flex-grow lg:flex-grow-0 items-center"
          current={page + 1}
          total={props.content.length}
        />
        <div
          className="cursor-pointer hover:bg-black hover:text-white"
          role="button"
          title={t('carousel-next-quote')}
          data-testid="next-page"
          onClick={nextPage}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
}
