import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../attributes';
import { AccentedLink, AccentColor } from '../../links';
import { Grid } from '../../../templates/layout/layout';
import { Carousel } from '../../carousel';
import { Separator } from '../../separator';
import { intersperse, dateSortDescending } from '../../../library/array-utils';
import { DateWithLongFormat } from '../../../components/date-formats';
import { newsIndexUrl, newsItemUrl } from '../../../library/urls';
import { combinePaths } from '../../../library/url-parsing';
import {
  useNewsItems,
  NewsItem,
  isMediaPost,
} from '../../../hooks/use-news-items';

function getImage(newsItem: NewsItem): JSX.Element {
  if (newsItem.headerImage !== undefined) {
    return (
      <GatsbyImage image={newsItem.headerImage} alt={decorativeImageAlt} />
    );
  }

  // Placeholder image for news items.
  return (
    <StaticImage
      src="../../../../static/images/news-placeholder.png"
      alt={decorativeImageAlt}
    />
  );
}

const AuthorAndPublishedDate = (props: { content: NewsItem }): JSX.Element => {
  const { t } = useTranslation();

  let author = <></>;
  if (!isMediaPost(props.content)) {
    author = (
      <div className="text-md text-left">
        {t('by-author', { author: props.content.author.name })}
      </div>
    );
  }

  return (
    <>
      {author}
      <div
        className={`text-md ${
          !isMediaPost(props.content) ? 'text-right' : 'text-left'
        }`}
      >
        <DateWithLongFormat d={new Date(props.content.publishedDate)} />
      </div>
    </>
  );
};

function getLinkLabel(p: NewsItem): string {
  if (!isMediaPost(p)) {
    return 'learn-more';
  }

  return 'read-article';
}

const DesktopStory = (props: { content: NewsItem }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <React.Fragment key={props.content.title}>
      <div className="col-span-full flex-row-gutter-6 grow-children-equally">
        <div className="flex-col-stack-4">{getImage(props.content)}</div>
        <div className="flex-col-stack-4">
          <div className="flex-col-stack-2">
            <div className="font-bold">{props.content.title}</div>
            <div className="flex flex-row grow-children-equally divide-x">
              <AuthorAndPublishedDate content={props.content} />
            </div>
          </div>
        </div>
        <div className="flex-col-stack-4">
          <p>{props.content.excerpt}</p>
          <AccentedLink
            href={newsItemUrl(props.content)}
            accentColor={AccentColor.Yellow}
            className="font-bold"
          >
            {t(getLinkLabel(props.content))}
          </AccentedLink>
        </div>
      </div>
    </React.Fragment>
  );
};

const MobileStory = (props: {
  content: NewsItem;
  className?: string;
  style: CSSProperties;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      key={props.content.title}
      className={`${props.className || ''} flex-col-stack-4`.trim()}
      style={props.style}
    >
      <div className="col-span-full">{getImage(props.content)}</div>
      <div className="flex-col-stack-4">
        <div className="font-bold">{props.content.title}</div>
        <div className="flex flex-row grow-children-equally">
          <AuthorAndPublishedDate content={props.content} />
        </div>
      </div>
      <div className="flex-col-stack-4">
        <p>{props.content.excerpt}</p>
        <AccentedLink
          href={newsItemUrl(props.content)}
          accentColor={AccentColor.Yellow}
          className="font-bold"
        >
          {t(getLinkLabel(props.content))}
        </AccentedLink>
      </div>
    </div>
  );
};

export const News = (props: { items: NewsItem[] }): JSX.Element => {
  const news = dateSortDescending(
    props.items,
    (p: NewsItem) => new Date(p.publishedDate)
  );

  return (
    <>
      <Grid className="hidden lg:grid col-span-full">
        {intersperse(
          i => (
            <Separator key={`sep-${i}`} />
          ),
          news.map((e, i) => {
            return <DesktopStory key={i} content={e} />;
          })
        )}
      </Grid>
      <div className="lg:hidden col-span-full">
        <Carousel content={news} element={MobileStory} className="px-8" />
      </div>
    </>
  );
};

const FrontPageNews = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const allData = useNewsItems();
  const latestItems = dateSortDescending(
    allData.filter(p => p.node_locale === i18n.language),
    (p: NewsItem) => new Date(p.publishedDate)
  ).slice(0, 3);

  return (
    <>
      <div className="col-span-full flex flex-row items-center">
        <div className="flex-grow font-bold text-3xl">{t('latest-news')}</div>
        <AccentedLink
          href={combinePaths(i18n.language, newsIndexUrl())}
          accentColor={AccentColor.Blue}
          className="font-bold"
        >
          {t('explore-more')}
        </AccentedLink>
      </div>
      <Separator />
      <News items={latestItems} />
    </>
  );
};

export default FrontPageNews;
