import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../attributes';
import { EventsItem, AllEventTypes } from '../../../library/models/event-page';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { AccentedLink, AccentColor } from '../../links';
import { Grid } from '../../../templates/layout/layout';
import { intersperse } from '../../../library/array-utils';
import { Separator } from '../../separator';
import { DateWithLongTimeFormat } from '../../../components/date-formats';
import { SlideButton } from '../../../components/buttons';
import CollapsibleFilters from '../../../components/collapsible';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Select, { MultiValue, StylesConfig } from 'react-select';
import { EventTypes } from '../../../library/models/common';
import { Button, BlabButtonStyle } from '../../buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import timeZoneList from '../../../library/timezones';

export enum PostSection {
  Upcoming = 'upcoming',
  Past = 'past',
}

function getImage(eventsItem: EventsItem): JSX.Element {
  if (eventsItem.eventImage !== undefined) {
    return (
      <GatsbyImage image={eventsItem.eventImage} alt={decorativeImageAlt} />
    );
  }

  return (
    <StaticImage
      src="../../../../static/images/b-microphone@2x.png"
      alt={decorativeImageAlt}
    />
  );
}

const countriesListFilters: { value: string; label: string }[] = [];

interface SelectOption {
  value: string;
  label: string;
}

const timeZoneOptions: SelectOption[] = timeZoneList.map(v => {
  return { value: v, label: v.replace(/_/g, ' ') };
});

const buildCountriesList = (posts: EventsItem[]) => {
  const countriesSet = new Set<string>();
  if (countriesListFilters.length === 0) {
    const global = 'Global';
    countriesSet.add(global);

    posts.forEach(post => {
      post.country.forEach(country => {
        countriesSet.add(country);
      });
    });

    countriesSet.forEach(country => {
      countriesListFilters.push({ value: country, label: country });
    });
  }
};

const ImpactArea = (props: { content: EventsItem }): JSX.Element => {
  return (
    <>
      {props.content.impactArea.map((e, i) => {
        if (i === 0) {
          return (
            <div className="text-md text-left pr-2" key={i}>
              {e}
            </div>
          );
        } else {
          return (
            <div className="text-md text-left pl-2 pr-2" key={i}>
              {e}
            </div>
          );
        }
      })}
    </>
  );
};

const EventLink = (props: {
  content: EventsItem;
  timezone?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const isPastPost =
    new Date(props.content.eventDate) < new Date() &&
    new Date(props.content.eventDateEnd) < new Date();

  const linkText = isPastPost
    ? t('b-corp-month-view-recording')
    : t('b-corp-month-sign-up');

  const eventLink = isPastPost
    ? props.content.recordingLink
    : props.content.eventLink;
  if (eventLink === undefined) {
    return <></>;
  }

  return (
    <AccentedLink
      href={eventLink}
      accentColor={AccentColor.Yellow}
      className="font-bold"
    >
      {linkText}
    </AccentedLink>
  );
};

const DesktopStory = (props: {
  content: EventsItem;
  timezone?: string;
}): JSX.Element => {
  return (
    <React.Fragment key={props.content.eventTitle}>
      <div className="col-span-full flex-row-gutter-6 pt-8 pb-8 grow-children-equally">
        <div className="flex-col-stack-4">{getImage(props.content)}</div>
        <div className="flex-col-stack-4">
          <div className="flex-col-stack-2">
            <div className="font-bold">{props.content.eventTitle}</div>
            <div className="flex flex-col grow-children-equally">
              <div className="text-md text-left">
                <DateWithLongTimeFormat
                  d={new Date(props.content.eventDate)}
                  timezone={props.timezone}
                />
              </div>
              <div className="text-md text-left">
                <DateWithLongTimeFormat
                  d={new Date(props.content.eventDateEnd)}
                  timezone={props.timezone}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-column">
            <div className="flex flex-wrap divide-x">
              <ImpactArea content={props.content} />
            </div>
          </div>
        </div>
        <div className="flex-col-stack-4">
          <p>
            <span className="font-bold">
              {'['}
              {props.content.eventType}
              {']'}
            </span>{' '}
            {props.content.excerpt}
          </p>
          <EventLink content={props.content} />
        </div>
      </div>
    </React.Fragment>
  );
};

const MobileStory = (props: {
  content: EventsItem;
  timezone?: string;
  className?: string;
}): JSX.Element => {
  return (
    <div
      key={props.content.eventTitle}
      className={`${props.className || ''} flex-col-stack-4 pt-10 pb-10`.trim()}
    >
      <div className="col-span-full">{getImage(props.content)}</div>
      <div className="flex-col-stack-4">
        <div className="font-bold">{props.content.eventTitle}</div>
      </div>
      <div className="flex-row-stack-4 grow-children-equall">
        <div className="text-md text-left">
          <DateWithLongTimeFormat
            d={new Date(props.content.eventDate)}
            timezone={props.timezone}
          />
        </div>
        <div className="text-md text-left">
          <DateWithLongTimeFormat
            d={new Date(props.content.eventDateEnd)}
            timezone={props.timezone}
          />
        </div>
      </div>
      <div className="flex-col-stack-4">
        <p>
          <span className="font-bold">
            {'['}
            {props.content.eventType}
            {']'}
          </span>{' '}
          {props.content.excerpt}
        </p>
        <EventLink content={props.content} />
      </div>
    </div>
  );
};

const Events = (props: {
  items: EventsItem[];
  section: string;
  timezone?: string;
}): JSX.Element => {
  const { t } = useTranslation();
  if (props.items.length === 0) {
    let period = '';
    switch (props.section) {
      case PostSection.Upcoming:
        period = 'no-upcoming-events';
        break;
      case PostSection.Past:
        period = 'no-past-events';
        break;
      default:
        break;
    }
    return (
      <>
        <div className="col-span-full flex-row-gutter-6 grow-children-equally">
          <div className="flex-col-stack-4">
            <div className="flex-col-stack-2 text-md text-center">
              {t(period)}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Grid className="hidden lg:grid col-span-full">
          <>
            {intersperse(
              i => (
                <Separator key={`sep-${i}`} />
              ),
              props.items.map((e, i) => {
                return (
                  <DesktopStory key={i} content={e} timezone={props.timezone} />
                );
              })
            )}
            <Separator />
          </>
        </Grid>
        <div id="mobile_events" className="lg:hidden col-span-full">
          {intersperse(
            i => (
              <Separator key={`sep-${i}`} />
            ),
            props.items.map((e, i) => {
              return (
                <MobileStory key={i} content={e} timezone={props.timezone} />
              );
            })
          )}
          <Separator />
        </div>
      </>
    );
  }
};

const EventsPage = (props: {
  items: EventsItem[];
  id: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState(props.items);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timezone, setTimezone] = useState<SelectOption | null>(
    tz ? { value: tz, label: tz.replace(/_/g, ' ') } : null
  );

  const [dateFilter, setDateFilterSelected] = useState(PostSection.Upcoming);

  const [impactAreaFilter] = useState<string[]>([]);

  const [natureOfEventFilter, setNatureOfEventFilterSelected] = useState<
    string[]
  >([]);

  const [countryFilter, setCountryFilterSelected] = useState<string[]>([]);

  const isPastPost = (post: EventsItem) => {
    return (
      new Date(post.eventDate) < new Date() &&
      new Date(post.eventDateEnd) < new Date()
    );
  };

  const isUpcomingPost = (post: EventsItem) => {
    return (
      new Date(post.eventDate) >= new Date() ||
      new Date(post.eventDateEnd) >= new Date()
    );
  };

  const filterPosts = () => {
    let ePosts = props.items;

    ePosts = ePosts.filter(post => {
      const dateFilterCheck =
        dateFilter === PostSection.Upcoming
          ? isUpcomingPost(post)
          : isPastPost(post);

      const eventTypeCheck =
        natureOfEventFilter.length > 0
          ? post.eventType.some(type => natureOfEventFilter.includes(type))
          : true;

      const countryCheck =
        countryFilter.length > 0
          ? post.country.some(type => countryFilter.includes(type))
          : true;

      return dateFilterCheck && eventTypeCheck && countryCheck;
    });

    setPosts(ePosts);
  };

  useEffect(() => {
    filterPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    natureOfEventFilter,
    impactAreaFilter,
    dateFilter,
    countryFilter,
    timezone,
  ]);

  useEffect(() => {
    buildCountriesList(posts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.items.length === 0) {
    return <></>;
  }

  const sections = [
    {
      onClick: () => {
        setDateFilterSelected(PostSection.Upcoming);
      },
      title: t(PostSection.Upcoming),
      selected: dateFilter === PostSection.Upcoming,
    },
    {
      onClick: () => {
        setDateFilterSelected(PostSection.Past);
      },
      title: t(PostSection.Past),
      selected: dateFilter === PostSection.Past,
    },
  ];

  const mobileSections = [
    {
      onClick: () => {
        setDateFilterSelected(PostSection.Upcoming);
      },
      title: t('mobile-upcoming'),
      selected: dateFilter === PostSection.Upcoming,
    },
    {
      onClick: () => {
        setDateFilterSelected(PostSection.Past);
      },
      title: t('mobile-past'),
      selected: dateFilter === PostSection.Past,
    },
  ];

  const eventTypeList = (): {
    value: string;
    label: string;
  }[] => {
    const eventTypes: { value: string; label: string }[] = [];
    AllEventTypes.forEach((eventType: string) => {
      const selectEventType = { value: '', label: '' };
      selectEventType.value = eventType;

      switch (eventType) {
        case EventTypes.Virtual: {
          selectEventType.label = t('b-corp-month-event-type-virtual');
          break;
        }
        case EventTypes.InPerson: {
          selectEventType.label = t('b-corp-month-event-type-in-person');
          break;
        }
        case EventTypes.ForBCorps: {
          selectEventType.label = t('b-corp-month-event-type-for-b-corps');
          break;
        }
      }
      eventTypes.push(selectEventType);
    });
    return eventTypes;
  };

  const filterPostByEventNature = (
    natureOfEvent: MultiValue<{ value: string; label: string }>
  ) => {
    setNatureOfEventFilterSelected(natureOfEvent.map(n => n.label));
  };

  const filterPostByCountry = (
    country: MultiValue<{ value: string; label: string }>
  ) => {
    setCountryFilterSelected(country.map(c => c.label));
  };

  const multiSelectCustomStyles: StylesConfig<SelectOption, true> = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      border: '1px solid #000000',
      boxShadow: 'none',
      color: '#000000',
      '&:hover': {
        border: '1px solid #000000',
        boxShadow: '0px 0px 6px #000000',
      },
    }),
  };

  return (
    <>
      <div
        id={props.id}
        data-testid="events_page"
        className="col-span-full flex flex-row items-center"
      >
        <div className="flex-grow font-bold text-3xl">
          {t('schedule-of-events')}
        </div>
      </div>
      {/* Desktop */}
      <Grid className="hidden lg:grid md:grid col-span-full">
        <div className="col-span-full lg:col-start-3 lg:col-end-11">
          <SlideButton sections={sections} />
        </div>
        <div className="col-span-full flex flex-column">
          <Select
            aria-label={t('b-corp-month-country-filter-placeholder')}
            styles={multiSelectCustomStyles}
            options={countriesListFilters}
            placeholder={t('b-corp-month-country-filter-placeholder')}
            isMulti
            className="basic-multi-select w-1/4 mr-2"
            classNamePrefix="event-type-select"
            backspaceRemovesValue={true}
            onChange={country => {
              filterPostByCountry(country);
            }}
          />
          <Select<SelectOption, true>
            aria-label={t('b-corp-month-event-all-types')}
            styles={multiSelectCustomStyles}
            options={eventTypeList()}
            placeholder={t('b-corp-month-event-all-types')}
            isMulti
            className="basic-multi-select w-1/4 ml-2 "
            classNamePrefix="event-type-select"
            backspaceRemovesValue={true}
            onChange={eventNature => {
              filterPostByEventNature(eventNature);
            }}
          />
          <div className="flex-grow" />
          <Select<SelectOption>
            aria-label={t('b-corp-month-event-time-zone')}
            value={timezone}
            options={timeZoneOptions}
            getOptionLabel={(o: SelectOption) => o.label}
            getOptionValue={(o: SelectOption) => o.value}
            placeholder={t('b-corp-month-event-time-zone')}
            className="basic-multi-select w-1/4 ml-2 "
            classNamePrefix="timezone-select"
            onChange={ev => {
              setTimezone(ev);
            }}
          />
        </div>

        <Separator />
      </Grid>
      {/* Mobile / Tablet */}
      <div className="lg:hidden md:hidden col-span-full">
        <div className="col-span-full lg:col-start-3 lg:col-end-11">
          <SlideButton sections={mobileSections} />
        </div>
        <div className="col-span-full flex-col-stack-4 pt-4 pb-4">
          <CollapsibleFilters open={false} title={t('more-filters')}>
            <div className="col-span-full">
              <div className="col-span-full lg:col-start-3 lg:col-end-11 space-y-4 pb-8">
                <div className="flex flex-row grow-children-equally">
                  <Select
                    aria-label={t('b-corp-month-country-filter-placeholder')}
                    styles={multiSelectCustomStyles}
                    options={countriesListFilters}
                    placeholder={t('b-corp-month-country-filter-placeholder')}
                    isMulti
                    className="basic-multi-select w-1/4 mr-1"
                    classNamePrefix="event-type-select"
                    backspaceRemovesValue={true}
                    onChange={country => {
                      filterPostByCountry(country);
                    }}
                  />
                </div>
                <div className="flex flex-row grow-children-equally">
                  <Select
                    aria-label={t('b-corp-month-country-filter-placeholder')}
                    styles={multiSelectCustomStyles}
                    options={eventTypeList()}
                    placeholder={t('b-corp-month-event-all-types')}
                    isMulti
                    className="basic-multi-select w-1/4 mr-1"
                    classNamePrefix="event-type-select"
                    backspaceRemovesValue={true}
                    onChange={eventNature => {
                      filterPostByEventNature(eventNature);
                    }}
                  />
                </div>
                <div className="flex flex-row grow-children-equally">
                  <Select<SelectOption>
                    aria-label={t('b-corp-month-event-time-zone')}
                    value={timezone}
                    options={timeZoneOptions}
                    getOptionLabel={(o: SelectOption) => o.label}
                    getOptionValue={(o: SelectOption) => o.value}
                    placeholder={t('b-corp-month-event-time-zone')}
                    className="basic-multi-select w-1/4 mr-1"
                    classNamePrefix="timezone-select"
                    onChange={ev => {
                      setTimezone(ev);
                    }}
                  />
                </div>
              </div>
              <div className="col-span-full lg:col-start-2 lg:col-end-4 list-none flex flex-wrap pb-8"></div>
              <div className="col-span-full flex-col-stack-2 pb-8">
                <Button
                  blabType={BlabButtonStyle.LargeFilledButton}
                  href="#mobile_events"
                >
                  <div className="flex-row-gutter-1">
                    <FontAwesomeIcon
                      id="chip-fa-icon"
                      size="lg"
                      icon={faCheck}
                    />
                    <span className="flex items-center">
                      {t('view-events')}
                    </span>
                    <span className="flex items-center">
                      {'(' + posts.length + ')'}
                    </span>
                  </div>
                </Button>
              </div>
            </div>
          </CollapsibleFilters>
        </div>
        <Separator />
      </div>
      <Events items={posts} section={dateFilter} timezone={timezone?.value} />
    </>
  );
};

export default EventsPage;
