import React from 'react';
import clxs from 'clsx';

interface PillProps {
  label?: JSX.Element | string;
  icon?: JSX.Element;
  iconLeft?: boolean;
  className?: string;
  onClick?: () => void;
}

const classes = {
  root: {
    default: 'w-fit flex flex-row justify-center items-center rounded-[32px]',
    label: 'px-2.5 py-1',
    iconOnly: 'p-2',
  },
  label: {
    default: 'whitespace-nowrap',
    iconLeft: 'ml-1',
    iconRight: 'mr-1',
  },
  icon: {
    default: 'w-4',
  },
  clickable: 'cursor-pointer',
};

function Pill({ label, icon, iconLeft, className, onClick }: PillProps) {
  if (!label) {
    if (!icon) {
      throw new Error('label or Icon must be present');
    }

    return (
      <div
        className={clxs(
          classes.root.default,
          classes.root.iconOnly,

          className
        )}
      >
        <span
          className={clxs(classes.icon.default, {
            [classes.clickable]: onClick,
          })}
          onClick={onClick}
        >
          {icon}
        </span>
      </div>
    );
  }

  return (
    <div
      className={clxs(
        classes.root.default,
        classes.root.label,
        {
          [classes.clickable]: !icon && onClick,
        },
        className
      )}
      onClick={(!icon && onClick) || undefined}
    >
      {icon && iconLeft && (
        <span
          className={clxs(classes.icon.default, {
            [classes.clickable]: onClick,
          })}
          onClick={onClick}
        >
          {icon}
        </span>
      )}
      <span
        className={clxs(classes.label.default, {
          [classes.label.iconLeft]: icon && iconLeft,
          [classes.label.iconRight]: icon && !iconLeft,
        })}
      >
        {label}
      </span>
      {icon && !iconLeft && (
        <span
          className={clxs(classes.icon.default, {
            [classes.clickable]: onClick,
          })}
          onClick={onClick}
        >
          {icon}
        </span>
      )}
    </div>
  );
}

export default Pill;
