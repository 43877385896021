import clsx from 'clsx';
import React from 'react';
import CheckIcon from '../../icons/outline/system-devices/check';

interface CheckboxProps {
  size: 'sm' | 'md' | 'lg';
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

const classes = {
  root: 'relative flex flex-row items-center',
  input: {
    default: 'outline-none border',
    hover: 'hover:border-black',
    active:
      'active:shadow-[0_0_0_4px_var(--color-fiber-grey-100)] focus:shadow-[0_0_0_4px_var(--color-fiber-grey-100)]',
    unchecked: {
      default: 'bg-white border-fiber-neutral-300',
      hover: 'hover:bg-fiber-grey-50 ',
      active:
        'active:bg-white active:border-fiber-grey-300 focus:bg-white focus:border-fiber-grey-300',
    },
    checked: {
      default: 'bg-black border-black',
      hover: '',
      active: '',
    },
    disabled: 'bg-fiber-neutral-100 border-fiber-neutral-200',
    size: {
      sm: 'w-4 h-4 rounded-[4px]',
      md: 'w-5 h-5 rounded-md',
      lg: 'w-6 h-6 rounded-md',
    },
  },
  checkbox: 'absolute flex justify-center items-center',
  icon: {
    default: 'text-white',
    size: {
      sm: 'w-3 h-3',
      md: 'w-4 h-4',
      lg: 'w-5 h-5',
    },
  },
  label: 'ml-4',
};

const Checkbox = ({
  size,
  label,
  checked,
  disabled,
  onChange,
  className,
}: CheckboxProps) => (
  <label className={clsx(classes.root, className)}>
    <input
      type="checkbox"
      {...{ checked, disabled, onChange }}
      className={clsx(classes.input.default, classes.input.size[size], {
        [classes.input.hover]: !disabled,
        [classes.input.active]: !disabled,
        [classes.input.unchecked.default]: !checked,
        [classes.input.unchecked.hover]: !checked && !disabled,
        [classes.input.unchecked.active]: !checked && !disabled,
        [classes.input.checked.default]: checked,
        [classes.input.checked.hover]: checked && !disabled,
        [classes.input.checked.active]: checked && !disabled,
        [classes.input.disabled]: disabled,
      })}
    />
    {checked && (
      <span className={clsx(classes.checkbox, classes.input.size[size])}>
        <div className={clsx(classes.icon.default, classes.icon.size[size])}>
          <CheckIcon />
        </div>
      </span>
    )}

    {label && <span className={classes.label}>{label}</span>}
  </label>
);

export default Checkbox;
