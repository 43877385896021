import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import { intersperse } from '../../library/array-utils';
import { GlobalPartnerModel } from '../../library/models/primitives';
import {
  useGlobalPartnerData,
  sortGlobalPartners,
} from '../../hooks/use-global-partner-data';
import { GlobalPartnerMap } from '../am-charts/map-chart';
import Accordion from '../accordion/accordion';
import { Separator } from '../separator';
import { AccentedLink, AccentColor } from '../links';
import { decorativeImageAlt } from '../attributes';

const GlobalPartnerPanel = ({
  website,
  logo,
  name,
  addressDescription,
  dateFounded,
  countriesAndRegions,
}: GlobalPartnerModel): JSX.Element => {
  const { t } = useTranslation();

  const link =
    website !== undefined ? (
      <AccentedLink
        accentColor={AccentColor.Yellow}
        className="font-bold"
        href={website}
      >
        {website}
      </AccentedLink>
    ) : (
      <></>
    );

  const icon = (
    <span
      className="flex"
      style={{
        width: 64,
        minWidth: 64,
        minHeight: 102,
        height: '100%',
      }}
    >
      <GatsbyImage image={logo} alt={decorativeImageAlt} objectFit="contain" />
    </span>
  );

  return (
    <Accordion title={name} icon={icon} data-testid="gp_accordion_item">
      <div className="flex-col-stack-2">
        <div className="font-bold text-sm text-gray">{addressDescription}</div>
        {link}
        <div className="font-bold text-sm text-gray">
          {t('founded-in', {
            year: new Date(dateFounded).getFullYear(),
          })}
        </div>
        <div>{countriesAndRegions.join(', ')}</div>
      </div>
    </Accordion>
  );
};

const GlobalPartnersList = ({
  globalPartners,
}: {
  globalPartners: GlobalPartnerModel[];
}) => (
  <>
    <Separator />
    {intersperse(
      i => (
        <Separator key={`sep-${i}`} />
      ),
      globalPartners.map((globalPartner, i) => (
        <GlobalPartnerPanel key={i} {...globalPartner} />
      ))
    )}
    <Separator />
  </>
);

export const GlobalPartners = ({
  globalPartners,
  isMap,
}: {
  globalPartners: GlobalPartnerModel[];
  isMap?: boolean;
}) => (isMap ? GlobalPartnerMap : GlobalPartnersList)({ globalPartners });

export const FrontPageGlobalPartnersMap = ({
  isListView,
}: {
  isListView?: boolean;
}): JSX.Element => {
  const globalPartners = sortGlobalPartners(
    useGlobalPartnerData().filter(gp => gp.node_locale === i18n.language)
  );

  return (
    <>
      {/** Desktop */}
      <div className="col-span-full hidden lg:block">
        {isListView ? (
          <div className="flex flex-col-stack-4 px-32">
            <GlobalPartners globalPartners={globalPartners} />
          </div>
        ) : (
          <GlobalPartners globalPartners={globalPartners} isMap />
        )}
      </div>

      {/** Tablet + Mobile */}
      <div
        data-testid="gp_accordion_list"
        className="col-span-full flex-col-stack-4 lg:hidden"
      >
        <GlobalPartners globalPartners={globalPartners} />
      </div>
    </>
  );
};
