import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../fiber/components/button/button';
import PaginationButton from '../fiber/components/pagination/button';
import ArrowLeftIcon from '../fiber/icons/outline/arrows/arrow-left';
import ArrowRightIcon from '../fiber/icons/outline/arrows/arrow-right';

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  onClick(value?: number): void;
  className?: string;
};

const MAX_TOTAL_PAGES = 7;
const CENTER_OFFSET = 4;

export const Pagination = (props: PaginationProps) => {
  const { t } = useTranslation();

  const maxPages = Math.min(props.totalPages, MAX_TOTAL_PAGES);
  const offset = (() => {
    if (props.totalPages <= MAX_TOTAL_PAGES) {
      return 0;
    }
    if (props.currentPage > props.totalPages - CENTER_OFFSET) {
      return props.totalPages - MAX_TOTAL_PAGES;
    }
    if (props.currentPage > CENTER_OFFSET) {
      return props.currentPage - CENTER_OFFSET;
    }
    return 0;
  })();

  return (
    <nav
      role="navigation"
      aria-label={t('search-pagination-navigation')}
      className={clsx(
        'grid grid-cols-2 lg:grid-cols-4 gap-y-10',
        props.className
      )}
    >
      <Button
        key="start"
        label={t('previous')}
        leftIcon={<ArrowLeftIcon />}
        disabled={props.currentPage === 1}
        onClick={() => props.onClick(props.currentPage - 1)}
        className="order-2 lg:order-1"
      />

      <div className="col-span-2 order-1 lg:order-2 justify-self-center flex-row-gutter-1.5">
        {new Array(maxPages).fill(null).map((_, index) => {
          const page = offset + index + 1;
          if (page > props.totalPages) {
            return <></>;
          }

          const selected = props.currentPage === page;

          return (
            <PaginationButton
              key={index}
              label={t('goto-page', { page: page })}
              selected={selected}
              disabled={selected}
              onClick={() => props.onClick(page)}
            >
              {page}
            </PaginationButton>
          );
        })}
      </div>

      <Button
        key="end"
        label={t('next')}
        leftIcon={<ArrowRightIcon />}
        disabled={props.currentPage === props.totalPages}
        onClick={() => props.onClick(props.currentPage + 1)}
        className="order-3 justify-self-end"
      />
    </nav>
  );
};
