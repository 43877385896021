import React from 'react';
import clsx from 'clsx';

interface BottomSheetProps {
  label: string;
  className?: string;
  children?: React.ReactNode;
}

const classes = {
  root: {
    default: 'absolute bottom-0 left-0 right-0 rounded-t-lg pb-6 bg-white',
  },
  title: {
    default: 'pt-4 px-6 pb-2.5 text-xl font-medium',
  },
};

function BottomSheet({ label, className, children }: BottomSheetProps) {
  return (
    <div className={clsx(classes.root.default, className)}>
      <div className={classes.title.default}>{label}</div>
      {children}
    </div>
  );
}

export default BottomSheet;
