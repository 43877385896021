import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '../../hooks/use-breakpoint';
import { useLockScroll } from '../../hooks/use-lock-scroll';
import SelectInput from '../../fiber/components/input/select-input';
import PositionedContentModal from '../modal/positioned-content-modal';
import { Search } from './search';
import { RefinementItem } from './constants';
import { useScrollToFilters } from './scroll';
import { useControlRefinementList } from './refinement-list';
import { sortByLabel } from './helpers';
import BottomSheet from '../dropdown/bottom-sheet';
import FullScreenModal from '../modal/full-screen-modal';

interface LocationSearchProps {
  countries: string[];
  className?: string;
}

const HEADERS_AREA_HEIGHT = 320;
const SEARCH_HEIGHT = 480 + 2 * 8;
const classes = {
  bottomSheet: 'w-screen max-h-3/4 flex flex-col !pb-0',
  search: 'my-2 absolute w-[408px] h-[480px]',
};

export const LocationSearch = ({
  countries,
  className,
}: LocationSearchProps) => {
  const { t } = useTranslation();
  const isLg = useBreakpoint('lg');
  const { lockScroll, unlockScroll } = useLockScroll('#___gatsby');
  const [open, setOpen] = useState(false);
  const countriesRL = useControlRefinementList(RefinementItem.countries);
  const hqOnlyRL = useControlRefinementList(RefinementItem.hqCountry);
  const [searchTerm, onSearch] = useState('');
  const scrollToFilters = useScrollToFilters();

  const [selected, setSelected] = useState<string[]>([]);
  useEffect(() => {
    setSelected([
      ...new Set(
        [...countriesRL.items, ...hqOnlyRL.items]
          .filter(e => e.isRefined)
          .map(e => e.value)
      ),
    ]);
  }, [countriesRL.items, hqOnlyRL.items]);

  const [headquatersOnly, setHeadquarterChecked] = useState(false);

  // Set headquarters checkmark when url contains selected HQ only countries
  const headquatersOnlyInit = useRef(false);
  const isHqSelected = hqOnlyRL.items.some(e => e.isRefined);
  useEffect(() => {
    if (headquatersOnlyInit.current) return;
    if (!isHqSelected) return;
    headquatersOnlyInit.current = true;
    setHeadquarterChecked(true);
  }, [isHqSelected]);

  const { refine, clearAll: onClearAll } = headquatersOnly
    ? hqOnlyRL
    : countriesRL;

  const items = countries
    .map(value => ({ value, label: value, selected: selected.includes(value) }))
    .sort(sortByLabel);

  const onItemSelect = (itemID: string) => {
    const item = countries.find(e => e === itemID);
    if (!item) return;
    refine(item);
    onSearch('');
  };

  const onHeadquatersOnlyClick = (isSelected: boolean) => {
    const refineNew = isSelected ? hqOnlyRL.refine : countriesRL.refine;
    const clearAllOld = (isSelected ? countriesRL : hqOnlyRL).clearAll;
    selected.forEach(value => refineNew(value));
    clearAllOld();
    setHeadquarterChecked(isSelected);
  };

  const onOpen = () => {
    if (isLg) {
      scrollToFilters();
      lockScroll();
    }
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    unlockScroll();
  };

  const selectedCount = selected.length;

  return (
    <div className={className}>
      <SelectInput
        label={t('location')}
        {...{ open, onOpen, onClose, selectedCount }}
      >
        {!isLg && (
          <FullScreenModal isOpen={open} onRequestClose={onClose}>
            <BottomSheet label={t('location')} className={classes.bottomSheet}>
              <Search
                {...{
                  listLabel: t('locations'),
                  items,
                  onItemSelect,
                  searchTerm,
                  onSearch,
                  checkboxLabel: t('headquarters-only'),
                  checboxChecked: headquatersOnly,
                  onCheckboxClick: onHeadquatersOnlyClick,
                  onClearAll,
                  onClose,
                }}
              />
            </BottomSheet>
          </FullScreenModal>
        )}
        {isLg && (
          <PositionedContentModal
            onRequestClose={onClose}
            minHeight={SEARCH_HEIGHT + HEADERS_AREA_HEIGHT}
          >
            <Search
              {...{
                listLabel: t('locations'),
                items,
                onItemSelect,
                searchTerm,
                onSearch,
                checkboxLabel: t('headquarters-only'),
                checboxChecked: headquatersOnly,
                onCheckboxClick: onHeadquatersOnlyClick,
                onClearAll,
                onClose,
              }}
              className={classes.search}
            />
          </PositionedContentModal>
        )}
      </SelectInput>
    </div>
  );
};
