import React from 'react';
import { InstantSearch as InstantSearchRIHW } from 'react-instantsearch';
import { routing } from './routing';
import { COMPANIES_COLLECTION_ALIAS } from '../../services/search/typesense/model';
import { makeTypesenseSearchAdaptor } from '../../services/search/typesense/search-api';
import { typesenceClusterConfiguration } from '../../services/search/typesense/cluster-configuration';
import {
  TypesenseSearchApiKey,
  TypesenseClientUrl,
  TypesenseEnrironment,
} from './constants';

const nodesConfiguration = TypesenseClientUrl
  ? { nodes: [{ url: TypesenseClientUrl }] }
  : typesenceClusterConfiguration;
export const typesenseInstantsearchAdapter = makeTypesenseSearchAdaptor({
  ...nodesConfiguration,
  apiKey: TypesenseSearchApiKey,
});

const indexName = COMPANIES_COLLECTION_ALIAS[TypesenseEnrironment];

interface InstantSearchProps {
  children?: React.ReactNode;
}

export const InstantSearch = (props: InstantSearchProps) => (
  <InstantSearchRIHW
    indexName={indexName}
    searchClient={typesenseInstantsearchAdapter.searchClient}
    routing={routing}
    {...props}
  />
);
