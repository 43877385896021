import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulGlobalPartnerQuery } from '../__generated__/graphql-types';
import { enableStrictDecoderErrors } from '../library/env';
import { decodeContentfulModel } from '../library/models/common';
import {
  GlobalPartnerModel,
  GlobalPartnerDecoder,
} from '../library/models/primitives';

export function useGlobalPartnerData(): GlobalPartnerModel[] {
  const data: ContentfulGlobalPartnerQuery = useStaticQuery(graphql`
    query ContentfulGlobalPartner {
      allContentfulGlobalPartner {
        nodes {
          contentful_id
          contentful_type: __typename
          node_locale
          name
          description
          website
          dateFounded
          logo {
            gatsbyImageData(layout: CONSTRAINED)
          }
          addressDescription
          addressCoordinates {
            lat
            lon
          }
          countriesAndRegions
        }
      }
    }
  `);

  const result = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'GlobalPartner',
    data.allContentfulGlobalPartner.nodes,
    GlobalPartnerDecoder
  );

  if (!result.isOk()) {
    throw new Error(result.error);
  }

  return result.value;
}

const BLabGlobalContentfulID = '6jpPptu4FJw5JLmatRlati';
export function sortGlobalPartners(
  data: GlobalPartnerModel[]
): GlobalPartnerModel[] {
  return data.sort((a, b) => {
    if (a.contentful_id === BLabGlobalContentfulID) {
      return -1;
    }
    if (b.contentful_id === BLabGlobalContentfulID) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  });
}
