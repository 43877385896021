import React, { FC, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { EnUs } from '../library/i18n';

const Page: FC<{}> = (): JSX.Element => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const url = new URL(window.location.href);
    const lang = i18n.language ? i18n.language.toLowerCase() : EnUs;

    navigate(`/${lang}${url.pathname}${url.search}`);
  }, [i18n.language]);

  return <></>;
};

export default Page;
