import { JsonDecoder, FromDecoder } from 'ts.data.json';
import {
  CommonModelDecoder,
  ValidDateDecoder,
  GatsbyImageDecoder,
  arrayOrUndefined,
} from '../common';

export const GlobalPartnerDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      name: JsonDecoder.string,
      description: JsonDecoder.optional(JsonDecoder.string),
      website: JsonDecoder.optional(JsonDecoder.string),
      dateFounded: ValidDateDecoder,
      logo: GatsbyImageDecoder,
      addressDescription: JsonDecoder.string,
      addressCoordinates: JsonDecoder.objectStrict(
        {
          lat: JsonDecoder.number,
          lon: JsonDecoder.number,
        },
        'addressCoordinates'
      ),
      countriesAndRegions: arrayOrUndefined(
        'countriesAndRegions',
        JsonDecoder.string
      ),
    },
    'GlobalPartner'
  )
);

export type GlobalPartnerModel = FromDecoder<typeof GlobalPartnerDecoder>;
