import React from 'react';
import CaretDownIcon from '../../icons/outline/arrows/caret-down';
import CaretUpIcon from '../../icons/outline/arrows/caret-up';
import InputWrapper, { InputWrapperProps } from './input-wrapper';
import Pill from '../pill/pill';

const classes = {
  placeholder: 'pr-1 text-fiber-neutral-500',
  value: 'flex-1 pr-1',
  icon: 'w-5',
  badge: 'mr-3 !w-5 h-5 font-medium text-xs text-white bg-black',
};

interface SelectInputProps
  extends Pick<InputWrapperProps, 'disabled' | 'error' | 'className'> {
  label?: string;
  selectedCount?: number;
  value?: string;
  placeholder?: string;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
}

function SelectInput({
  label,
  selectedCount,
  value,
  open,
  onOpen,
  onClose,
  children,
  ...rest
}: SelectInputProps) {
  const Icon = open ? CaretUpIcon : CaretDownIcon;
  const onClick = open ? onClose : onOpen;

  return (
    <div>
      <InputWrapper focused={open} {...rest} onClick={onClick}>
        {label && (
          <span className={classes.placeholder} aria-label={label}>
            {label}
          </span>
        )}
        <span className={classes.value}>{value}</span>
        {!!selectedCount && (
          <Pill label={`${selectedCount}`} className={classes.badge} />
        )}
        <span className={classes.icon}>
          <Icon />
        </span>
      </InputWrapper>
      {open && children}
    </div>
  );
}

export default SelectInput;
