import React, { FC } from 'react';
import { Language } from '../library/i18n';
import FindABCorp from '../components/pages/find-a-b-corp/find-a-b-corp';

export type FindABCorpContext = {
  language: Language;
  pagePath: string;
  countries: string[];
};

interface FindABCorpProps {
  readonly pageContext: FindABCorpContext;
}

const Page: FC<FindABCorpProps> = ({
  pageContext: { countries },
}): JSX.Element => {
  return <FindABCorp countries={countries} />;
};

export default Page;
