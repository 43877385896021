import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../../attributes';

const classes = {
  root: 'relative flex flex-row',
  textsContainer: 'p-6 lg:p-16',
  header: 'text-xl font-bold',
  description: 'text-4xl font-bold mt-4',
  imagesContainer: 'hidden lg:flex flex-row-gutter-4 lg:p-16',
  image: 'rounded-2xl',
  background:
    'absolute -z-10 top-0 left-0 right-0 bottom-[-136px] lg:bottom-[-80px] bg-gray-light rounded-[48px] lg:rounded-[40px]',
};

interface HeaderProps {
  className?: string;
}

const Header = ({ className }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.textsContainer}>
        <h1 className={classes.header} data-testid="directory-welcome">
          {t('looking-for-a-b-corp')}
        </h1>
        <div className={classes.description}>{t('directory-discover')}</div>
      </div>

      <div className={classes.imagesContainer}>
        <StaticImage
          src="../../../../../static/images/b-corp-search.png"
          alt={decorativeImageAlt}
          className={classes.image}
        />
        <StaticImage
          src="../../../../../static/images/letterb.jpg"
          alt={decorativeImageAlt}
          className={classes.image}
        />
      </div>

      <div className={classes.background} />
    </div>
  );
};

export default Header;
