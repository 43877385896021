import React, { useState, useRef, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface AccordionProps extends PropsWithChildren {
  title: string;
  icon?: JSX.Element;
  className?: string;
  'data-testid'?: string;
}

const Accordion = ({
  title,
  icon,
  children,
  className,
  'data-testid': dataTestID,
}: AccordionProps): JSX.Element => {
  const [isActive, setIsActiveState] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    if (ref.current) {
      setIsActiveState(isActive ? false : true);
      setContentHeight(isActive ? 0 : ref.current.scrollHeight);
    }
  };

  const accordianTransform =
    'transform transition-all duration-500 ease-in-out';
  const rotationClass = isActive ? 'rotate-90' : '';

  return (
    <div
      data-testid={dataTestID}
      className={`${className || ''} flex flex-col w-full`}
    >
      <button
        className="flex-row-gutter-4"
        onClick={toggleAccordion}
        aria-expanded={isActive}
      >
        {icon && <span className="flex self-center">{icon}</span>}
        <span
          data-testid="accordion_title"
          className="flex font-bold text-3xl text-left self-center overflow-hidden flex-grow"
        >
          {title}
        </span>
        <span className="flex self-center">
          <FontAwesomeIcon
            className={`${rotationClass} ${accordianTransform} ml-auto text-gray`.trim()}
            icon={faChevronRight}
          />
        </span>
      </button>
      <div
        ref={ref}
        data-testid="accordion-content"
        style={{ maxHeight: `${contentHeight}px` }}
        className={`${accordianTransform} overflow-auto`}
      >
        <div className="px-2 pt-6">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
