import { GatsbyImage } from 'gatsby-plugin-image';
import i18n from 'i18next';
import Layout, { Grid } from '../layout/layout';
import { decorativeImageAlt } from '../../components/attributes';
import { useChangeLanguage } from '../../library/i18n';
import React, { FC } from 'react';
import { Link } from 'gatsby';
import { WrappedRichText } from '../../components/rich-text';

import {
  OverviewCollection,
  Overview,
} from '../../library/models/overview-collection';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { createRelatedContentUrl } from '../../library/urls';
import { isString } from '../../library/string-utils';

export type OverviewPageContext = {
  pagePath: string;
  pageModel: OverviewCollection;
};

export type PageType = 'movement' | 'standards' | 'programs-and-tools';

interface OverviewPageProps {
  readonly pageContext: OverviewPageContext;
}

type OverviewDisplay = 'left' | 'right' | 'center';

const OverviewPage: FC<OverviewPageProps> = ({
  pageContext: { pageModel, pagePath },
}): JSX.Element => {
  useChangeLanguage(pageModel.node_locale);

  const siteMetadata = useSiteMetadata();

  const metaTagProps = {
    description: pageModel.seoDescription,
    title: pageModel.seoTitle,
    lang: i18n.language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
  };

  const overviewDetails = (overview: Overview, display: OverviewDisplay) => {
    let padding: string;
    switch (display) {
      case 'right':
        padding = 'pl-4';
        break;
      case 'left':
        padding = 'pr-4';
        break;
      default:
        padding = '';
    }

    return (
      <div className={`flex flex-col flex-grow ${padding} justify-center`}>
        <span className="pb-2 uppercase text-gray-dark">{overview.title}</span>
        <div className="pb-3 ">
          <p className="text-2xl">{overview.headline}</p>
        </div>
        <div className="pt-4">
          <WrappedRichText
            className="flex-col-stack-2"
            richText={overview.description}
          />
        </div>
        <div className="pt-4">
          <Link
            to={generateURL(overview)}
            className="text-bg-fx-yellow text-bg-fx text-bg-fx-scale-y "
          >
            {overview.callToAction}
          </Link>
        </div>
      </div>
    );
  };
  return (
    <Layout metaTagProps={metaTagProps}>
      {/** Desktop */}
      <Grid className="col-span-full hidden lg:grid">
        <div className="lg:flex flex-col col-span-full">
          <div className="text-center p-10">
            <div className="mb-5">
              <span className="text-4xl">{pageModel.title}</span>
            </div>
            <p>{pageModel.description}</p>
          </div>
          {pageModel.overviewItems.map((i, index) => {
            const direction = index % 2 ? 'right' : 'left';
            const details = overviewDetails(i, direction);
            return (
              <div key={index} className="flex flex-row p-10">
                {direction === 'left' && details}
                <GatsbyImage
                  image={i.image}
                  alt={decorativeImageAlt}
                  className="rounded-3xl overview-image"
                />
                {direction === 'right' && details}
              </div>
            );
          })}
        </div>
      </Grid>

      {/** Tablet + Mobile */}
      <Grid className="lg:hidden col-span-full">
        <div className=" col-span-full text-center p-10">
          <div className="mb-5">
            <span className="text-4xl">{pageModel.title}</span>
          </div>
          <p>{pageModel.description}</p>
        </div>
        <div className="col-span-full flex-col-stack-8 lg:hidden">
          {pageModel.overviewItems.map((i, index) => {
            return (
              <div
                key={index}
                className="flex-col-stack-2 text-left items-center"
              >
                <GatsbyImage
                  image={i.image}
                  alt={decorativeImageAlt}
                  className="rounded-3xl"
                />

                {overviewDetails(i, 'center')}
              </div>
            );
          })}
        </div>
      </Grid>
    </Layout>
  );
};

const generateURL = (overview: Overview): string => {
  if (isString(overview.url)) {
    return overview.url;
  }

  return createRelatedContentUrl(overview.url);
};

export default OverviewPage;
