import React, { useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FrontPageGlobalPartnersMap } from '../../components/global-partners/global-partners';
import { decorativeImageAlt } from '../../components/attributes';
import { SlideButton } from '../../components/buttons';
import { WrappedRichText } from '../../components/rich-text';
import { useChangeLanguage } from '../../library/i18n';
import { PartnersPageModel } from '../../library/models/partners-page';
import { AbstractPageModel } from '../../library/models/primitives';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import Layout, { Grid } from '../layout/layout';

export type PartnersPageContext = {
  pageModel: PartnersPageModel;
  pagePath: string;
};

export interface PartnersPageProps {
  readonly pageContext: PartnersPageContext;
  initialIsListView?: boolean;
}

const WelcomeMessage = ({ title, body }: AbstractPageModel): JSX.Element => (
  <>
    <h1 data-testid="hero-header" className="text-xl font-bold uppercase">
      {title}
    </h1>
    {body && (
      <WrappedRichText
        richText={body}
        additionalProps={{
          'heading-2': {
            as: 'h2',
            className: 'text-4xl font-bold leading-tight',
          },
        }}
      />
    )}
  </>
);

const PartnersPage = ({
  pageContext: { pageModel, pagePath },
  initialIsListView = false,
}: PartnersPageProps): JSX.Element => {
  const { t } = useTranslation();
  useChangeLanguage(pageModel.node_locale);

  const [isListView, setIsListView] = useState(initialIsListView);

  const { page, leftImage, rightImage, info, callout } = pageModel;

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    description: page.seoDescription,
    title: page.seoTitle,
    lang: i18n.language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
  };

  return (
    <Layout metaTagProps={metaTagProps} className="gap-y-14">
      <Grid className="hidden lg:grid col-span-full lg:breakout">
        <div className="col-span-full row-start-1 row-end-6 bg-gray-light rounded-3xl" />
        <div className="col-start-2 col-end-8 row-start-2 row-end-5 flex-col-stack-2">
          <WelcomeMessage {...page} />
        </div>
        <div className="col-start-8 col-end-12 row-start-2 row-end-7 flex-row-gutter-4 justify-end">
          <GatsbyImage
            className="w-1/2"
            image={leftImage}
            alt={decorativeImageAlt}
          />
          <GatsbyImage
            className="w-1/2"
            image={rightImage}
            alt={decorativeImageAlt}
          />
        </div>
      </Grid>

      {/** Tablet & Mobile */}
      <div className="flex-col-stack-6 lg:hidden col-span-full bg-gray-light rounded-3xl py-6">
        <div className="flex-col-stack-2 text-center items-center">
          <WelcomeMessage {...page} />
        </div>
      </div>

      <WrappedRichText
        richText={callout}
        className="col-span-full text-lg font-semibold text-center lg:col-span-4 lg:text-left"
      />
      <WrappedRichText
        richText={info}
        className="col-span-full flex-col-stack-4 text-center lg:col-span-8 lg:text-left"
      />

      <div className="hidden lg:block lg:col-start-5 lg:col-end-9">
        <SlideButton
          sections={[
            {
              onClick: () => {
                setIsListView(false);
              },
              title: t('map'),
              selected: isListView === false,
            },
            {
              onClick: () => {
                setIsListView(true);
              },
              title: t('list'),
              selected: isListView === true,
            },
          ]}
        />
      </div>

      <FrontPageGlobalPartnersMap isListView={isListView} />
    </Layout>
  );
};

export default PartnersPage;
