import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useSearchBox } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import animatePlaceholder from '../../library/animated-placeholder';
import MagnifyingGlassIcon from '../../fiber/icons/outline/system-devices/magnifying-glass';
import ArrowRightIcon from '../../fiber/icons/outline/arrows/arrow-right';
import XIcon from '../../fiber/icons/outline/math-finance/x';
import { useScrollToFilters } from './scroll';
import {
  useSortByOptions,
  INITIAL_SORT_VALUE,
  QUERY_SORT_VALUE,
} from './sort-by';

const useAnimatedPlaceholder = (
  inputRef: React.RefObject<HTMLInputElement>
) => {
  const { t } = useTranslation();
  const requestRef = useRef<number>();

  useEffect(() => {
    const phrases = [
      t('search-placeholder-1'),
      t('search-placeholder-2'),
      t('search-placeholder-3'),
      t('search-placeholder-4'),
    ];

    const animate = async () => {
      await animatePlaceholder(phrases, inputRef);
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      const frameID = requestRef.current;
      if (!frameID) return;
      cancelAnimationFrame(frameID);
    };
  }, [t, inputRef]);
};

const classes = {
  root: {
    default:
      'w-full max-w-[824px] flex flex-row justify-between items-center gap-4 h-[76px] pl-6 pr-2 bg-white shadow-small rounded-[40px]',
    withValue: 'border border-black',
    focused: 'border border-black shadow-[inset_0_-4px_0_var(--color-black)]',
  },
  icon: 'w-6 min-w-[24px]',
  input: 'w-full outline-none !border-none',
  button: {
    default:
      'my-2 flex px-6 py-4 gap-2 bg-fiber-yellow-600 rounded-[40px] font-medium text-fiber-grey-900',
    hidden: 'hidden',
  },
};

interface SearchBoxProps {
  className?: string;
  onSearch?: (value: string) => void;
}

export const SearchBox = ({
  className,
  onSearch: onSearchCb,
}: SearchBoxProps): JSX.Element => {
  const { t } = useTranslation();
  const { query, refine, clear } = useSearchBox({});
  const [inputValue, setInputValue] = useState(() => query);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const scrollToFilters = useScrollToFilters();
  const sortBy = useSortByOptions();

  useAnimatedPlaceholder(inputRef);

  const searchableValue = inputValue.trim();

  const onSearch = (value: string) => {
    refine(value);
    scrollToFilters();
    onSearchCb?.(value);
    inputRef.current?.blur();
    sortBy.refine(value ? QUERY_SORT_VALUE : INITIAL_SORT_VALUE);
  };
  const onClear = () => {
    clear();
    scrollToFilters();
    setInputValue('');
    sortBy.refine(INITIAL_SORT_VALUE);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value);
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    onSearch(searchableValue);
    return false;
  };
  const onFocus = () => {
    scrollToFilters();
    setFocused(true);
  };
  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (e.relatedTarget && e.relatedTarget === buttonRef.current) {
      // Cancel blur event when clicked on submit button
      return;
    }
    setFocused(false);
  };
  const onButtonClick = () => {
    if (focused) {
      onSearch(searchableValue);
      return;
    }
    onClear();
    return false;
  };
  const onButtonBlur = () => {
    setFocused(false);
  };

  const isButtonHidden = searchableValue === '';

  return (
    <div
      data-testid="search-box"
      className={clsx(
        classes.root.default,
        {
          [classes.root.withValue]: !isButtonHidden,
          [classes.root.focused]: focused,
        },
        className
      )}
    >
      <span className={classes.icon}>
        <MagnifyingGlassIcon />
      </span>
      <input
        data-testid="search-input"
        ref={inputRef}
        type="text"
        value={inputValue}
        className={classes.input}
        aria-label={t('query')}
        {...{ onChange, onKeyPress, onFocus, onBlur }}
      />
      <button
        ref={buttonRef}
        data-testid="search-button"
        type="submit"
        onClick={onButtonClick}
        onMouseDown={e => {
          // needed to prevent blur event from overriding onClick handler
          // https://stackoverflow.com/a/64877996
          // https://stackoverflow.com/a/43356000
          e.preventDefault();
          e.stopPropagation();
        }}
        onBlur={onButtonBlur}
        className={clsx(classes.button.default, {
          [classes.button.hidden]: isButtonHidden,
        })}
        aria-label={t(focused ? 'search' : 'clear')}
        aria-hidden={isButtonHidden}
      >
        <div className="hidden sm:flex">{t(focused ? 'search' : 'clear')}</div>
        <span className="w-6">{focused ? <ArrowRightIcon /> : <XIcon />}</span>
      </button>
    </div>
  );
};
