import { ContentfulEventPageQuery } from '../../__generated__/graphql-types';
import { FromDecoder, JsonDecoder, Result } from 'ts.data.json';
import { enableStrictDecoderErrors } from '../env';
import {
  GatsbyImageDecoder,
  decodeContentfulModel,
  arrayOrUndefined,
  ValidDateDecoder,
  EventTypes,
  LanguageDecoder,
  CommonModelDecoder,
  RichTextDecoder,
} from './common';

export const AllEventTypes: string[] = [
  EventTypes.Virtual,
  EventTypes.InPerson,
  EventTypes.ForBCorps,
];

const EventTypeDecoder = JsonDecoder.string.chain(eventType => {
  switch (eventType) {
    case EventTypes.Virtual: {
      return JsonDecoder.constant(EventTypes.Virtual);
    }
    case EventTypes.InPerson: {
      return JsonDecoder.constant(EventTypes.InPerson);
    }
    case EventTypes.ForBCorps: {
      return JsonDecoder.constant(EventTypes.ForBCorps);
    }
    default: {
      return JsonDecoder.fail<EventTypes>(`unknown Event Type ${eventType}`);
    }
  }
});

export type EventPage = FromDecoder<typeof EventPageDecoder>;

const EventDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.objectStrict(
    {
      contentful_id: JsonDecoder.string,
      contentful_type: JsonDecoder.string,
      node_locale: LanguageDecoder,
      eventTitle: JsonDecoder.string,
      eventImage: GatsbyImageDecoder,
      eventDate: ValidDateDecoder,
      eventDateEnd: ValidDateDecoder,
      eventType: JsonDecoder.array(EventTypeDecoder, 'eventType'),
      eventLink: JsonDecoder.string,
      recordingLink: JsonDecoder.optional(JsonDecoder.string),
      excerpt: JsonDecoder.string,
      impactArea: JsonDecoder.array(JsonDecoder.string, 'impactArea'),
      country: JsonDecoder.array(JsonDecoder.string, 'country'),
    },
    'event_post'
  )
);

export type EventsItem = FromDecoder<typeof EventDecoder>;

const EventPageDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      seoTitle: JsonDecoder.string,
      seoDescription: JsonDecoder.objectStrict(
        { seoDescription: JsonDecoder.string },
        'seoDescription'
      ).map(s => s.seoDescription),
      header: JsonDecoder.string,
      headerImage: JsonDecoder.optional(GatsbyImageDecoder),
      headerCallout: JsonDecoder.optional(RichTextDecoder),
      subHeader: JsonDecoder.string,
      body: RichTextDecoder,
      bodyCallout: JsonDecoder.optional(
        JsonDecoder.objectStrict(
          { bodyCallout: JsonDecoder.string },
          'bodyCallout'
        ).map(s => s.bodyCallout)
      ),
      galleryDescription: JsonDecoder.objectStrict(
        { galleryDescription: JsonDecoder.string },
        'galleryDescription'
      ).map(s => s.galleryDescription),
      posts: JsonDecoder.lazy(() => arrayOrUndefined('posts', EventDecoder)),
    },
    'Raw EventPage'
  )
);

export function decodeEventPageQuery(
  data: ContentfulEventPageQuery
): Result<EventPage[]> {
  return decodeContentfulModel(
    enableStrictDecoderErrors(),
    'EventPage',
    data.allContentfulEventPage.nodes,
    EventPageDecoder
  );
}
