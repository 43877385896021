import React from 'react';
import clsx from 'clsx';

interface SectionDividerProps {
  variant?: 'single' | 'double' | 'background';
  className?: string;
  children?: React.ReactNode;
}

const classes = {
  root: {
    single: 'flex flex-row items-center whitespace-nowrap',
    double: 'flex flex-col items-center',
    background:
      'flex flex-row justify-center items-center p-2 rounded-lg bg-fiber-neutral-50',
  },
  line: 'w-full h-px bg-surface-variant-light-outline',
};

const Line = () => <div className={classes.line} />;

const SectionDivider = ({
  variant = 'single',
  className,
  children,
}: SectionDividerProps) => {
  const rootClassName = clsx(classes.root[variant], className);

  switch (variant) {
    case 'single': {
      if (!children) {
        return (
          <div className={rootClassName}>
            <Line />
          </div>
        );
      }
      return (
        <div className={rootClassName}>
          <Line />
          {children}
          <Line />
        </div>
      );
    }

    case 'double': {
      if (!children) {
        throw new Error('children must exist for double divider');
      }
      return (
        <div className={rootClassName}>
          <Line />
          {children}
          <Line />
        </div>
      );
    }

    case 'background': {
      if (!children) {
        throw new Error('children must exist for background divider');
      }
      return <div className={rootClassName}>{children}</div>;
    }
  }
};

export default SectionDivider;
