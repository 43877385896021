import { graphql, useStaticQuery } from 'gatsby';
import { JsonDecoder, FromDecoder } from 'ts.data.json';
import {
  GatsbyImageDecoder,
  decodeContentfulModel,
  CommonModelDecoder,
} from '../library/models/common';
import { enableStrictDecoderErrors } from '../library/env';

export const QuoteDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      author: JsonDecoder.string,
      title: JsonDecoder.string,
      content: JsonDecoder.objectStrict(
        { content: JsonDecoder.string },
        'content'
      ).map(s => s.content),
      authorImage: GatsbyImageDecoder,
      order: JsonDecoder.number,
    },
    'Quote'
  )
);

export type Quote = FromDecoder<typeof QuoteDecoder>;

export function useQuotes(): Quote[] {
  const data = useStaticQuery(graphql`
    query ContentfulQuote {
      allContentfulQuote {
        nodes {
          contentful_id
          contentful_type: __typename
          node_locale
          author
          title
          content {
            content
          }
          authorImage {
            gatsbyImageData(width: 75, aspectRatio: 1)
          }
          order
        }
      }
    }
  `);

  const result = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'Quote',
    data.allContentfulQuote.nodes,
    QuoteDecoder
  );

  if (!result.isOk()) {
    throw new Error(result.error);
  }

  return result.value.sort((a, b) => a.order - b.order);
}
