import { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';

export type MakeTypesenseApiOptions = Pick<
  ConfigurationOptions,
  'apiKey' | 'nearestNode' | 'nodes'
>;

const TYPESENCE_ENVIRONMENT_OPTIONS = [
  'development',
  'test',
  'staging',
  'production',
] as const;
export type TypesenseEnvironment =
  (typeof TYPESENCE_ENVIRONMENT_OPTIONS)[number];

export function toTypesenseEnvironment(typesenseEnvironment?: string) {
  if (!typesenseEnvironment) {
    throw new Error('missing typesense environment');
  }
  if (
    !TYPESENCE_ENVIRONMENT_OPTIONS.includes(
      typesenseEnvironment as TypesenseEnvironment
    )
  ) {
    throw new Error('wrong typesense environment');
  }
  return typesenseEnvironment as TypesenseEnvironment;
}

export const COMPANIES_COLLECTION_ALIAS = {
  development: 'companies-development-en-us',
  test: 'companies-development-en-us',
  staging: 'companies-staging-en-us',
  production: 'companies-production-en-us',
} satisfies Record<TypesenseEnvironment, string>;
