import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FromDecoder, JsonDecoder } from 'ts.data.json';

const SiteMetaDataDecoder = JsonDecoder.objectStrict(
  {
    site: JsonDecoder.objectStrict(
      {
        siteMetadata: JsonDecoder.objectStrict(
          {
            siteUrl: JsonDecoder.string,
          },
          'SiteMetadata'
        ),
      },
      'Site'
    ),
  },
  'SiteMap'
).map(r => {
  return r.site.siteMetadata;
});

export type SiteMetaData = FromDecoder<typeof SiteMetaDataDecoder>;

export const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const decodedResult = useMemo(
    () => SiteMetaDataDecoder.decode(result),
    [result]
  );

  if (!decodedResult.isOk()) {
    throw new Error(`failed to query site metadata: ${decodedResult.error}`);
  }

  return decodedResult.value;
};
