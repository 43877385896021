import config from '../tailwind.config';

interface ColorConfig {
  [key: string]:
    | string
    | {
        [key: string]: string;
      };
}

export const customColors: ColorConfig = config.theme.colors;
export const screenSizes = config.theme.container.screens;
