import { ContentfulMediaPostQuery } from '../../__generated__/graphql-types';
import { JsonDecoder, Result, FromDecoder } from 'ts.data.json';
import {
  ValidDateDecoder,
  GatsbyImageDecoder,
  CommonModelDecoder,
  decodeContentfulModel,
} from './common';
import { enableStrictDecoderErrors } from '../env';

export const MediaPostDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      title: JsonDecoder.string,
      excerpt: JsonDecoder.string,
      publishedDate: ValidDateDecoder,
      headerImage: GatsbyImageDecoder,
      link: JsonDecoder.string,
    },
    'MediaPost'
  )
);

export type MediaPost = FromDecoder<typeof MediaPostDecoder>;

export function decodeMediaPostQuery(
  data: ContentfulMediaPostQuery
): Result<MediaPost[]> {
  return decodeContentfulModel(
    enableStrictDecoderErrors(),
    'MediaPost',
    data.allContentfulMediaPost.nodes,
    MediaPostDecoder
  );
}
