import React from 'react';
import clsx from 'clsx';

export const BUTTON_TYPE = ['neutral', 'grey'] as const;
export const BUTTON_SIZE = ['sm', 'md', 'lg'] as const;

export interface ButtonProps {
  type?: (typeof BUTTON_TYPE)[number];
  size?: (typeof BUTTON_SIZE)[number];
  label?: string;
  icon?: JSX.Element;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
}

const classes = {
  default:
    'outline-none w-fit inline-flex items-center gap-3 border rounded-md',
  neutral: {
    default: 'text-fiber-neutral-900 bg-white border-fiber-neutral-300',
    hover: 'hover:bg-fiber-neutral-50',
    active:
      'active:bg-fiber-neutral-50 active:border-fiber-neutral-300 active:shadow-[0_0_0_4px_var(--color-fiber-neutral-100)]',
    focus:
      'focus:bg-white focus:border-fiber-neutral-300 focus:shadow-[0_0_0_4px_var(--color-fiber-neutral-100)]',
    disabled: 'text-fiber-neutral-400',
  },
  grey: {
    default: 'text-white bg-fiber-neutral-900 border-fiber-neutral-900',
    hover: 'hover:bg-fiber-grey-800 hover:border-fiber-grey-800',
    active:
      'active:bg-fiber-grey-800 active:border-fiber-grey-800 active:shadow-[0_0_0_4px_var(--color-fiber-grey-100)]',
    focus:
      'focus:bg-fiber-grey-900 focus:border-fiber-grey-900 focus:shadow-[0_0_0_4px_var(--color-fiber-grey-100)]',
    disabled: 'bg-fiber-grey-200 border-fiber-grey-200',
  },
  withLabel: {
    sm: 'text-sm px-4 py-2.5',
    md: 'text-base px-5 py-3',
    lg: 'text-lg px-6 py-3.5',
  },
  withoutLabel: {
    sm: 'p-2.5',
    md: 'p-3.5',
    lg: 'p-4',
  },
  disabled: 'cursor-not-allowed',
  icon: {
    sm: 'w-5',
    md: 'w-5',
    lg: 'w-6',
  },
};

const Button = ({
  type = 'neutral',
  size = 'md',
  label,
  icon,
  leftIcon,
  rightIcon,
  disabled,
  onClick,
  className,
}: ButtonProps) => {
  if (icon && (label || leftIcon || rightIcon)) {
    throw new Error(`can't use icon prop with label, leftIcon or rightIcon`);
  }

  return (
    <button
      className={clsx(classes.default, classes[type].default, className, {
        [classes[type].hover]: !disabled,
        [classes[type].active]: !disabled,
        [classes[type].focus]: !disabled,
        [classes[type].disabled]: disabled,
        [classes.disabled]: disabled,
        [classes.withLabel[size]]: label,
        [classes.withoutLabel[size]]: !label,
      })}
      disabled={disabled}
      onClick={onClick}
      aria-label={label}
    >
      {leftIcon && <span className={classes.icon[size]}>{leftIcon}</span>}
      {label}
      {icon && <span className={classes.icon[size]}>{icon}</span>}
      {rightIcon && <span className={classes.icon[size]}>{rightIcon}</span>}
    </button>
  );
};

export default Button;
