import React from 'react';
import { useTranslation } from 'react-i18next';

const Disclaimer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <p className="col-span-full text-center text-gray-dark text-sm font-sans">
      {t('disclaimer')}
    </p>
  );
};

export default Disclaimer;
