import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { decorativeImageAlt } from '../attributes';
import CertifiedSince from './certified-since';

interface CompanyCardProps {
  name: string;
  description?: string | null;
  initialCertificationDate: JSX.Element;
  link: string;
  logo?: string;
  className?: string;
}

const classes = {
  root: {
    default:
      'flex flex-col lg:flex-row lg:gap-x-6 bg-white rounded-lg border border-white overflow-hidden shadow-[0_4px_8px_-2px_rgba(16,24,40,0.1),0_2px_4px_-2px_rgba(16,24,40,0.06)]',
    hover:
      'hover:border-black hover:shadow-[0_20px_24px_-4px_rgba(16,24,40,0.1),0_8px_8px_-4px_rgba(16,24,40,0.04)]',
  },
  logoWrapper:
    'self-center lg:min-w-[244px] lg:max-w-[244px] flex justify-center items-center p-4 lg:p-8',
  logo: 'object-contain w-[120px] lg:w-[180px] h-[74px] lg:h-auto lg:max-h-[240px]',
  grid: 'p-4 lg:p-6 grid gap-x-10 gap-y-8 grid-cols-2',
  textWrapper: 'col-span-full',
  name: 'text-xl font-medium text-black',
  description: 'mt-4 line-clamp-3 text-fiber-neutral-900',
  badges: 'col-span-1 lg:col-span-3 flex flex-row',
};

const CompanyCard = ({
  name,
  description,
  initialCertificationDate,
  link,
  logo,
}: CompanyCardProps) => {
  const { t } = useTranslation();

  const image = (() => {
    if (logo) {
      return (
        <img src={logo} alt={decorativeImageAlt} className={classes.logo} />
      );
    }
    if (initialCertificationDate) {
      return (
        <StaticImage
          src="../../../static/images/en-us/bcorp-logo-no-border-gray.png"
          alt={decorativeImageAlt}
          className={classes.logo}
          objectFit="contain"
        />
      );
    }
    return null;
  })();

  return (
    <Link
      to={link}
      aria-label={t('company-profile-link-label', { company: name })}
      data-testid="profile-link"
    >
      <div className={clsx(classes.root.default, classes.root.hover)}>
        <div className={classes.logoWrapper}>{image}</div>

        <div className={classes.grid}>
          <div className={classes.textWrapper}>
            <span className={classes.name} data-testid="company-name-desktop">
              {name}
            </span>
            <p className={classes.description}>{description}</p>
          </div>

          <div className={classes.badges}>
            <CertifiedSince
              label={t('certified-since')}
              initialCertificationDate={initialCertificationDate}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CompanyCard;
