import React, { FC, useEffect, useState } from 'react';
import { Language } from '../../library/i18n';
import Layout from '../layout/layout';
import {
  blogIndexUrl,
  pressIndexUrl,
  newsIndexUrl,
  mediaIndexUrl,
} from '../../library/urls';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { GrayHeader } from '../../components/gray-header';
import { SlideButton } from '../../components/buttons';
import { News } from '../../components/pages/index/news';
import { Pagination } from '../../components/pagination';
import {
  combinePaths,
  parseIntegerWithDefault,
} from '../../library/url-parsing';
import { dateSortDescending } from '../../library/array-utils';
import { scrollToTop } from '../../library/dom';
import { ContentfulNewsItemTypes } from '../../library/models/common';
import { NewsItem } from '../../hooks/use-news-items';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { Separator } from '../../components/separator';

export type NewsIndexPageContext = {
  language: Language;
  pageType?: ContentfulNewsItemTypes;
  posts: NewsItem[];
  pagePath: string;
};

interface NewsIndexPageProps {
  readonly pageContext: NewsIndexPageContext;
}

type PageState = {
  currentPage?: number;
};

const NewsIndexPage: FC<NewsIndexPageProps> = ({
  pageContext: { language, pageType, posts, pagePath },
}): JSX.Element => {
  const { t, i18n } = useTranslation();

  const [pageState, setPageState] = useState<PageState>({});

  useEffect(() => {
    setPageState(s => {
      return {
        ...s,
        currentPage: parseIntegerWithDefault(
          new URL(window.location.href).searchParams,
          'page',
          1
        ),
      };
    });
  }, [i18n.language, language]);

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    lang: language,
    title: t('news'),
    description: t('news-index-description'),
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
  };

  const sections = [
    {
      onClick: () => {
        if (pageType === ContentfulNewsItemTypes.ContentfulBlogPost) {
          navigate(combinePaths(language, newsIndexUrl()));
        } else {
          navigate(combinePaths(language, blogIndexUrl()));
        }
      },
      title: t('blog'),
      selected: pageType === ContentfulNewsItemTypes.ContentfulBlogPost,
    },
    {
      onClick: () => {
        if (pageType === ContentfulNewsItemTypes.ContentfulMediaPost) {
          navigate(combinePaths(language, newsIndexUrl()));
        } else {
          navigate(combinePaths(language, mediaIndexUrl()));
        }
      },
      title: t('in-the-news'),
      selected: pageType === ContentfulNewsItemTypes.ContentfulMediaPost,
    },
    {
      onClick: () => {
        if (pageType === ContentfulNewsItemTypes.ContentfulPressPost) {
          navigate(combinePaths(language, newsIndexUrl()));
        } else {
          navigate(combinePaths(language, pressIndexUrl()));
        }
      },
      title: t('press-releases'),
      selected: pageType === ContentfulNewsItemTypes.ContentfulPressPost,
    },
  ];

  const itemsPerPage = 5;
  const currentOffset = itemsPerPage * ((pageState.currentPage || 1) - 1);
  const pageLimit =
    currentOffset + itemsPerPage > posts.length
      ? posts.length
      : currentOffset + itemsPerPage;

  const currentPosts = dateSortDescending(
    posts,
    p => new Date(p.publishedDate)
  ).slice(currentOffset, pageLimit);

  return (
    <Layout metaTagProps={metaTagProps}>
      <GrayHeader>
        <h1 className="font-semibold text-2xl uppercase">
          {t('updates-and-insights')}
        </h1>
        <h2 className="font-bold text-4xl">{t('news-index-description')}</h2>
      </GrayHeader>

      <div className="col-span-full lg:col-start-2 lg:col-end-12">
        <SlideButton sections={sections} />
      </div>
      <div className="flex-col-stack-2 col-span-full">
        {/** Mobile view */}
        <div className="flex-col-stack-4 md:hidden">
          <News items={posts} />
        </div>

        {/** Desktop and Tablet view */}
        <div className="hidden md:flex flex-col-stack-4">
          <Separator />
          <News items={currentPosts} />
          <Separator />
          <div className="self-center">
            <Pagination
              totalPages={Math.ceil(posts.length / itemsPerPage)}
              currentPage={
                pageState.currentPage === undefined ? 1 : pageState.currentPage
              }
              onClick={newPage => {
                setPageState(s => {
                  const newUrl = combinePaths(
                    language,
                    (() => {
                      switch (pageType) {
                        case undefined:
                          return newsIndexUrl(newPage);
                        case ContentfulNewsItemTypes.ContentfulBlogPost: {
                          return blogIndexUrl(newPage);
                        }
                        case ContentfulNewsItemTypes.ContentfulMediaPost: {
                          return mediaIndexUrl(newPage);
                        }
                        case ContentfulNewsItemTypes.ContentfulPressPost: {
                          return pressIndexUrl(newPage);
                        }
                      }
                    })()
                  );

                  const newState = {
                    ...s,
                    currentPage: newPage,
                  };

                  history.pushState(newState, '', newUrl);
                  scrollToTop();

                  return newState;
                });
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsIndexPage;
