import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-sticky-el';
import { useBreakpoint } from '../../../hooks/use-breakpoint';
import { useCalculateHeightOnResize } from '../../../hooks/use-calculate-height-on-resize';
import { useSiteMetadata } from '../../../hooks/use-site-metadata';
import { searchIndexUrl } from '../../../library/urls';
import Layout from '../../../templates/layout/layout';
import {
  FiltersButton,
  Hits,
  InstantSearch,
  LocationSearch,
  MoreFilters,
  OwnershipSearch,
  Pagination,
  SearchBox,
  SortBy,
  Stats,
} from '../../search';
import CompanyRecord from '../../company-search-record/company-search-record';
import Disclaimer from '../../disclaimer';
import Header from './components/header';

interface FindABCorpProps {
  countries: string[];
}

const classes = {
  layout: 'relative',
  header: 'mx-6 lg:container lg:mx-auto my-5',
  searchboxArea: {
    default: 'px-12',
    sticky: 'z-10 bg-white shadow-small px-6 left-0 right-0',
  },
  searchboxWrapper: 'flex justify-center items-center gap-4 py-4',
  impactAreas: 'my-14 lg:container mx-auto',
  filtersArea: {
    default: 'bg-surface-variant-light px-6 lg:mt-18',
    sticky: 'z-10 bg-surface-variant-light shadow-small px-6 left-0 right-0',
  },
  filters: 'container mx-auto grid grid-cols-2 gap-2 lg:flex py-6',
  sortBy: 'order-1 col-span-2 lg:order-4 lg:flex lg:flex-grow lg:justify-end',
  locationSearch: 'order-2 col-span-1 lg:order-1 lg:w-40',
  ownershipSearch: 'order-3 col-span-1 lg:order-2 lg:w-40',
  moreFilters: 'order-4 col-span-2 lg:order-3',
  dataArea: 'bg-surface-variant-light flex flex-col py-8 px-6',
  dataWrapper: 'container mx-auto',
  stats: 'mt-4 lg:mt-8',
  disclaimer: 'container mx-auto p-8',
};

const FindABCorp = ({ countries }: FindABCorpProps) => {
  const isLg = useBreakpoint('lg');
  const headerRef = useRef<HTMLDivElement>(null);
  const top = useCalculateHeightOnResize(headerRef);
  const { t, i18n } = useTranslation();
  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    description: t('seo-search-description'),
    title: t('find-a-b-corp'),
    lang: i18n.language,
    noIndex: true,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: searchIndexUrl(),
  };

  const searchboxTop = top;
  const filtersTop = top + 108;

  return (
    <InstantSearch>
      <Layout
        metaTagProps={metaTagProps}
        headerRef={headerRef}
        noContainerConstraints
        className={classes.layout}
      >
        <Header className={classes.header} />

        <Sticky
          topOffset={-searchboxTop}
          wrapperClassName={classes.searchboxArea.default}
          stickyClassName={classes.searchboxArea.sticky}
          stickyStyle={{ top: searchboxTop }}
        >
          <div className={classes.searchboxWrapper}>
            <SearchBox />
            {!isLg && <FiltersButton />}
          </div>
        </Sticky>

        <Sticky
          topOffset={-filtersTop}
          wrapperClassName={classes.filtersArea.default}
          stickyClassName={classes.filtersArea.sticky}
          stickyStyle={{ top: filtersTop }}
          disabled={!isLg}
        >
          <div className={classes.filters}>
            <SortBy className={classes.sortBy} />
            <LocationSearch
              countries={countries}
              className={classes.locationSearch}
            />
            <OwnershipSearch className={classes.ownershipSearch} />
            <MoreFilters className={classes.moreFilters} />
          </div>
        </Sticky>

        <div className={classes.dataArea}>
          <div className={classes.dataWrapper}>
            <Stats className={classes.stats} />
            <Hits hitComponent={CompanyRecord} />
            <Pagination />
          </div>
        </div>

        <div className={classes.disclaimer}>
          <Disclaimer />
        </div>
      </Layout>
    </InstantSearch>
  );
};

export default FindABCorp;
