import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../attributes';
import { GalleriesItem } from '../../../hooks/use-event-gallery';
import React from 'react';
import { AccentColor, AccentedLink } from '../../links';
import { Grid } from '../../../templates/layout/layout';

function getImage(galleriesItem: GalleriesItem): JSX.Element {
  if (galleriesItem.galleryImage !== undefined) {
    return (
      <GatsbyImage
        image={galleriesItem.galleryImage}
        alt={decorativeImageAlt}
      />
    );
  }

  return (
    <StaticImage
      src="../../../../static/images/b-microphone@2x.png"
      alt={decorativeImageAlt}
    />
  );
}

const toColumns = (items: GalleriesItem[], cols: number) => {
  return [...Array(cols).keys()].map(c =>
    items.filter((_, i) => i % cols === c)
  );
};

const GalleryItem = (props: { content: GalleriesItem }): JSX.Element => {
  return (
    <>
      <div>
        <a
          aria-label="gallery image"
          href={props.content.destinationLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="pb-3">{getImage(props.content)}</div>
        </a>
        <AccentedLink
          href={props.content.destinationLink}
          accentColor={AccentColor.Yellow}
          className="font-bold"
        >
          {props.content.companyName}
        </AccentedLink>
      </div>
    </>
  );
};

const DesktopStory = (props: {
  items: GalleriesItem[];
  cols: number;
}): JSX.Element => {
  const splitedCols = toColumns(props.items, props.cols);
  return (
    <div className="col-span-full flex-row-gutter-1.5 grow-children-equally">
      {splitedCols.map((e, i) => {
        return (
          <div className="flex-col-stack-4" key={i}>
            {e.map((item, index) => {
              return <GalleryItem key={index} content={item} />;
            })}
          </div>
        );
      })}
    </div>
  );
};

const TabletStory = (props: {
  items: GalleriesItem[];
  cols: number;
}): JSX.Element => {
  const splitedCols = toColumns(props.items, props.cols);
  return (
    <div className="col-span-full flex-row-gutter-1.5 grow-children-equally">
      {splitedCols.map((e, i) => {
        return (
          <div className="flex-col-stack-4" key={i}>
            {e.map((item, index) => {
              return <GalleryItem key={index} content={item} />;
            })}
          </div>
        );
      })}
    </div>
  );
};

const MobileStory = (props: { items: GalleriesItem[] }): JSX.Element => {
  return (
    <div className="flex-col-stack-4">
      {props.items.map((e, i) => {
        return <GalleryItem key={i} content={e} />;
      })}
    </div>
  );
};

export const Gallery = (props: { items: GalleriesItem[] }): JSX.Element => {
  return (
    <>
      {/** Desktop */}
      <Grid className="hidden lg:grid col-span-full" data-testid="gallery_item">
        <div className="hidden lg:flex col-span-full grow-children-equally">
          <DesktopStory items={props.items} cols={3} />
        </div>
      </Grid>
      {/** Tablet + Mobile */}
      <Grid className="lg:hidden col-span-full" data-testid="gallery_item">
        <div className="col-span-full flex-col-stack-2">
          <div className="md:hidden flex col-span-full grow-children-equally">
            <MobileStory items={props.items} />
          </div>
          <div className="hidden md:flex col-span-full grow-children-equally">
            <TabletStory items={props.items} cols={2} />
          </div>
        </div>
      </Grid>
    </>
  );
};

const GalleryPage = (props: { items: GalleriesItem[] }): JSX.Element => {
  if (props.items.length === 0) {
    return <></>;
  }
  return (
    <>
      <Gallery items={props.items} />
    </>
  );
};

export default GalleryPage;
