import { Err, FromDecoder, JsonDecoder, Ok, Result } from 'ts.data.json';
import {
  ImpactAreaStandardsQuery,
  ImpactTopicStandardsQuery,
} from '../../__generated__/graphql-types';
import { ImpactArea, ImpactTopic } from './certified-company';

export type StandardTopic = FromDecoder<typeof StandardTopicDecoder>;
export type StandardArea = FromDecoder<typeof StandardAreaDecoder>;

export type Standard = {
  impactAreas: Array<StandardArea>;
  impactTopics: Array<StandardTopic>;
};

export const StandardTopicDecoder = JsonDecoder.objectStrict(
  {
    id: JsonDecoder.string,
    value: JsonDecoder.number,
    name: JsonDecoder.string,
  },
  'StandardTopicDecoder'
);

export const StandardAreaDecoder = JsonDecoder.objectStrict(
  {
    id: JsonDecoder.string,
    value: JsonDecoder.number,
    name: JsonDecoder.string,
    excerpt: JsonDecoder.string,
  },
  'StandardAreaDecoder'
);

export function decodeImpactAreaStandardsQuery(
  data: ImpactAreaStandardsQuery
): Result<Array<StandardArea>> {
  const decodedStandardsResult = JsonDecoder.array(
    StandardAreaDecoder,
    'DecodedImpactArea'
  ).decode(data?.allStandardsImpactAreas.nodes);

  if (!decodedStandardsResult.isOk()) {
    return new Err(decodedStandardsResult.error);
  }

  return new Ok(decodedStandardsResult.value);
}

export function decodeImpactTopicStandardsQuery(
  data: ImpactTopicStandardsQuery
): Result<Array<StandardTopic>> {
  const decodedStandardsResult = JsonDecoder.array(
    StandardTopicDecoder,
    'DecodedImpacTopic'
  ).decode(data?.allStandardsImpactTopics.nodes);

  if (!decodedStandardsResult.isOk()) {
    return new Err(decodedStandardsResult.error);
  }

  return new Ok(decodedStandardsResult.value);
}

export function getTopicName(topic: ImpactTopic, standards: Standard): string {
  const match = standards.impactTopics.find(t => t.value === topic.id);
  return match?.name || topic.name;
}

export function getAreaName(area: ImpactArea, standards: Standard): string {
  const match = standards.impactAreas.find(a => a.value === area.id);
  return match?.name || area.name;
}

export function getAreaExcerpt(area: ImpactArea, standards: Standard): string {
  const match = standards.impactAreas.find(a => a.value === area.id);
  if (!match) {
    const fakeMatch = standards.impactAreas.find(
      a => a.name === area.name && a.excerpt.length != 0
    );
    return fakeMatch?.excerpt || '';
  }

  return match.excerpt;
}
