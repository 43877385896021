import React, { FC } from 'react';
import { Language, useChangeLanguage } from '../library/i18n';

export type TranslatedPageContext = { language: Language };

export type TranslatedPageProps = {
  readonly pageContext: TranslatedPageContext;
};

type TranslatedPagePropWithChildren = {
  children?: JSX.Element | JSX.Element[];
} & TranslatedPageProps;

const Page: FC<TranslatedPagePropWithChildren> = ({
  pageContext,
  children,
}: TranslatedPagePropWithChildren): JSX.Element => {
  useChangeLanguage(pageContext.language);

  return <>{children}</>;
};

export default Page;
