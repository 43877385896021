import React, { FC } from 'react';
import { useChangeLanguage } from '../../library/i18n';
import { GenericPage as GenericPageModel } from '../../library/models/generic-page';
import Layout from '../layout/layout';
import { WrappedRichText } from '../../components/rich-text';
import { GrayHeader } from '../../components/gray-header';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { CallToAction } from '../../components/call-to-action';

export type GenericPageContext = {
  pageModel: GenericPageModel;
  pagePath: string;
};

export interface GenericPageProps {
  readonly pageContext: GenericPageContext;
}

const GenericPage: FC<GenericPageProps> = ({
  pageContext: { pageModel, pagePath },
}): JSX.Element => {
  useChangeLanguage(pageModel.node_locale);

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    lang: pageModel.node_locale,
    title: pageModel.seoTitle,
    description: pageModel.seoDescription,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
  };

  const callToAction = pageModel.callToAction ? (
    <CallToAction content={pageModel.callToAction} />
  ) : (
    <></>
  );

  return (
    <Layout metaTagProps={metaTagProps}>
      <GrayHeader>
        <h1>{pageModel.header}</h1>
        <div className="font-bold text-2xl">{pageModel.subheader}</div>
      </GrayHeader>
      <WrappedRichText
        className="flex-col-stack-2 col-span-full"
        richText={pageModel.body}
      />
      {callToAction}
    </Layout>
  );
};

export default GenericPage;
