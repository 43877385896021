import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '../../hooks/use-breakpoint';
import { useLockScroll } from '../../hooks/use-lock-scroll';
import FunnelSimpleIcon from '../../fiber/icons/outline/office-editing/funnel-simple';
import XIcon from '../../fiber/icons/outline/math-finance/x';
import CaretDownIcon from '../../fiber/icons/outline/arrows/caret-down';
import Checkbox from '../../fiber/components/controls/checkbox';
import Pill from '../../fiber/components/pill/pill';
import Link from '../../fiber/components/link/link';
import Button from '../../fiber/components/button/button';
import SectionDivider from '../../fiber/components/section-divider/section-divider';
import FullScreenModal from '../modal/full-screen-modal';
import PillsList from '../pills-list/pills-list';
import { RefinementItem } from './constants';
import { useControlRefinementList } from './refinement-list';
import { useStats } from 'react-instantsearch';
import { useScrollToFilters } from './scroll';
import { sortByLabel } from './helpers';

interface MoreFiltersProps {
  className?: string;
}

const Subheader = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => (
  <div className={clsx('font-sans font-medium text-xl text-black', className)}>
    {children}
  </div>
);

const Description = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => (
  <div className={clsx('font-sans text-fiber-neutral-500', className)}>
    {children}
  </div>
);

const SIZES = ['0', '1-9', '10-49', '50-249', '250-999', '1000+'] as const;
type Size = (typeof SIZES)[number];
const SIZE_LABEL: Record<Size, string> = {
  '0': '0',
  '1-9': '1 - 9',
  '10-49': '10 - 49',
  '50-249': '50 - 249',
  '250-999': '250 - 999',
  '1000+': '1000+',
};

const classes = {
  root: {
    default:
      'outline-none flex flex-row justify-center items-center px-5 py-3.5 gap-2 bg-fiber-masterbrand-white border border-fiber-neutral-300 rounded-[48px]',
    hover: 'hover:border-fiber-grey-900',
    focus: 'focus:border-fiber-grey-900',
  },
  buttonIcon: 'w-5',
  appliedFiltersPill: '!w-5 h-5 font-medium text-xs text-white bg-black',
  popup:
    'w-full h-full max-h-full lg:max-w-[824px] lg:h-auto lg:max-h-[calc(100%-80px)] bg-white lg:rounded-3xl flex flex-col',
  headerArea: 'p-6 pr-[30px] flex flex-row justify-between items-center',
  header: 'font-medium text-2xl text-fiber-masterbrand-black',
  closeIcon: 'cursor-pointer w-6',
  filtersArea: 'overflow-scroll flex-grow',
  filterWrapper: 'p-6',
  description: 'mt-0.5',
  pillsList: 'mt-6',
  checkboxesList: 'my-6 grid lg:grid-cols-2 gap-6',
  footerArea: 'p-6 flex justify-between items-center lg:justify-end gap-10',
};

export const MoreFilters = ({ className }: MoreFiltersProps) => {
  const { t } = useTranslation();
  const isLg = useBreakpoint('lg');
  const [open, setOpen] = useState(false);
  const { nbHits } = useStats();
  const scrollToFilters = useScrollToFilters();
  const { lockScroll, unlockScroll } = useLockScroll('#___gatsby');
  const {
    items: sizeItems,
    refine: refineSize,
    clearAll: clearAllSizes,
  } = useControlRefinementList(RefinementItem.size);
  const {
    items: industryItems,
    refine: refineIndustry,
    clearAll: clearAllIndustries,
  } = useControlRefinementList(RefinementItem.industry);

  const selectedSizes = new Set(
    sizeItems.filter(e => e.isRefined).map(e => e.value)
  );
  const sizeOptions = SIZES.map(value => ({
    value,
    label: SIZE_LABEL[value],
    selected: selectedSizes.has(value),
  }));
  const onToggleSize = refineSize;
  const onClearAllSizes = clearAllSizes;

  const selectedIndustries = new Set(
    industryItems.filter(e => e.isRefined).map(e => e.value)
  );
  const onToggleIndustry = refineIndustry;

  const [industryFiltersShown, setIndustryFiltersShown] = useState(6);
  const onMoreIndustryFilters = () => setIndustryFiltersShown(prev => prev + 6);

  const appliedFiltersCount = selectedSizes.size + selectedIndustries.size;

  const onClearAll = () => {
    clearAllSizes();
    clearAllIndustries();
  };

  const onOpen = () => {
    if (isLg) {
      scrollToFilters();
      lockScroll();
    }
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    unlockScroll();
    onClearAll();
  };

  const onApply = () => {
    setOpen(false);
    unlockScroll();
  };

  return (
    <>
      <button
        className={clsx(
          classes.root.default,
          classes.root.hover,
          classes.root.focus,
          className
        )}
        onClick={onOpen}
      >
        <span className={classes.buttonIcon}>
          <FunnelSimpleIcon />
        </span>
        {t('more-filters')}
        {appliedFiltersCount > 0 && (
          <Pill
            label={`${appliedFiltersCount}`}
            className={classes.appliedFiltersPill}
          />
        )}
      </button>

      <FullScreenModal
        isOpen={open}
        onRequestClose={onClose}
        contentClassName={classes.popup}
      >
        <div className={classes.headerArea}>
          <span className={classes.header}>{t('more-filters')}</span>
          <span className={classes.closeIcon} onClick={onClose}>
            <XIcon />
          </span>
        </div>
        <SectionDivider />

        <div className={classes.filtersArea}>
          <div className={classes.filterWrapper}>
            <Subheader>{t('company-more-filters-size-title')}</Subheader>
            <Description className={classes.description}>
              {t('company-more-filters-size-description')}
            </Description>
            <PillsList
              options={sizeOptions}
              onSelect={onToggleSize}
              defaultLabel={t('all')}
              onDefault={onClearAllSizes}
              className={classes.pillsList}
            />
          </div>

          {industryItems.length > 0 && (
            <div className={classes.filterWrapper}>
              <Subheader>{t('industry')}</Subheader>
              <div className={classes.checkboxesList}>
                {industryItems
                  .sort(sortByLabel)
                  .slice(0, industryFiltersShown)
                  .map(({ value, label, isRefined }) => (
                    <Checkbox
                      key={value}
                      size="md"
                      label={label}
                      checked={isRefined}
                      onChange={() => onToggleIndustry(value)}
                    />
                  ))}
              </div>
              {industryItems.length > industryFiltersShown && (
                <Link
                  label={t('more-industries')}
                  onClick={onMoreIndustryFilters}
                  icon={<CaretDownIcon />}
                />
              )}
            </div>
          )}
        </div>

        <SectionDivider />
        <div className={classes.footerArea}>
          <Link label={t('clear-all')} onClick={onClearAll} />
          <Button
            type="grey"
            size="lg"
            label={t(
              nbHits > 1
                ? 'show-search-results_other'
                : 'show-search-results_one',
              {
                count: nbHits,
              }
            )}
            disabled={nbHits === 0}
            onClick={onApply}
          />
        </div>
      </FullScreenModal>
    </>
  );
};
