exports.components = {
  "component---src-templates-certified-company-tsx": () => import("./../../../src/templates/certified-company.tsx" /* webpackChunkName: "component---src-templates-certified-company-tsx" */),
  "component---src-templates-find-a-b-corp-tsx": () => import("./../../../src/templates/find-a-b-corp.tsx" /* webpackChunkName: "component---src-templates-find-a-b-corp-tsx" */),
  "component---src-templates-legal-index-tsx": () => import("./../../../src/templates/legal/index.tsx" /* webpackChunkName: "component---src-templates-legal-index-tsx" */),
  "component---src-templates-legal-requirement-tsx": () => import("./../../../src/templates/legal/requirement.tsx" /* webpackChunkName: "component---src-templates-legal-requirement-tsx" */),
  "component---src-templates-news-blog-post-tsx": () => import("./../../../src/templates/news/blog-post.tsx" /* webpackChunkName: "component---src-templates-news-blog-post-tsx" */),
  "component---src-templates-news-news-index-tsx": () => import("./../../../src/templates/news/news-index.tsx" /* webpackChunkName: "component---src-templates-news-news-index-tsx" */),
  "component---src-templates-news-press-post-tsx": () => import("./../../../src/templates/news/press-post.tsx" /* webpackChunkName: "component---src-templates-news-press-post-tsx" */),
  "component---src-templates-overview-overview-tsx": () => import("./../../../src/templates/overview/overview.tsx" /* webpackChunkName: "component---src-templates-overview-overview-tsx" */),
  "component---src-templates-pages-event-tsx": () => import("./../../../src/templates/pages/event.tsx" /* webpackChunkName: "component---src-templates-pages-event-tsx" */),
  "component---src-templates-pages-generic-tsx": () => import("./../../../src/templates/pages/generic.tsx" /* webpackChunkName: "component---src-templates-pages-generic-tsx" */),
  "component---src-templates-pages-partners-tsx": () => import("./../../../src/templates/pages/partners.tsx" /* webpackChunkName: "component---src-templates-pages-partners-tsx" */),
  "component---src-templates-pages-standard-tsx": () => import("./../../../src/templates/pages/standard.tsx" /* webpackChunkName: "component---src-templates-pages-standard-tsx" */),
  "component---src-templates-pages-team-tsx": () => import("./../../../src/templates/pages/team.tsx" /* webpackChunkName: "component---src-templates-pages-team-tsx" */),
  "component---src-templates-pages-timeline-tsx": () => import("./../../../src/templates/pages/timeline.tsx" /* webpackChunkName: "component---src-templates-pages-timeline-tsx" */),
  "component---src-templates-pending-company-tsx": () => import("./../../../src/templates/pending-company.tsx" /* webpackChunkName: "component---src-templates-pending-company-tsx" */),
  "component---src-templates-translated-page-missing-language-tsx": () => import("./../../../src/templates/translated-page-missing-language.tsx" /* webpackChunkName: "component---src-templates-translated-page-missing-language-tsx" */),
  "component---src-translated-pages-404-index-tsx": () => import("./../../../src/translated-pages/404/index.tsx" /* webpackChunkName: "component---src-translated-pages-404-index-tsx" */),
  "component---src-translated-pages-index-tsx": () => import("./../../../src/translated-pages/index.tsx" /* webpackChunkName: "component---src-translated-pages-index-tsx" */)
}

