import React from 'react';
import { useTranslation } from 'react-i18next';

export const DateWithLongFormat = ({
  d,
  className,
}: {
  d: Date;
  className?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const f = t('date-long-format', {
    val: d,
    formatParams: { val: { year: 'numeric', month: 'long', day: 'numeric' } },
  });

  return (
    <span className={className} suppressHydrationWarning>
      {f}
    </span>
  );
};

export const DateWithMonthFormat = ({
  d,
  className,
}: {
  d: Date;
  className?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const f = t('date-month-format', {
    val: d,
    formatParams: { val: { year: 'numeric', month: 'long' } },
  });

  return (
    <span className={className} suppressHydrationWarning>
      {f}
    </span>
  );
};

export const DateWithLongTimeFormat = ({
  d,
  timezone,
  className,
}: {
  d: Date;
  timezone?: string;
  className?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  const f = t('date-time-long-format', {
    val: d,
    formatParams: {
      val: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone ? timezone : 'America/New_York',
      },
    },
  });

  return (
    <span className={className} suppressHydrationWarning>
      {f}
    </span>
  );
};
