import { useMemo, useCallback } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useLockScroll = (selector: string) => {
  const targetElement = useMemo(
    () => document.querySelector(selector),
    [selector]
  );

  const lockScroll = useCallback(() => {
    if (!targetElement) return;
    disableBodyScroll(targetElement);
  }, [targetElement]);

  const unlockScroll = useCallback(() => {
    if (!targetElement) return;
    enableBodyScroll(targetElement);
  }, [targetElement]);

  return { lockScroll, unlockScroll };
};
