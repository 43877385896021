import React from 'react';
import Pill from '../../fiber/components/pill/pill';

const classes = {
  label: 'text-fiber-neutral-500 w-[104px]',
  pill: 'mt-1 text-fiber-neutral-900 bg-surface-variant-light',
};

interface CertifiedSinceProps {
  label: string;
  initialCertificationDate: JSX.Element;
}

const CertifiedSince = ({
  label,
  initialCertificationDate,
}: CertifiedSinceProps) => (
  <div>
    {initialCertificationDate && (
      <>
        <div className={classes.label}>{label}</div>
        <Pill label={initialCertificationDate} className={classes.pill} />
      </>
    )}
  </div>
);

export default CertifiedSince;
