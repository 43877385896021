import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  useDebounce,
  useRafLoop,
  useWindowScroll,
  useWindowSize,
} from 'react-use';
import FullScreenModal from './full-screen-modal';

interface PositionedContentModalProps {
  onRequestClose?: () => void;
  minHeight: number;
  children?: React.ReactNode;
}

const classes = {
  absolute: 'absolute',
  centered: 'flex justify-center items-center',
};

const PositionedContentModal = ({
  onRequestClose,
  minHeight,
  children,
}: PositionedContentModalProps) => {
  const stubRef = useRef<HTMLDivElement>(null);
  const [isAbsolute, setIsAbsolute] = useState(false);
  const [stubCoordinates, setStubCoordinates] = useState(() => ({
    top: 0,
    left: 0,
  }));
  const [loopStop, loopStart, isActive] = useRafLoop(() => {
    if (minHeight > height) {
      setIsAbsolute(false);
      return;
    }

    const stubRect = stubRef.current?.getBoundingClientRect();
    if (!stubRect) return;

    const { top, left } = stubRect;
    setStubCoordinates({ top, left });
    setIsAbsolute(true);
  }, false);

  const { y } = useWindowScroll();
  const { width, height } = useWindowSize();
  useEffect(() => {
    if (isActive()) return;
    loopStart();
  }, [y, width, height, isActive, loopStart]);
  useDebounce(
    () => {
      if (!isActive()) return;
      loopStop();
    },
    250,
    [y, width, height, isActive, loopStop]
  );

  return (
    <>
      <div ref={stubRef} />
      <FullScreenModal isOpen onRequestClose={onRequestClose}>
        <div
          className={clsx({
            [classes.absolute]: isAbsolute,
            [classes.centered]: !isAbsolute,
          })}
          style={stubCoordinates}
        >
          {children}
        </div>
      </FullScreenModal>
    </>
  );
};

export default PositionedContentModal;
