import { FromDecoder, JsonDecoder } from 'ts.data.json';
import { RichTextDecoder } from '../common';

export const AbstractPageDecoder = JsonDecoder.object(
  {
    title: JsonDecoder.string,
    body: JsonDecoder.optional(RichTextDecoder),
    seoTitle: JsonDecoder.string,
    seoDescription: JsonDecoder.objectStrict(
      { seoDescription: JsonDecoder.string },
      'seoDescription'
    ).map(s => s.seoDescription),
  },
  'Abstract page'
);

export type AbstractPageModel = FromDecoder<typeof AbstractPageDecoder>;
