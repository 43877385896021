import { useRefinementList, useClearRefinements } from 'react-instantsearch';
import { RefinementItem } from './constants';

export const useControlRefinementList = (attribute: RefinementItem) => {
  const { items, refine } = useRefinementList({ attribute, limit: 500 });
  const { refine: clearAll } = useClearRefinements({
    includedAttributes: [attribute],
  });

  return {
    items,
    refine,
    clearAll,
  };
};
