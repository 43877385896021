import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { allSupportedLanguages, Language } from '../../library/i18n';
import { getSrc, getImage, ImageDataLike } from 'gatsby-plugin-image';
import ogDefault from '../../../static/images/og-blab.png';
import { isString } from '../../library/string-utils';
import { combinePaths } from '../../library/url-parsing';

export interface MetaTagProps {
  description: string;
  title: string;
  lang: Language;
  viewport?: string;
  keywords?: string[];
  siteUrl: string;
  canonicalUrl?: string;
  pageContentfulId?: string;
  supportedLanguages?: string[];
  pathWithoutLanguage: string;
  ogImage?: ImageDataLike | string;
  noIndex?: boolean;
  redirects?: boolean;
}

const MetaTags: FC<MetaTagProps> = ({
  description,
  title,
  viewport = 'width=device-width, initial-scale=1',
  keywords,
  lang,
  siteUrl,
  canonicalUrl,
  supportedLanguages = allSupportedLanguages(),
  pathWithoutLanguage,
  ogImage,
  noIndex = false,
}: MetaTagProps): JSX.Element => {
  // Default OG image with height + width
  let ogImageContentSrc: string = ogDefault;
  let ogHeight = 630;
  let ogWidth = 1200;

  if (ogImage) {
    if (isString(ogImage)) {
      ogImageContentSrc = ogImage;
    } else {
      const ogCusomImageSrc = getSrc(ogImage);
      const ogCustomImageData = getImage(ogImage);

      if (ogCusomImageSrc !== undefined && ogCustomImageData !== undefined) {
        ogImageContentSrc = ogCusomImageSrc;
        if (ogCustomImageData.layout === 'fixed') {
          // Custom OG image with fixed height + width
          ogHeight = ogCustomImageData.height;
          ogWidth = ogCustomImageData.width;
        } else {
          // Custom OG image with height + width calculated based on recommended OG dimensions
          ogHeight = ogCustomImageData.height * 1200;
          ogWidth = ogCustomImageData.width * 1200;
        }
      }
    }
  }

  if (!ogImageContentSrc.startsWith('http')) {
    ogImageContentSrc = combinePaths(siteUrl, ogImageContentSrc);
  }

  return (
    <Helmet htmlAttributes={{ lang: lang }}>
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="description" content={description} />
      {keywords && (
        <meta
          name="keywords"
          data-react-helmet="true"
          content={`${keywords ? keywords.join(', ') : ''}`}
        />
      )}
      <title>{title}</title>
      <meta name="viewport" content={viewport} />
      <meta httpEquiv="content-language" content={lang} />

      <meta property="og:image" content={ogImageContentSrc} />
      {ogHeight && (
        <meta property="og:image:height" content={`${ogHeight}px`} />
      )}
      {ogWidth && <meta property="og:image:width" content={`${ogWidth}px`} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Google site verification Tag */}
      <meta
        name="google-site-verification"
        content="43JWLXuTtICT1Da59DoMq-dqUoLrNbhJK37yJ3SngMw"
      />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageContentSrc} />

      <link
        rel="canonical"
        href={
          canonicalUrl
            ? canonicalUrl
            : combinePaths(siteUrl, lang, pathWithoutLanguage)
        }
      />
      {supportedLanguages
        .filter(l => l !== lang)
        .map(l => {
          return (
            <link
              rel="alternate"
              href={combinePaths(siteUrl, l, pathWithoutLanguage)}
              hrefLang={l}
              key={l}
            />
          );
        })}
    </Helmet>
  );
};

export default MetaTags;
