import React, { ReactNode, HTMLAttributes } from 'react';
import merge from 'lodash/merge';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  Block,
  Inline,
  BLOCKS,
  INLINES,
  MARKS,
} from '@contentful/rich-text-types';
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { decorativeImageAlt } from './attributes';
import { AccentedLink, AccentColor } from '../components/links';
import { createRelatedContentUrl } from '../library/urls';
import { isString } from '../library/string-utils';

interface BlockProps {
  as?: keyof React.ReactHTML;
  className?: string | null;
}

type BlockType =
  | BLOCKS.PARAGRAPH
  | BLOCKS.HEADING_1
  | BLOCKS.HEADING_2
  | BLOCKS.HEADING_3
  | BLOCKS.HEADING_4
  | BLOCKS.HEADING_5
  | BLOCKS.HEADING_6
  | BLOCKS.OL_LIST
  | BLOCKS.UL_LIST
  | BLOCKS.HR;

const defaultOptions: Record<BlockType, Required<BlockProps>> = {
  [BLOCKS.PARAGRAPH]: { as: 'p', className: null },
  // Avoid header tags which cause a11y and SEO issues.
  [BLOCKS.HEADING_1]: { as: 'div', className: 'text-bold text-4xl' },
  [BLOCKS.HEADING_2]: { as: 'div', className: 'text-bold text-3xl' },
  [BLOCKS.HEADING_3]: { as: 'div', className: 'text-bold text-2xl' },
  [BLOCKS.HEADING_4]: { as: 'div', className: 'text-bold text-xl' },
  [BLOCKS.HEADING_5]: { as: 'div', className: 'text-bold text-lg' },
  [BLOCKS.HEADING_6]: { as: 'div', className: 'text-bold text-md' },
  [BLOCKS.OL_LIST]: { as: 'ol', className: 'px-12 list-decimal' },
  [BLOCKS.UL_LIST]: { as: 'ul', className: 'px-12 list-disc' },
  [BLOCKS.HR]: { as: 'div', className: 'my-4' },
};

type AdditionalProps = Partial<Record<BlockType, BlockProps>> & {
  // We use direct names for MARKS because they represented as strings
  // in @contentful/rich-text-types v14.1.2.
  // https://github.com/contentful/rich-text/pull/136
  bold?: { className?: string };
  [INLINES.HYPERLINK]?: { className?: string; accentColor?: AccentColor };
};

const configureBlockRenderer =
  (props: AdditionalProps) => (nodeType: BlockType) => {
    const options = merge({}, defaultOptions[nodeType], props[nodeType] || {});
    const { as: type, ...rest } = options;

    return function block(_: Block | Inline, children: ReactNode) {
      return React.createElement(type, rest, children);
    };
  };

function makeBold(additionalProps: AdditionalProps['bold'] = {}) {
  return function bold(text: ReactNode) {
    return <b className={additionalProps.className || ''}>{text}</b>;
  };
}

function underline(text: ReactNode) {
  return <span className="font-serif blue-highlight">{text}</span>;
}

function makeSimpleHyperlink(
  additionalProps: AdditionalProps[INLINES.HYPERLINK] = {}
) {
  return function simpleHyperlink(
    node: Block | Inline,
    children: ReactNode
  ): JSX.Element {
    if (!isString(node.data.uri)) {
      throw new Error(`missing uri for hyperlink : ${JSON.stringify(node)}`);
    }

    if (
      node.data.uri.includes('player.vimeo.com/video') ||
      node.data.uri.includes('youtube.com/embed/')
    ) {
      return (
        <span className="block w-4/5 max-w-screen-sm h-full m-auto">
          <span className="block embed-video">
            <iframe
              height="2"
              width="2"
              src={node.data.uri}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            />
          </span>
        </span>
      );
    }

    return (
      <AccentedLink
        href={node.data.uri.trim()}
        accentColor={additionalProps.accentColor || AccentColor.Yellow}
        className={additionalProps.className || 'font-serif'}
      >
        {children}
      </AccentedLink>
    );
  };
}

function assetHyperlink(
  node: Block | Inline,
  children: ReactNode
): JSX.Element {
  return (
    <AccentedLink
      href={node.data.target.file.url}
      accentColor={AccentColor.Yellow}
      className="font-serif"
    >
      {children}
    </AccentedLink>
  );
}

function entryHyperlink(
  node: Block | Inline,
  children: ReactNode
): JSX.Element {
  if (!node.data.target) {
    throw new Error(`missing entry target : ${JSON.stringify(node)}`);
  }

  return (
    <AccentedLink
      href={createRelatedContentUrl(node.data.target)}
      accentColor={AccentColor.Yellow}
      className="font-serif"
    >
      {children}
    </AccentedLink>
  );
}

function embeddedAsset(node: Block | Inline): JSX.Element {
  if (node.data.target.gatsbyImageData) {
    /**
     * The translation of image assets content is not currently supported (descriptions, etc.).
     * It is also possible that an image description contains no meaningful information for a screenreader.
     * Using an empty image alt tag for now but this should be discussed in the future.
     *
     * const altTag = node.data.target.description;
     */

    return (
      <GatsbyImage
        alt={decorativeImageAlt}
        image={node.data.target.gatsbyImageData as IGatsbyImageData}
        objectFit="contain"
      />
    );
  }

  if (node.data.target.file) {
    if (node.data.target.file.contentType === 'video/mp4') {
      return (
        <video controls>
          <source
            src={node.data.target.file.url}
            type={node.data.target.file.contentType}
          />
        </video>
      );
    }

    if (
      node.data.target.file.contentType === 'audio/mp4' ||
      node.data.target.file.contentType === 'audio/mpeg'
    ) {
      return (
        <audio controls>
          <source
            src={node.data.target.file.url}
            type={node.data.target.file.contentType}
          />
        </audio>
      );
    }

    if (node.data.target.file.contentType === 'application/pdf') {
      return (
        <object
          type={node.data.target.file.contentType}
          data={node.data.target.file.url}
          width="100%"
          height="1125px"
        />
      );
    }
  }

  throw new Error(`unknown embedded asset: ${JSON.stringify(node)}`);
}

const makeRenderOptions = (additionalProps: AdditionalProps = {}): Options => {
  const configuredBlockRenderer = configureBlockRenderer(additionalProps);

  return {
    renderMark: {
      [MARKS.BOLD]: makeBold(additionalProps.bold),
      [MARKS.UNDERLINE]: underline,
    },
    renderNode: {
      [INLINES.HYPERLINK]: makeSimpleHyperlink(
        additionalProps[INLINES.HYPERLINK]
      ),
      [INLINES.ASSET_HYPERLINK]: assetHyperlink,
      [INLINES.ENTRY_HYPERLINK]: entryHyperlink,
      [BLOCKS.EMBEDDED_ASSET]: embeddedAsset,
      [BLOCKS.PARAGRAPH]: configuredBlockRenderer(BLOCKS.PARAGRAPH),
      [BLOCKS.UL_LIST]: configuredBlockRenderer(BLOCKS.UL_LIST),
      [BLOCKS.OL_LIST]: configuredBlockRenderer(BLOCKS.OL_LIST),
      [BLOCKS.HEADING_1]: configuredBlockRenderer(BLOCKS.HEADING_1),
      [BLOCKS.HEADING_2]: configuredBlockRenderer(BLOCKS.HEADING_2),
      [BLOCKS.HEADING_3]: configuredBlockRenderer(BLOCKS.HEADING_3),
      [BLOCKS.HEADING_4]: configuredBlockRenderer(BLOCKS.HEADING_4),
      [BLOCKS.HEADING_5]: configuredBlockRenderer(BLOCKS.HEADING_5),
      [BLOCKS.HEADING_6]: configuredBlockRenderer(BLOCKS.HEADING_6),
      [BLOCKS.HR]: configuredBlockRenderer(BLOCKS.HR),
    },
  };
};

export function WrappedRichText(
  props: HTMLAttributes<HTMLDivElement> & {
    richText: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    additionalProps?: AdditionalProps;
  }
): JSX.Element {
  const { richText, additionalProps, ...filteredProps } = props;

  return (
    <div {...filteredProps}>
      {renderRichText(richText, makeRenderOptions(additionalProps))}
    </div>
  );
}
