import { useEffect } from 'react';

export function useHideFreshworks(isOpen: boolean) {
  useEffect(() => {
    if (!isOpen) return;

    const nodes = document.querySelectorAll<
      Element & { style: CSSStyleDeclaration }
    >('#freshworks-container #launcher-frame');

    nodes.forEach(node => node.style.setProperty('height', '0'));

    return () => {
      nodes.forEach(node => node.style.setProperty('height', '56px'));
    };
  }, [isOpen]);
}
