import React from 'react';
import i18n from 'i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { useChangeLanguage } from '../../library/i18n';
import { TimelinePageModel } from '../../library/models/timeline-page';
import {
  AbstractPageModel,
  ListItemModel,
  ListModel,
  UrlModel,
} from '../../library/models/primitives';
import { Button, BlabButtonStyle } from '../../components/buttons';
import { decorativeImageAlt } from '../../components/attributes';
import { AccentedLink, AccentColor } from '../../components/links';
import { WrappedRichText } from '../../components/rich-text';
import Layout, { Grid } from '../layout/layout';

export type TimelinePageContext = {
  pageModel: TimelinePageModel;
  pagePath: string;
};

export interface TimelinePageProps {
  readonly pageContext: TimelinePageContext;
}

const enrichListItems = (items: ListItemModel[]) =>
  items.map((item, i) => ({
    ...item,
    order: `${i + 1}`.padStart(2, '0'),
    isEven: i % 2 === 0,
    isLast: i === items.length - 1,
  }));

function orderCircle(order: string): JSX.Element {
  return (
    <div
      className="flex justify-center items-center bg-white rounded-full text-2xl text-blue shadow-lg"
      style={{ width: 75, height: 75 }}
    >
      {order}
    </div>
  );
}

function verticalLine(): JSX.Element {
  return (
    <div className="border-r border-black h-full" style={{ width: 37.5 }}></div>
  );
}

function ListItemText({ title, description, link }: ListItemModel) {
  return (
    <>
      <h3>{title}</h3>
      <WrappedRichText
        className="font-serif flex-col-stack-4"
        richText={description}
        additionalProps={{ bold: { className: 'font-serif font-bold' } }}
      />
      {link && (
        <AccentedLink
          href={link.url}
          accentColor={AccentColor.Yellow}
          className="font-bold"
        >
          {link.title}
        </AccentedLink>
      )}
    </>
  );
}

function Header({ title, body }: AbstractPageModel): JSX.Element {
  return (
    <>
      <h1 data-testid="hero-header">{title}</h1>
      {body && (
        <WrappedRichText
          className="font-serif space-y-4"
          richText={body}
          additionalProps={{ paragraph: { className: 'font-serif' } }}
        />
      )}
    </>
  );
}

function ListFooter({ epilogue }: Pick<ListModel, 'epilogue'>) {
  return (
    <div className="flex flex-col space-y-8 py-8">
      <WrappedRichText
        className="text-center text-2xl space-y-8"
        richText={epilogue}
        additionalProps={{
          paragraph: { className: 'font-sans' },
          hyperlink: {
            className: 'font-bold',
            accentColor: AccentColor.Blue,
          },
        }}
      />
    </div>
  );
}

function DesktopList({ title, items, epilogue }: ListModel) {
  return (
    <Grid className="col-span-full bg-gray-light rounded-3xl pb-10">
      <div className="col-span-full flex justify-center py-10">
        <h2>{title}</h2>
      </div>

      {enrichListItems(items).map(({ order, isEven, isLast, ...item }) => {
        const image = item.image ? (
          <GatsbyImage image={item.image} alt={decorativeImageAlt} />
        ) : null;

        return (
          <Grid key={order} className="col-span-full">
            <div className="col-start-2 col-end-6 space-y-8">
              {isEven ? image : <ListItemText {...item} />}
            </div>

            <div>
              {orderCircle(order)}
              {!isLast && verticalLine()}
            </div>

            <div className="col-start-7 col-end-12 flex flex-col space-y-8">
              {isEven ? <ListItemText {...item} /> : image}
            </div>
          </Grid>
        );
      })}

      <div className="col-start-2 col-end-12 flex flex-col space-y-8 text-2xl">
        <ListFooter {...{ epilogue }} />
      </div>
    </Grid>
  );
}

function MobileList({ title, items, epilogue }: ListModel) {
  return (
    <>
      <div className="w-full col-span-full bg-gray-light rounded-3xl pb-10">
        <div className="flex w-full justify-center py-10">
          <h2>{title}</h2>
        </div>

        <div className="flex flex-col px-10 space-y-10">
          {enrichListItems(items).map(({ order, isLast, ...item }) => (
            <div key={order} className="flex w-full space-x-10">
              <div>
                {orderCircle(order)}
                {!isLast && verticalLine()}
              </div>
              <div className="space-y-8">
                <ListItemText {...item} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col">
        <ListFooter {...{ epilogue }} />
      </div>
    </>
  );
}

function CTAButton({ url, title }: UrlModel) {
  return (
    <Button href={url} blabType={BlabButtonStyle.LargeFilledButton}>
      {title}
    </Button>
  );
}

function LoginUrl({ url, title }: UrlModel) {
  return (
    <AccentedLink
      href={url}
      accentColor={AccentColor.Yellow}
      className="font-serif"
    >
      {title}
    </AccentedLink>
  );
}

const TimelinePage = ({
  pageContext: { pageModel, pagePath },
}: TimelinePageProps): JSX.Element => {
  useChangeLanguage(pageModel.node_locale);

  const { page, callToActionButton, loginUrl, image, timeline } = pageModel;

  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    description: page.seoDescription,
    title: page.seoTitle,
    lang: i18n.language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: pagePath,
    ogImage: image,
  };

  return (
    <Layout metaTagProps={metaTagProps}>
      {/* Desktop */}
      <div className="hidden lg:flex flex-col col-span-full">
        <div className="flex space-x-24">
          <div className="flex flex-col w-1/2 space-y-8">
            <Header {...page} />
            <div>
              {callToActionButton && <CTAButton {...callToActionButton} />}
              <div className="p-5">
                {loginUrl && <LoginUrl {...loginUrl} />}
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <GatsbyImage image={image} alt={decorativeImageAlt} />
          </div>
        </div>
        <DesktopList {...timeline} />
      </div>

      {/* Tablet + Mobile */}
      <div className="lg:hidden col-span-full">
        <div className="flex flex-col justify-center space-y-8 text-center py-10">
          <Header {...page} />
          {callToActionButton && <CTAButton {...callToActionButton} />}
          <div>{loginUrl && <LoginUrl {...loginUrl} />}</div>
        </div>

        <MobileList {...timeline} />
      </div>
    </Layout>
  );
};

export default TimelinePage;
