import React from 'react';
import { useTranslation } from 'react-i18next';

export const BiaLogo = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {/* Inline SVG to allow overriding text color */}
      <svg
        viewBox="0 0 302.32 353.58"
        className="h-12 flex flex-row"
        data-testid="bia-logo"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{t('b-lab-logo-alt')}</title>
        <g fill="#231f20">
          <path d="m87.9 64.81h62.58c15.23 0 35.28 0 45.67 6.28a40.86 40.86 0 0 1 20.29 36c0 18.34-9.65 32.09-25.6 38.6v.49c21.27 4.34 32.86 20.27 32.86 41.51 0 25.33-18.13 49.47-52.44 49.47h-83.36zm22.94 74.09h45.94c26.08 0 36.72-9.41 36.72-27.52 0-23.89-16.9-27.26-36.72-27.26h-45.94zm0 78.91h57.53c19.8 0 32.38-12.07 32.38-30.88 0-22.45-18.13-28.73-37.47-28.73h-52.44z" />
          <path d="m282.39 151c0 72.38-58.75 131.06-131.24 131.06a131.06 131.06 0 1 1 0-262.11c72.49 0 131.24 58.67 131.24 131m-131.24-150.95c-83.47 0-151.15 67.59-151.15 151s67.68 151 151.15 151 151.17-67.6 151.17-151-67.68-151-151.17-151" />
          <path d="m.31 333.77h301.7v19.81h-301.7z" />
          <path d="m293.75 325.73a7.34 7.34 0 1 1 7.35-7.34 7.1 7.1 0 0 1 -7.35 7.34zm0-13a5.64 5.64 0 0 0 0 11.25 5.65 5.65 0 0 0 0-11.27zm-1.23 9.56h-1.68v-7.89h2.94c2 0 3.24.58 3.24 2.38a1.85 1.85 0 0 1 -1.9 2.06l2 3.45h-2l-1.8-3.29h-.8zm1.5-4.65c.75 0 1.2-.16 1.2-1s-.87-.87-1.51-.87h-1.19v1.9z" />
        </g>
      </svg>
    </>
  );
};
export enum BiaGlobalLogoSize {
  Mobile = 1,
  Desktop = 2,
}
function logoSizeToClass(size: BiaGlobalLogoSize): string {
  switch (size) {
    case BiaGlobalLogoSize.Mobile:
      return 'h-48';
    case BiaGlobalLogoSize.Desktop:
      return 'h-24';
  }
}
export const BiaGlobalLogo = (props: {
  size: BiaGlobalLogoSize;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {/* Inline SVG to allow overriding text color */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 180 288"
        className={logoSizeToClass(props.size)}
        data-testid="bia-global-logo"
      >
        <title>{t('b-lab-logo-alt')}</title>
        <g fill="#231f20">
          <path d="m35.52 172.8v47.37h28.33v10.75h-41.11v-58.12z" />
          <path d="m69.3 201.78a13.43 13.43 0 0 1 2-6.76 13.72 13.72 0 0 1 4.56-4.31 19.69 19.69 0 0 1 6.22-2.32 35.57 35.57 0 0 1 7-.7 48.51 48.51 0 0 1 6.43.45 19.94 19.94 0 0 1 5.94 1.75 11.73 11.73 0 0 1 4.4 3.62 10.14 10.14 0 0 1 1.71 6.15v21.9a43.37 43.37 0 0 0 .33 5.45 9.81 9.81 0 0 0 1.13 3.91h-11.72a19 19 0 0 1 -.53-2 17.76 17.76 0 0 1 -.28-2.07 15.12 15.12 0 0 1 -6.51 4 26.5 26.5 0 0 1 -7.65 1.15 20.64 20.64 0 0 1 -5.62-.74 12.87 12.87 0 0 1 -4.56-2.26 10.6 10.6 0 0 1 -3.05-3.9 13.29 13.29 0 0 1 -1.1-5.62 12.25 12.25 0 0 1 1.26-5.9 10.6 10.6 0 0 1 3.26-3.71 14.61 14.61 0 0 1 4.56-2.07 48.87 48.87 0 0 1 5.16-1.1c1.74-.27 3.45-.49 5.13-.65a31.29 31.29 0 0 0 4.48-.73 7.79 7.79 0 0 0 3.09-1.43 3.12 3.12 0 0 0 1.06-2.76 6.15 6.15 0 0 0 -.61-3 4.55 4.55 0 0 0 -1.63-1.71 6.59 6.59 0 0 0 -2.36-.81 20.08 20.08 0 0 0 -2.89-.2 8.8 8.8 0 0 0 -5.37 1.46 6.58 6.58 0 0 0 -2.28 4.89zm26.7 8.54a5.07 5.07 0 0 1 -1.83 1 18.87 18.87 0 0 1 -2.36.61c-.84.16-1.72.3-2.65.41s-1.84.24-2.76.41a20.57 20.57 0 0 0 -2.57.65 7.44 7.44 0 0 0 -2.2 1.1 5.2 5.2 0 0 0 -1.5 1.75 5.6 5.6 0 0 0 -.57 2.68 5.47 5.47 0 0 0 .57 2.61 4.28 4.28 0 0 0 1.55 1.66 6.42 6.42 0 0 0 2.27.86 14.87 14.87 0 0 0 2.69.24 10.21 10.21 0 0 0 5.29-1.14 7.61 7.61 0 0 0 2.77-2.72 8.75 8.75 0 0 0 1.1-3.22 23.1 23.1 0 0 0 .2-2.6z" />
          <path d="m126.44 172.8v21.2h.17a12.37 12.37 0 0 1 5.73-4.76 19.54 19.54 0 0 1 7.53-1.51 16.11 16.11 0 0 1 11.85 5.3 20.63 20.63 0 0 1 4 6.87 33.08 33.08 0 0 1 0 19.95 20.63 20.63 0 0 1 -4 6.87 16.11 16.11 0 0 1 -11.85 5.3 22.13 22.13 0 0 1 -8.3-1.47 10.5 10.5 0 0 1 -5.53-5h-.17v5.38h-11v-58.13zm18.72 32a13.3 13.3 0 0 0 -1.79-4.32 9.5 9.5 0 0 0 -3.05-3 9.41 9.41 0 0 0 -8.83 0 9.33 9.33 0 0 0 -3.09 3 13.08 13.08 0 0 0 -1.79 4.32 22 22 0 0 0 -.57 5.13 21.58 21.58 0 0 0 .57 5 13.08 13.08 0 0 0 1.79 4.32 9.13 9.13 0 0 0 3.09 3 9.41 9.41 0 0 0 8.83 0 9.3 9.3 0 0 0 3.05-3 13.3 13.3 0 0 0 1.79-4.32 22.13 22.13 0 0 0 .57-5 22.53 22.53 0 0 0 -.57-5.14z" />
          <path d="m107 73.9v-.21c7-2.88 11.31-9 11.31-17a18.06 18.06 0 0 0 -9-15.88c-4.57-2.81-13.43-2.81-20.15-2.81h-27.63v76.09h36.81c15.15 0 23.15-10.66 23.15-21.85 0-9.39-5.12-16.42-14.49-18.34zm-35.34-27.39h20.28c8.75 0 16.22 1.49 16.22 12 0 8-4.7 12.15-16.22 12.15h-20.28zm25.4 59h-25.4v-26.28h23.15c8.54 0 16.55 2.77 16.55 12.68 0 8.31-5.55 13.64-14.3 13.64z" />
          <path d="m89.45 9.37a66.66 66.66 0 1 0 66.76 66.63 66.7 66.7 0 0 0 -66.76-66.63zm0 124.57a57.87 57.87 0 1 1 58-57.88 57.91 57.91 0 0 1 -58 57.88z" />
          <path d="m22.85 156.75h133.22v8.75h-133.22z" />
          <path d="m153.37 147.87a1.44 1.44 0 0 0 .82-.33 1.1 1.1 0 0 0 .33-.88 1.12 1.12 0 0 0 -.4-1 2.06 2.06 0 0 0 -1.21-.3h-1.69v4.43h.78v-1.89h.69l1.14 1.89h.83zm-.6-.57h-.77v-1.3h1.21a1.27 1.27 0 0 1 .31.08.62.62 0 0 1 .23.19.49.49 0 0 1 .09.32.69.69 0 0 1 -.08.38.58.58 0 0 1 -.23.21.8.8 0 0 1 -.32.09zm.6.57a1.44 1.44 0 0 0 .82-.33 1.1 1.1 0 0 0 .33-.88 1.12 1.12 0 0 0 -.4-1 2.06 2.06 0 0 0 -1.21-.3h-1.69v4.43h.78v-1.89h.69l1.14 1.89h.83zm-.6-.57h-.77v-1.3h1.21a1.27 1.27 0 0 1 .31.08.62.62 0 0 1 .23.19.49.49 0 0 1 .09.32.69.69 0 0 1 -.08.38.58.58 0 0 1 -.23.21.8.8 0 0 1 -.32.09zm3.58-1.41a3.86 3.86 0 0 0 -.86-1.26 3.9 3.9 0 0 0 -2.75-1.11 4.1 4.1 0 0 0 -1.51.29 4 4 0 0 0 -1.25.82 3.86 3.86 0 0 0 -.86 1.26 4.36 4.36 0 0 0 0 3.23 4.06 4.06 0 0 0 .81 1.27 4 4 0 0 0 1.24.84 4.17 4.17 0 0 0 1.59.3 4 4 0 0 0 2.75-1.1 3.86 3.86 0 0 0 .86-1.26 4.49 4.49 0 0 0 0-3.28zm-.78 3a3.21 3.21 0 0 1 -.65 1 3 3 0 0 1 -1 .68 3 3 0 0 1 -1.2.24 3.11 3.11 0 0 1 -1.28-.26 3 3 0 0 1 -1-.72 3.24 3.24 0 0 1 -.62-1.05 3.82 3.82 0 0 1 -.21-1.28 3.63 3.63 0 0 1 .24-1.34 3 3 0 0 1 .65-1.05 2.93 2.93 0 0 1 1-.68 3 3 0 0 1 1.21-.24 3 3 0 0 1 1.2.24 3 3 0 0 1 1 .68 3.11 3.11 0 0 1 .65 1.05 3.63 3.63 0 0 1 .24 1.34 3.59 3.59 0 0 1 -.23 1.37zm-1.38-1.33a1.1 1.1 0 0 0 .33-.88 1.12 1.12 0 0 0 -.4-1 2.06 2.06 0 0 0 -1.21-.3h-1.69v4.43h.78v-1.89h.69l1.14 1.89h.83l-1.24-1.95a1.44 1.44 0 0 0 .77-.32zm-1.42-.24h-.77v-1.32h1.21a1.27 1.27 0 0 1 .31.08.62.62 0 0 1 .23.19.49.49 0 0 1 .09.32.69.69 0 0 1 -.08.38.58.58 0 0 1 -.23.21.8.8 0 0 1 -.32.09z" />
          <path d="m43.76 274a12.21 12.21 0 0 1 -4.77 1 17.07 17.07 0 0 1 -6.82-1.31 14.73 14.73 0 0 1 -5.12-3.69 16 16 0 0 1 -3.19-5.44 19.92 19.92 0 0 1 -1.11-6.73 20.62 20.62 0 0 1 1.11-6.83 16.51 16.51 0 0 1 3.19-5.54 14.75 14.75 0 0 1 5.12-3.7 16.73 16.73 0 0 1 6.83-1.39 16 16 0 0 1 4.93.76 14 14 0 0 1 4.31 2.25 12.12 12.12 0 0 1 4.7 8.65h-6.94a6.89 6.89 0 0 0 -2.5-4.16 7.23 7.23 0 0 0 -4.5-1.39 8.5 8.5 0 0 0 -4.16 1 7.89 7.89 0 0 0 -2.79 2.52 11.13 11.13 0 0 0 -1.55 3.64 18.3 18.3 0 0 0 -.49 4.21 17 17 0 0 0 .49 4.05 10.87 10.87 0 0 0 1.55 3.54 7.93 7.93 0 0 0 2.78 2.52 8.39 8.39 0 0 0 4.16.95 7.88 7.88 0 0 0 5.58-1.83 7.92 7.92 0 0 0 2.29-5.3h-7.31v-5.41h13.88v17.83h-4.62l-.74-3.75a10.91 10.91 0 0 1 -4.31 3.55z" />
          <path d="m64.13 241.16v33h-6.58v-33z" />
          <path d="m68.2 257.06a10.9 10.9 0 0 1 6.39-6.53 13.66 13.66 0 0 1 5.09-.9 13.88 13.88 0 0 1 5.11.9 10.86 10.86 0 0 1 6.41 6.53 15.77 15.77 0 0 1 0 10.39 11.11 11.11 0 0 1 -2.5 3.95 10.87 10.87 0 0 1 -3.91 2.53 14.3 14.3 0 0 1 -5.11.88 14.08 14.08 0 0 1 -5.09-.88 10.88 10.88 0 0 1 -3.89-2.53 11.11 11.11 0 0 1 -2.5-3.95 15.77 15.77 0 0 1 0-10.39zm6 8a7.38 7.38 0 0 0 1 2.46 5 5 0 0 0 1.78 1.73 5.47 5.47 0 0 0 2.78.65 5.6 5.6 0 0 0 2.8-.65 5.06 5.06 0 0 0 1.8-1.73 7.38 7.38 0 0 0 1-2.46 13.64 13.64 0 0 0 .28-2.8 14 14 0 0 0 -.28-2.82 7 7 0 0 0 -1-2.45 5.11 5.11 0 0 0 -4.6-2.41 5 5 0 0 0 -4.64 2.42 7 7 0 0 0 -1 2.45 14 14 0 0 0 -.28 2.82 13.64 13.64 0 0 0 .33 2.79z" />
          <path d="m101.66 241.16v12h.09a7.06 7.06 0 0 1 3.27-2.71 11 11 0 0 1 4.28-.85 9.11 9.11 0 0 1 6.73 3 11.42 11.42 0 0 1 2.29 3.91 18.71 18.71 0 0 1 0 11.34 11.51 11.51 0 0 1 -2.32 3.95 9.11 9.11 0 0 1 -6.73 3 12.53 12.53 0 0 1 -4.72-.84 6 6 0 0 1 -3.15-2.82h-.09v3.05h-6.22v-33zm10.65 18.19a7.78 7.78 0 0 0 -1-2.46 5.3 5.3 0 0 0 -1.74-1.69 4.85 4.85 0 0 0 -2.52-.62 4.9 4.9 0 0 0 -2.5.62 5.36 5.36 0 0 0 -1.76 1.69 7.78 7.78 0 0 0 -1 2.46 13 13 0 0 0 -.32 2.91 12.88 12.88 0 0 0 .32 2.87 7.62 7.62 0 0 0 1 2.45 5.16 5.16 0 0 0 1.76 1.69 4.81 4.81 0 0 0 2.5.63 4.76 4.76 0 0 0 2.52-.63 5.11 5.11 0 0 0 1.74-1.69 7.62 7.62 0 0 0 1-2.45 12.88 12.88 0 0 0 .32-2.87 13 13 0 0 0 -.32-2.91z" />
          <path d="m122.77 257.63a7.6 7.6 0 0 1 1.15-3.84 7.91 7.91 0 0 1 2.6-2.45 11 11 0 0 1 3.54-1.32 20.27 20.27 0 0 1 3.95-.39 26.69 26.69 0 0 1 3.66.25 11.08 11.08 0 0 1 3.38 1 6.62 6.62 0 0 1 2.5 2.06 5.73 5.73 0 0 1 1 3.49v12.45a23.79 23.79 0 0 0 .19 3.1 5.5 5.5 0 0 0 .64 2.22h-6.66a7.23 7.23 0 0 1 -.3-1.13 9.3 9.3 0 0 1 -.16-1.18 8.58 8.58 0 0 1 -3.71 2.27 14.93 14.93 0 0 1 -4.35.65 11.66 11.66 0 0 1 -3.19-.42 7.26 7.26 0 0 1 -2.59-1.3 6 6 0 0 1 -1.74-2.22 7.44 7.44 0 0 1 -.62-3.19 7.09 7.09 0 0 1 .71-3.36 5.93 5.93 0 0 1 1.86-2.1 8.07 8.07 0 0 1 2.59-1.18 27 27 0 0 1 2.94-.63c1-.15 2-.28 2.91-.37a16.53 16.53 0 0 0 2.55-.42 4.36 4.36 0 0 0 1.76-.81 1.78 1.78 0 0 0 .6-1.55 3.51 3.51 0 0 0 -.35-1.69 2.6 2.6 0 0 0 -.92-1 3.78 3.78 0 0 0 -1.35-.46 10.53 10.53 0 0 0 -1.64-.12 4.93 4.93 0 0 0 -3.05.84 3.66 3.66 0 0 0 -1.3 2.77zm15.23 4.86a3 3 0 0 1 -1 .58 10.22 10.22 0 0 1 -1.35.35c-.47.09-1 .17-1.5.23s-1.05.14-1.57.23a14.18 14.18 0 0 0 -1.46.37 4.44 4.44 0 0 0 -1.25.63 2.94 2.94 0 0 0 -.86 1 3.28 3.28 0 0 0 -.32 1.53 3.15 3.15 0 0 0 .32 1.48 2.52 2.52 0 0 0 .88.95 3.87 3.87 0 0 0 1.3.49 8.46 8.46 0 0 0 1.53.13 5.82 5.82 0 0 0 3-.64 4.43 4.43 0 0 0 1.58-1.55 4.85 4.85 0 0 0 .62-1.83 12.15 12.15 0 0 0 .08-1.44z" />
          <path d="m155.12 241.16v33h-6.57v-33z" />
        </g>
        <path d="m0 0h180v288h-180z" fill="none" />
      </svg>
    </>
  );
};
