import React from 'react';
import { useCookieConsent } from '../hooks/use-cookie-consent';
import { CookieBanner } from './cookie-banner/cookie-banner';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

export const CookieConsent = () => {
  const { isVisible, accept, decline } = useCookieConsent({
    cookieName: 'gatsby-gdpr-google-tagmanager',
  });

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10">
      <CookieBanner
        text={
          'By clicking “Accept all cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.'
        }
        acceptLabel={'Accept all cookies'}
        declineLabel={'Reject non essential cookies'}
        onAccept={() => {
          accept();
          initializeAndTrack(window.location);
        }}
        onDecline={() => {
          decline();
        }}
      />
    </div>
  );
};
