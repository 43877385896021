import { Link } from 'gatsby';
import React from 'react';

type BLabLinkProps = {
  forceExternalLink?: boolean;
  showReferral?: boolean;
};

// Simple link with no default styles
export const PlainLink = (
  props: BLabLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
): JSX.Element => {
  const { forceExternalLink, showReferral, ...anchorProps } = props;

  if (
    !anchorProps.href ||
    anchorProps.href.startsWith('http://') ||
    anchorProps.href.startsWith('https://') ||
    forceExternalLink
  ) {
    // All external links open in a new tab
    let rel = 'noopener noreferrer';
    if (showReferral) {
      rel = 'noopener';
    }

    return (
      <a target="_blank" rel={rel} {...anchorProps}>
        {anchorProps.children}
      </a>
    );
  }

  return (
    <Link to={anchorProps.href} {...anchorProps}>
      {anchorProps.children}
    </Link>
  );
};

export enum AccentColor {
  Yellow = 1,
  Blue = 2,
}

function getAccentClass(a: AccentColor): string {
  switch (a) {
    case AccentColor.Yellow:
      return 'yellow-highlight';
    case AccentColor.Blue:
      return 'blue-highlight';
  }
}

type AccentLinkProps = {
  accentColor: AccentColor;
};

export const AccentedLink = (
  props: AccentLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
): JSX.Element => {
  const { accentColor, ...filteredProps } = props;

  const fullProps = {
    ...filteredProps,
    className: `${getAccentClass(accentColor)} cursor-pointer ${
      filteredProps.className || ''
    }`,
  };

  let link: JSX.Element;
  if (
    !fullProps.href ||
    fullProps.href.startsWith('https://') ||
    fullProps.href.startsWith('//')
  ) {
    // All external links or internal documents open in a new tab.
    link = (
      <a target="_blank" rel="noopener noreferrer" {...fullProps}>
        {fullProps.children}
      </a>
    );
  } else if (!fullProps.href || fullProps.href.startsWith('mailto:')) {
    link = <a {...fullProps}> {fullProps.children}</a>;
  } else {
    link = (
      <Link to={fullProps.href} {...fullProps}>
        {fullProps.children}
      </Link>
    );
  }

  return <span>{link}</span>;
};
